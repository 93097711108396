import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, getCookie, pesan } from "../modul";

function App() {
    document.title = "Users";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                searchcount: 50,
                index: 0,
                sort: "Username",
                by: "DESC",
                SalesID: "",
                DocNumber: "",
                ID: "",
                Title: "Tambah Users",
                Nama: "",
                Username: "",
                Pwd: "0000",
                UserType: "sales",
                isDisabled: false,
                IsSu: false
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                PusatID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token")
            }

            let sql = await api("list_users", param);
            if (sql.status == "true") {
                console.log(sql);
                this.setState({ data: sql.data });
            }
        }

        async handleForms(ID = "") {
            if (ID != "") {
                let sql = await api("detail_users", { ID: ID, Token: getCookie("Token") });
                if (sql.status == "true") {
                    this.setState({
                        ID: sql.data.ID,
                        Title: "Edit Users",
                        Nama: sql.data.Nama,
                        Username: sql.data.Username,
                        Pwd: sql.data.Pwd,
                        UserType: sql.data.UserType,
                        isDisabled: true,
                        IsSu: sql.data.IsSu
                    });
                }
            } else {
                this.setState({
                    ID: "",
                    Nama: "",
                    Username: "",
                    Pwd: "0000",
                    UserType: "sales",
                    Title: "Tambah Users",
                    isDisabled: false
                });
            }
        }

        async handleSubmit(e) {
            e.preventDefault();
            let param = {
                ID: this.state.ID,
                Nama: this.state.Nama,
                Username: this.state.Username,
                Pwd: this.state.Pwd,
                UserType: this.state.UserType,
                IsSu: this.state.IsSu == true ? 1 : 0,
                PusatID: getCookie("UserID"),
                Token: getCookie("Token")
            }
            let sql = await api(this.state.ID == "" ? "input_users" : "edit_users", param);
            if (sql.status == "true") {
                this.setState({
                    Nama: "",
                    Username: "",
                    Pwd: "",
                    UserType: "sales",
                    Title: "Tambah Users"
                });
                pesan("Berhasil", sql.message, "primary");
                document.getElementById('btnTutupForm').click();
                this.main();
            }
        }

        async handleHapus(e) {
            e.preventDefault();
            e.stopPropagation();
            let sql = await api("hapus_users", { ID: this.state.ID, Token: getCookie("Token") });
            if (sql.status == "true") {
                pesan("Berhasil", sql.message, "primary");
                document.getElementById('btnTutupHapus').click();
                this.main();
            } else {
                pesan("Gagal", sql.message, "danger");
            }
        }

        async handleSubmitTarik(e) {
            e.preventDefault();
            e.stopPropagation();
            let sql = await api("tarik_users", { Token: getCookie("Token") });
            if (sql.status == "true") {
                pesan("Berhasil", sql.message, "primary");
                document.getElementById('btnTutupTarik').click();
                this.main();
            } else {
                pesan("Gagal", sql.message, "danger");
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Users</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className='input-group gap-2'>
                                    <button className='btn btn-primary' onClick={() => this.handleForms()} data-bs-toggle="modal" data-bs-target="#modalForm">Tambah Users</button>
                                    <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalTarik">Tarik Sales</button>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="120px"></th>
                                        <th width="150px">Nama</th>
                                        <th width="150px">Username</th>
                                        <th>Jenis</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <button className='btn btn-sm' onClick={() => this.handleForms(item.ID)} data-bs-toggle="modal" data-bs-target="#modalForm">
                                                            <span className='material-icons'>edit</span>
                                                        </button>
                                                        <button className='btn btn-sm' onClick={() => this.setState({ ID: item.ID })} data-bs-toggle="modal" data-bs-target="#modalDelete">
                                                            <span className='material-icons'>delete</span>
                                                        </button>
                                                    </td>
                                                    <td>{item.Nama}</td>
                                                    <td>{item.Username}</td>
                                                    <td>{item.UserType}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                    <div className="modal fade" id="modalForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form id="formInput" onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{this.state.Title}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" name="ID" value={this.state.ID} />
                                        <div className='form-group'>
                                            <label>Nama User</label>
                                            <input className='form-control' type="text" name="Nama" value={this.state.Nama} onChange={(e) => this.setState({ Nama: e.target.value })} required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Username</label>
                                            <input className='form-control' type="text" name="Nama" value={this.state.Username} onChange={(e) => this.setState({ Username: e.target.value })} required />
                                        </div>
                                        <div className='form-group' style={{ display: this.state.isDisabled == true ? "none" : "block" }}>
                                            <label>Password</label>
                                            <input className='form-control' type="text" name="Nama" value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required readOnly={this.state.isDisabled} />
                                        </div>
                                        <div className='form-group'>
                                            <label>Tipe User</label>
                                            <select name="UserType" className='form-select' value={this.state.UserType} onChange={(e) => this.setState({ UserType: e.target.value })}>
                                                <option value="sales">Sales</option>
                                                <option value="kasir">Kasir</option>
                                                <option value="kacab">Kepala Cabang</option>
                                                <option value="pusat">Admin Pusat</option>
                                            </select>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={this.state.IsSu} onChange={(e) => this.setState({ IsSu: e.target.checked })} />
                                            <label className="form-check-label" for="flexSwitchCheckDefault">Semua Hak Akses</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupForm" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-primary">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDelete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form id="formHapus" onSubmit={(e) => this.handleHapus(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Hapus Dokumen</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <h4>Apakah anda yakin akan hapus user ini</h4>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupHapus" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning">Hapus</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalTarik" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form id="formInput" onSubmit={(e) => this.handleSubmitTarik(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Tarik Data Sales</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        Apakah Anda Yakin Akan Tarik Data Sales?
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupTarik" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-primary">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;