import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Pagination from "../components/Pagination";
import { saiki, api, tanggalIndo, numberFormat, getCookie } from "../modul";
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

function App(d1 = saiki("1"), d2 = saiki(), q = "") {
    document.title = "Penagihan Selesai";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                search: q,
                searchcount: 50,
                index: 1,
                jml: 0,
                sort: "DocDate",
                by: "DESC",
                SalesID: "",
                Detail: {},
                ChangeImg: "no",
                D1: window.sessionStorage.getItem("D1") || saiki("1"),
                D2: window.sessionStorage.getItem("D2") || saiki(),
                PaymentType: "",
                thead: [],
                Field: [
                    { cap: "Tanggal", sort: "DocDate", type: "date" },
                    { cap: "Nomor Dokumen", sort: "DocNumber", type: "str" },
                    { cap: "Jumlah Invoice", sort: "Total", type: "number" },
                    { cap: "Jumlah Tagihan", sort: "Amount", type: "number" },
                    { cap: "Pelanggan", sort: "Customer", type: "html" },
                ],
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            let page = e.target.dataset.page;
            this.setState({ index: page });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        handleDetail(detail) {
            ReactDOM.render(<Form DocNumber={detail.DocNumber} />, document.getElementById('tampil'));
        }

        handleChangePayment(val) {
            this.setState({ PaymentType: val });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleChangeDate(dd, val) {
            window.sessionStorage.setItem(dd, val);
            if (dd == "D1") {
                this.setState({ "D1": val });
            } else {
                this.setState({ "D2": val });
            }
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                KacabID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                D1: this.state.D1,
                D2: this.state.D2,
                PaymentType: this.state.PaymentType,
                Token: getCookie("Token")
            }
            let Field = this.state.Field;
            if (this.state.PaymentType == 'Tunai') {
                Field = [
                    { cap: "No Pusat", sort: "PusatID" },
                    { cap: "No Invoice", sort: "NoInvoice" },
                    { cap: "Sales", sort: "SalesName" },
                    { cap: "Pelanggan", sort: "CustomerName" },
                    { cap: "Jenis Pembayaran", sort: "PaymentType" },
                    { cap: "Saldo Awal", sort: "AmountOpen", type: "number" },
                    { cap: "Pembayaran", sort: "Amount", type: "number" },
                    { cap: "Saldo Akhir", sort: "AmountBalance", type: "number" },
                ];
            } else {
                Field = [
                    { cap: "Tanggal", sort: "DocDate", type: "date" },
                    { cap: "Nomor Dokumen", sort: "DocNumber", type: "str" },
                    { cap: "Jumlah Invoice", sort: "Total", type: "number" },
                    { cap: "Jumlah Tagihan", sort: "Amount", type: "number" },
                    { cap: "Pelanggan", sort: "Customer", type: "html" },
                ]
            }

            let sql = await api("list_tagihan_selesai_kacab", param);
            if (sql.status == "true") this.setState({ data: sql.data, Field: Field, jml: sql.ct });
        }

        async handlePrint() {
            let param = {
                D1: this.state.D1,
                D2: this.state.D2,
                Token: getCookie("Token")
            }
            let sql = await api("list_detail_tagihan_selesai_kacab", param);
            let dom = [];
            if (sql.status == "true") {
                for (let tgl of sql.docdate) {
                    let TotalSales = 0, TotalType = 0;
                    dom.push(<Fragment>
                        <h3 style={{ textAlign: "center" }}>{tanggalIndo(tgl.DocDate)}</h3>
                        {
                            sql.sales.map((sales, salesi) => {
                                let ctTF = [], domTF = [], ctTunai = [], domTunai = [], ctGiro = [], domGiro = [], ctAll = 0;
                                for (let data of sql.data) if (data.SalesID == sales.SalesID && data.DocDate == tgl.DocDate) ctAll++;
                                if (ctAll > 0) {
                                    for (let data of sql.data) if (data.SalesID == sales.SalesID && data.PaymentType == "Transfer" && data.DocDate == tgl.DocDate) ctTF.push(data);
                                    for (let data of sql.data) if (data.SalesID == sales.SalesID && data.PaymentType == "Tunai" && data.DocDate == tgl.DocDate) ctTunai.push(data);
                                    for (let data of sql.data) if (data.SalesID == sales.SalesID && data.PaymentType == "Giro" && data.DocDate == tgl.DocDate) ctGiro.push(data);
                                    if (ctTF.length > 0) {
                                        domTF = (
                                            <Fragment>
                                                <h6>Transfer</h6>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Invoice</th>
                                                                <th>Nama Customer</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ctTF.map((item, index) => {
                                                                if (item.DocDate == tgl.DocDate) {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.NoInvoice}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan='2' style={{ textAlign: "right" }}>Total</td>
                                                                <td>{numberFormat(ctTF.reduce((a, b) => a + b.AmountOpen, 0))}</td>
                                                                <td>{numberFormat(ctTF.reduce((a, b) => a + b.Amount, 0))}</td>
                                                                <td>{numberFormat(ctTF.reduce((a, b) => a + b.AmountBalance, 0))}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                    if (ctTunai.length > 0) {
                                        domTunai = (
                                            <Fragment>
                                                <h6>Tunai</h6>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Invoice</th>
                                                                <th>Nama Customer</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ctTunai.map((item, index) => {
                                                                if (item.DocDate == tgl.DocDate) {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.NoInvoice}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan='2' style={{ textAlign: "right" }}>Total</td>
                                                                <td>{numberFormat(ctTunai.reduce((a, b) => a + b.AmountOpen, 0))}</td>
                                                                <td>{numberFormat(ctTunai.reduce((a, b) => a + b.Amount, 0))}</td>
                                                                <td>{numberFormat(ctTunai.reduce((a, b) => a + b.AmountBalance, 0))}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                    if (ctGiro.length > 0) {
                                        domGiro = (
                                            <Fragment>
                                                <h6>Giro</h6>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Invoice</th>
                                                                <th>Nama Customer</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ctGiro.map((item, index) => {
                                                                if (item.DocDate == tgl.DocDate) {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.NoInvoice}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan='2' style={{ textAlign: "right" }}>Total</td>
                                                                <td>{numberFormat(ctGiro.reduce((a, b) => a + b.AmountOpen, 0))}</td>
                                                                <td>{numberFormat(ctGiro.reduce((a, b) => a + b.Amount, 0))}</td>
                                                                <td>{numberFormat(ctGiro.reduce((a, b) => a + b.AmountBalance, 0))}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                    return (<div style={{ border: "solid black 1px", padding: "20px" }}>
                                        <h6>{sales.SalesName}</h6>
                                        {domTF}
                                        {domTunai}
                                        {domGiro}
                                    </div>);
                                }
                            })
                        }
                        <div className='row'>
                            <div className='col-md-6 mb-2'>
                                <h4>Total Per Sales</h4>
                                <table className='table' style={{ fontSize: "24px" }}>
                                    {
                                        sql.totalSales.map((sales, index) => {
                                            if (sales.DocDate == tgl.DocDate) {
                                                TotalSales += parseFloat(sales.Total);
                                                return (
                                                    <tr>
                                                        <td width="120px">{sales.SalesName}</td>
                                                        <td width="10px">:</td>
                                                        <td>{numberFormat(sales.Total)}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                    <tr>
                                        <td width="120px" style={{ fontWeight: "bold" }}>Total</td>
                                        <td width="10px" style={{ fontWeight: "bold" }}>:</td>
                                        <td style={{ fontWeight: "bold" }}>{numberFormat(TotalSales)}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className='col-md-6 mb-2'>
                                <h4>Total Per Tipe Pembayaran</h4>
                                <table className='table' style={{ fontSize: "24px" }}>
                                    {
                                        sql.totalType.map((tipe, index) => {
                                            if (tipe.DocDate == tgl.DocDate) {
                                                TotalType += parseFloat(tipe.Total);
                                                return (
                                                    <tr>
                                                        <td width="120px">{tipe.PaymentType}</td>
                                                        <td width="10px">:</td>
                                                        <td>{numberFormat(tipe.Total)}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                    <tr>
                                        <td width="120px" style={{ fontWeight: "bold" }}>Total</td>
                                        <td width="10px" style={{ fontWeight: "bold" }}>:</td>
                                        <td style={{ fontWeight: "bold" }}>{numberFormat(TotalType)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </Fragment>)
                }
            }
            ReactDOM.render(dom, document.getElementById('divPrint'));
            setTimeout(() => window.print(), 500);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Kacab Selesai</h2>
                        <div className='row'>
                            <div className='col-md-3'></div>
                            <div className='col-md-9'>
                                <div className='d-flex justify-content-between align-items-center gap-1'>
                                    <select className='form-select' value={this.state.PaymentType} onChange={(e) => this.handleChangePayment(e.target.value)}>
                                        <option value="">Non Tunai</option>
                                        <option value="Tunai">Tunai</option>
                                        <option value="Transfer">Transfer</option>
                                        <option value="Giro">Giro</option>
                                    </select>
                                    <input type="date" className='form-control' value={this.state.D1} onChange={(e) => this.handleChangeDate('D1', e.target.value)} />
                                    <input type="date" className='form-control' value={this.state.D2} onChange={(e) => this.handleChangeDate('D2', e.target.value)} />
                                    <button className='btn btn-primary' onClick={() => this.main()}>
                                        <span className='material-icons'>search</span>
                                    </button>
                                    <button className='btn btn-primary' onClick={() => this.handlePrint()}>
                                        <span className='material-icons'>print</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <DataGrid dataSource={this.state.data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            <Column caption=""
                                cellRender={(rowData) => (
                                    <div>
                                        <i className="material-icons" onClick={() => this.handleDetail(rowData.data)}>more_horiz</i>
                                    </div>
                                )}
                            />
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <TotalItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                                <GroupItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Awal : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Pembayaran : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Akhir : {0}" showInGroupFooter={false} alignByColumn={true} />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
            Detail: {},
            dataGiro: [],
            btnKirim: true
        }
    }

    async componentDidMount() {
        let ctx = this;
        ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><span className="material-icons">arrow_back</span> Kembali</div>, document.getElementById('divHeaderLabel'));
        let sql = await api("tagihan_detail_kacab", { DocNumber: ctx.props.DocNumber, Token: getCookie("Token") });
        if (sql.status == "true") {
            this.setState({ data: sql.data, btnKirim: sql.data.Status == "0" ? true : false });
        }
    }

    handleDetail(detail) {
        this.setState({ Detail: detail });
        if (detail.PaymentType == "Giro") {
            let dom = (
                <Fragment>
                    <tr>
                        <td>No Giro</td>
                        <td>:</td>
                        <td>{detail.GiroNumber}</td>
                    </tr>
                    <tr>
                        <td>Tangal Jatuh Tempo</td>
                        <td>:</td>
                        <td>{tanggalIndo(detail.TanggalJatuhTempoGiro)}</td>
                    </tr>
                </Fragment>
            );
            this.setState({ dataGiro: dom });
        }
    }

    render() {
        let CTTunai = 0, SWTunai = 0, PMTunai = 0, SATunai = 0;
        return (
            <Fragment>
                <div className="card-form">
                    <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Baru</h2>
                    <div className='row'>
                        <div className='col-md-9'></div>
                        <div className='col-md-3'>
                            <div className='input-icons'>
                                <span className='material-icons icon-search'>search</span>
                                <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className="table-responsive" id="divView">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>No Invoice</th>
                                    <th>Pelanggan</th>
                                    <th>Sales</th>
                                    <th>Saldo Awal</th>
                                    <th>Pembayaran</th>
                                    <th>Saldo Akhir</th>
                                    <th>Tanggal Pelunasan</th>
                                    <th>PPN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.data.map((item, index) => {
                                        let State = <span className="badge bg-danger">Reject</span>;
                                        if (item.State == 0) {
                                            State = <span className='badge bg-warning'>Menunggu</span>;
                                        } else if (item.State == 1) {
                                            State = <span className='badge bg-primary'>Approve</span>;
                                        }
                                        CTTunai++;
                                        SWTunai += item.AmountOpen;
                                        PMTunai += item.Amount;
                                        SATunai += item.AmountBalance;
                                        return (
                                            <tr>
                                                <td>{item.NoInvoice}</td>
                                                <td>{item.CustomerName}</td>
                                                <td>{item.SalesName}</td>
                                                <td>{numberFormat(item.AmountOpen)}</td>
                                                <td>{numberFormat(item.Amount)}</td>
                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                <td>{item.TaxType}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td>{CTTunai}</td>
                                    <td></td>
                                    <td>{numberFormat(SWTunai)}</td>
                                    <td>{numberFormat(PMTunai)}</td>
                                    <td>{numberFormat(SATunai)}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default App;