import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JML: [],
            jml: this.props.jml,
            IDX: this.props.index
        };
    }

    componentDidMount() {
        let JML = [],
            IDX = this.props.index,
            jml = this.props.jml;
        for (let idx = 1; idx <= jml; idx++) {
            JML.push({ nomor: idx, status: idx == IDX ? "active" : "" });
        }
        this.setState({ JML: JML, IDX: IDX, jml: this.props.jml });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.IDX != this.props.index || prevState.jml != this.props.jml) {
            let JML = [],
                IDX = this.props.index,
                jml = this.props.jml;
            for (let idx = 1; idx <= jml; idx++) {
                JML.push({ nomor: idx, status: idx == IDX ? "active" : "" });
            }
            this.setState({ JML: JML, IDX: IDX, jml: this.props.jml });
        }
    }

    render() {
        return (
            <Fragment>
                <nav aria-label="Page navigation" >
                    <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                            <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a>
                        </li>
                        {
                            this.state.JML.map((item, index) => {
                                return (
                                    <li className={"page-item " + item.status} key={index} onClick={this.props.page}>
                                        <span data-page={item.nomor} className="page-link" style={{ cursor: item.status == "" ? "pointer" : "no-drop" }}>{item.nomor}</span>
                                    </li>
                                )
                            })
                        }
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </Fragment >
        )
    }
}

export default Pagination;