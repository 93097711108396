import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, tanggalIndo, numberFormat, getCookie, pesan } from "../modul";
import { FormsTransfer, FormsTunai, FormsGiro } from './Forms';
import img_upload from '../bg_uppload-image.png';
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

function App() {
    document.title = "Data Kacab Baru";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                D1: window.localStorage.getItem("D1") || saiki("1"),
                D2: window.localStorage.getItem("D2") || saiki(),
                searchcount: 50,
                index: 1,
                sort: "DocDate",
                by: "DESC",
                jml: 0,
                SalesID: "",
                Detail: {},
                DetailPayment: [],
                ChangeImg: "no",
                dataPayment: [],
                CoaCode: "",
                Amount: "",
                DocID: "",
                tanggalSetor: saiki(),
                totalPayment: 0,
                dataTempInvoice: [],
                dataPenagihanTunai: [],
                tempItem: {},
                tempCoaCode: "",
                tempCoaName: "",
                tempCoaID: "",
                tempAmount: 0,
                TotalTagihan: 0,
                totalTerpilih: 0,
                IsFilter: false,
                dataARAP: [],
                host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
                divImg: "",
                Field: [
                    { cap: "Tanggal", sort: "TanggalPembayaran", type: "date" },
                    { cap: "No Dokumen", sort: "KasirID" },
                    { cap: "No Invoice", sort: "NoInvoice" },
                    { cap: "Sales", sort: "SalesName" },
                    { cap: "Pelanggan", sort: "CustomerName" },
                    { cap: "Ppn", sort: "TaxType" },
                    { cap: "Saldo Awal", sort: "AmountOpen", type: "number" },
                    { cap: "Pembayaran", sort: "Amount", type: "number" },
                    { cap: "Saldo Akhir", sort: "AmountBalance", type: "number" },
                ]
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            let sql = await api("akun_bank", { Token: getCookie("Token") });
            if (sql.status == "true") this.setState({ dataPayment: sql.data });
            this.main();
        }

        handleChangeDate(dd, val) {
            window.localStorage.setItem(dd, val);
            if (dd == "D1") {
                this.setState({ "D1": val });
            } else {
                this.setState({ "D2": val });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            let page = e.target.dataset.page;
            this.setState({ index: page });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        async handleModalKirim(Detail) {
            let sql = await api("detail_payment", { DocNumber: Detail.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                let tt = 0;
                for (let pay of sql.payment) tt += parseFloat(pay.Amount);
                this.setState({ Detail: Detail, ChangeImg: "no", dataPayment: sql.data, totalPayment: tt });
            }
        }

        async handleKirim(e) {
            e.preventDefault();
            e.stopPropagation();
            let form = e.target;
            if (form.checkValidity()) {
                document.getElementById('btnKirim').disabled = true;
                document.getElementById('btnKirim').innerHTML = "Mengirim . . .";
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
                let data = new FormData(form);
                fetch(host + "kirim_tagihan_kacab", {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        this.main();
                        pesan("Proses Success", hasil.message, "primary");
                        document.getElementById('btnKirim').disabled = false;
                        document.getElementById('btnKirim').innerHTML = "kirim";
                        document.getElementById('btnTutupKirim').click();
                    } else {
                        document.getElementById('btnKirim').disabled = false;
                        document.getElementById('btnKirim').innerHTML = "kirim";
                        pesan("Peringatan", hasil.message, "danger");
                    }
                }).catch((error) => {
                    document.getElementById('btnKirim').disabled = false;
                    document.getElementById('btnKirim').innerHTML = "kirim";
                    console.log("Error: " + error);
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        async main() {
            let param = {
                search: this.state.search,
                D1: this.state.D1,
                D2: this.state.D2,
                IsFilter: this.state.IsFilter,
                index: this.state.index,
                searchcount: this.state.searchcount,
                KacabID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token")
            }
            let sql = await api("list_tagihan_tunai_kacab", param);
            if (sql.status == "true") {
                this.setState({ data: sql.data, jml: sql.ct });
            }
        }

        openFile(e, id) {
            document.getElementById(id).innerHTML = '';
            let files = e.target.files;
            let file;
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    let reader = new FileReader();
                    file = files[i];
                    reader.onload = (file) => {
                        document.getElementById(id).innerHTML += `<img src="${reader.result}" width="150px">`;
                        this.setState({ ChangeImg: "yes" });
                    }
                    reader.readAsDataURL(file)
                }
            } else {
                document.getElementById(id).innerHTML += `<img src="${img_upload}" width="150px">`;
            }
        }

        async handleAddPayment(td, ID) {
            let bank = await api("akun_bank", { Token: getCookie("Token") });
            if (bank.status == "true") {
                this.setState({ DocID: ID, CoaCode: "", Amount: 0 });
                let dom = <Fragment>
                    <div className='input-group'>
                        <select className='form-select form-select-sm' onChange={(e) => this.setState({ CoaCode: e.target.value })}>
                            <option value=''>Pilih Akun Bank</option>
                            {
                                bank.data.map((val, i) => {
                                    return (
                                        <option value={val.Code}>{val.Description}</option>
                                    )
                                })
                            }
                        </select>
                        <input type="number" className='form-control form-control-sm' step="0.01" onChange={(e) => this.setState({ Amount: e.target.value })} />
                        <button className='btn btn-sm btn-danger' type="button">
                            <span className='material-icons'>delete</span>
                        </button>
                        <button className='btn btn-sm btn-primary' type="button" onClick={() => this.handleSavePayment()}>
                            <span className='material-icons'>save</span>
                        </button>
                    </div>
                </Fragment>;
                ReactDOM.render(dom, document.getElementById(td));
            }
        }

        async handleSavePayment(ID) {
            if (this.state.CoaCode != "") {
                if (this.state.Amount > 0) {
                    let param = {
                        DocID: this.state.DocID,
                        CoaCode: this.state.CoaCode,
                        Amount: this.state.Amount,
                        DocNumber: this.state.Detail.DocNumber,
                        Token: getCookie("Token")
                    }

                    let sql = await api("save_payment", param);

                    if (sql.status == "true") {
                        pesan("Berhasil", sql.message, "primary");
                        this.handleModalKirim(this.state.Detail);
                    } else {
                        pesan("Gagal", sql.message, "danger");
                    }
                } else {
                    pesan("Gagal", "Jumlah Payment Tidak Boleh 0", "danger");
                }
            } else {
                pesan("Peringatan", "Silahkan Pilih Akun Bank", "danger");
            }
        }

        async handleCariInvoice(val) {
            let tempID = [], tempTotalTerpilih = 0, tempTotalTagihan = 0;
            let dataTerpilih = this.state.dataTempInvoice;
            for (let dd of dataTerpilih) {
                tempID.push(dd.ID);
                tempTotalTerpilih += parseFloat(dd.Amount);
            }
            let sql = await api("data_inovoice_tunai", { NoInvoice: val, ID: JSON.stringify(tempID), Token: getCookie("Token") });
            if (sql.status == "true") {
                let tempData = [];
                for (let item of sql.data) {
                    let temp = {
                        ID: item.ID,
                        NoInvoice: item.NoInvoice,
                        DocNumber: item.KasirID,
                        SalesName: item.SalesName,
                        CustomerName: item.CustomerName,
                        AmountOpen: item.AmountOpen,
                        Amount: item.Amount,
                        AmountBalance: item.AmountBalance,
                        CoaCode: this.state.tempCoaCode,
                        CoaName: this.state.tempCoaName,
                        CoaID: this.state.tempCoaID
                    }
                    if (!dataTerpilih.includes(temp)) {
                        tempData.push(item);
                        tempTotalTagihan += parseFloat(item.Amount)
                    }
                }
                if (sql.status == "true") this.setState({ dataPenagihanTunai: tempData, TotalTagihan: tempTotalTagihan, totalTerpilih: tempTotalTerpilih });
            }
        }

        pilihInvoice(e, item) {
            if (e.target.localName == "td") {
                this.setState({ tempItem: item, tempAmount: item.Amount });
                setTimeout(() => {
                    this.addInvoiceTunai();
                }, 500);
            }
        }

        handlePilihCoa(i) {
            let data = this.state.dataPayment;
            let CoaCode = data[i].Code;
            let CoaID = data[i].ID;
            let CoaName = data[i].Description;
            this.setState({ tempCoaCode: CoaCode, tempCoaName: CoaName, tempCoaID: CoaID });
        }

        addInvoiceTunai() {
            if (this.state.tempCoaCode != "") {
                let data = this.state.dataTempInvoice;
                let item = this.state.tempItem;
                let isTrue = true;
                for (let ii of data) if (ii.ID == item.ID) isTrue = false;
                if (isTrue == true) {
                    let tempData = {
                        ID: item.ID,
                        DocNumber: item.KasirID,
                        NoInvoice: item.NoInvoice,
                        SalesName: item.SalesName,
                        CustomerName: item.CustomerName,
                        AmountOpen: item.AmountOpen,
                        Amount: item.Amount,
                        AmountBalance: item.AmountBalance,
                        CoaCode: this.state.tempCoaCode,
                        CoaName: this.state.tempCoaName,
                        CoaID: this.state.tempCoaID
                    };

                    data.push(tempData);
                    this.setState({ dataTempInvoice: data });
                    this.handleCariInvoice("");
                } else {
                    pesan("Pemberitahuan", "No Invoice Sudah Di pilih", "warning");
                }
            } else {
                pesan("Pemberitahuan", "Silahkan pilih Akun Bank", "warning");
            }
        }

        deleteInvocieTunai(i) {
            let data = this.state.dataTempInvoice;
            data.splice(i, 1);
            this.setState({ dataTempInvoice: data });
            setTimeout(() => {
                this.handleCariInvoice("");
            }, 500);
        }

        async handleKirimTunai(e) {
            e.preventDefault();
            e.stopPropagation();
            let form = e.target;
            if (form.checkValidity()) {
                let btn = document.getElementById('btnKirimTunai');
                btn.disabled = true;
                btn.innerText = 'Memproses . . .';
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
                let data = new FormData(form);
                fetch(host + "kirim_pembayaran_tunai", {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        this.main();
                        this.setState({ dataTempInvoice: [], tempAmount: 0 })
                        pesan("Proses Success", hasil.message, "primary");
                        btn.disabled = false;
                        btn.innerHTML = "Kirim";
                        document.getElementById('btnTutupKirimTunai').click();
                    } else {
                        btn.disabled = false;
                        btn.innerHTML = "Kirim";
                        pesan("Peringatan", hasil.message, "danger");
                    }
                }).catch((error) => {
                    btn.disabled = false;
                    btn.innerHTML = "Kirim";
                    console.log("Error: " + error);
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        async handleDetail(Detail) {
            let sql = await api("detail_giro", { ID: Detail.ID, Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ Detail: Detail, DetailPayment: sql.data, divImg: <img src={this.state.host + Detail.Berkas} style={{ width: "100%" }} /> });
            }
        }

        async handleReject(ID, DocNumber) {
            let btn = document.getElementById('btnRejectKacab');
            btn.disabled = true;
            btn.innerText = 'Memproses . . .';
            let sql = await api("reject_tagihan_kacab", { ID: ID, DocNumber: DocNumber, Token: getCookie("Token"), UserID: getCookie("UserID"), Notes: this.state.Notes });
            if (sql.status == "true") {
                btn.disabled = false;
                btn.innerText = 'Reject';
                pesan("Pemberitahuan", sql.message, "primary");
                App();
                document.getElementById('btnTutupDetail').click();
            } else {
                btn.disabled = false;
                btn.innerText = 'Reject';
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleDetailARAP(NoInvoice) {
            let sql = await api("detailARAP", { NoInvoice: NoInvoice, Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ dataARAP: sql.data });
            }
        }

        async handleEdit(data, type) {
            if (type == 'Transfer') {
                ReactDOM.render(<FormsTransfer ID={data.ID} IDHeader={this.props.DocNumber} menu="Kasir" from="Barutunaikacab" />, document.getElementById("tampil"));
            } else if (type == "Tunai") {
                ReactDOM.render(<FormsTunai ID={data.ID} IDHeader={this.props.DocNumber} menu="Kasir" from="Barutunaikacab" />, document.getElementById("tampil"));
            } else {
                ReactDOM.render(<FormsGiro ID={data.ID} IDHeader={this.props.DocNumber} menu="Kasir" from="Barutunaikacab" />, document.getElementById("tampil"));
            }
        }

        render() {
            let tinggiLayar = window.screen.height;
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Kacab Baru Tunai</h2>
                        <div className='row'>
                            <div className='col-md-3'>
                                <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#modalKirimKePusat" onClick={() => this.handleCariInvoice("")}>Kirim Tagihan Ke Pusat</button>
                            </div>
                            <div className='col-md-9'>
                                <div className='input-group gap-1'>
                                    <input type="search" className='form-control' placeholder='Cari No Invoice' value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                                    <input type="date" className='form-control' value={this.state.D1} onChange={(e) => this.handleChangeDate('D1', e.target.value)} />
                                    <input type="date" className='form-control' value={this.state.D2} onChange={(e) => this.handleChangeDate('D2', e.target.value)} />
                                    <button className='btn btn-primary' onClick={(e) => this.main()}><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <DataGrid dataSource={this.state.data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            <Column caption=""
                                cellRender={(item) => (
                                    <div className='d-flex gap-2'>
                                        <button className='btn' data-bs-toggle="modal" data-bs-target="#modalDetail" type="button" onClick={() => this.handleDetail(item.data)}>
                                            <span className='material-icons'>visibility</span>
                                        </button>
                                        <button className='btn' onClick={() => this.handleEdit(item.data, 'Tunai')}>
                                            <span className='material-icons'>edit</span>
                                        </button>
                                    </div>
                                )}
                            />
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <TotalItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                                <GroupItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Awal : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Pembayaran : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Akhir : {0}" showInGroupFooter={false} alignByColumn={true} />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                    </div>

                    <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="modalDetail" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <table className='table'>
                                                <tbody>
                                                    <tr>
                                                        <td width="120px">Nama Pelanggan</td>
                                                        <td width="15px">:</td>
                                                        <td>{this.state.Detail.CustomerName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>No Invoice</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.NoInvoice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Awal</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountOpen)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.Amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Akhir</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountBalance)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tanggal Pelunasan</td>
                                                        <td>:</td>
                                                        <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.PaymentType}</td>
                                                    </tr>
                                                    {this.state.TRReject}
                                                </tbody>
                                            </table>
                                            <p></p>
                                            <div className='table-responsive'>{this.state.tableGiro}</div>
                                            <p></p>
                                            <div className='form-group'>
                                                <label>Catatan</label>
                                                <textarea name='Notes' className="form-control" placeholder='Isi Keterangan' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            {this.state.divImg}
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                    <button type="button" id="btnRejectKacab" className="btn btn-danger" onClick={() => this.handleReject(this.state.Detail.ID, this.state.Detail.KacabID)}>Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalKirimKePusat" tabIndex="-1" aria-labelledby="modalKirimKePusat" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-fullscreen">
                            <div className="modal-content">
                                <form id="formKirim" onSubmit={(e) => this.handleKirimTunai(e)} className="needs-validation" noValidate>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modalKirimKePusatLabel">Kirim Ke Pusat</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body" style={{ overflowY: "scroll", maxHeight: tinggiLayar - 250 + "px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Tanggal Setor</label>
                                                    <input type="date" className='form-control' value={this.state.tanggalSetor} onChange={(e) => this.setState({ tanggalSetor: e.target.value })} name="TanggalPembayaran" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Bukti Transfer</label>
                                                    <input type="file" accept='image/*' className='form-control' name="Img" id="image-upload-tunai" onChange={(e) => this.openFile(e, "labelImgTunai")} required={true} />
                                                    <div className='invalid-feedback'>Silahkan Pilih bukti transfer</div>
                                                </div>
                                            </div>
                                        </div>
                                        <p></p>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h6>Data Penagihan : {numberFormat(this.state.TotalTagihan)}</h6>
                                            </div>
                                            <div className='col-md-6'>
                                                <h6>Data Terpilih : {numberFormat(this.state.totalTerpilih)}</h6>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6 mb-1'>
                                                <div className='row'>
                                                    <div className='col-md-6 mb-1'>
                                                        <input type="search" className='form-control' id="edtCariInooice" placeholder='Cari No Invoice' onKeyUp={(e) => this.handleCariInvoice(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mb-1'>
                                                        <select className='form-select' onChange={(e) => this.handlePilihCoa(e.target.value)} required={true}>
                                                            <option value="">Silahkan Pilih Akun Bank</option>
                                                            {
                                                                this.state.dataPayment.map((item, index) => {
                                                                    return (<option key={index} value={index}>{item.Description}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='table-responsive' style={{ overflowY: "scroll", maxHeight: tinggiLayar - 300 + "px" }}>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Dokumen</th>
                                                                <th>No Invoice</th>
                                                                <th>Sales</th>
                                                                <th>Pelanggan</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.dataPenagihanTunai.map((item, index) => {
                                                                    return (
                                                                        <tr key={index} onClick={(e) => this.pilihInvoice(e, item)} style={{ cursor: "pointer" }}>
                                                                            <td>{item.KasirID}</td>
                                                                            <td><span className='btn btn-link' data-bs-toggle="modal" data-bs-target="#modalDetailARAP" onClick={() => this.handleDetailARAP(item.NoInvoice)}>{item.NoInvoice}</span></td>
                                                                            <td>{item.SalesName}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                            <td>{item.CoaName}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-1' style={{ overflowY: "scroll", maxHeight: "450px" }}>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>No Dokumen</th>
                                                                <th>No Invoice</th>
                                                                <th>Sales</th>
                                                                <th>Pelanggan</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                                <th>Bank</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.dataTempInvoice.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <span className='material-icons' onClick={() => this.deleteInvocieTunai(index)} style={{ cursor: "pointer" }}>delete</span>
                                                                            </td>
                                                                            <td>{item.DocNumber}</td>
                                                                            <td><span className='btn btn-link' data-bs-toggle="modal" data-bs-target="#modalDetailARAP" onClick={() => this.handleDetailARAP(item.NoInvoice)}>{item.NoInvoice}</span></td>
                                                                            <td>{item.SalesName}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                            <td>{item.CoaName}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name="ChangeImg" value={this.state.ChangeImg} />
                                    <input type="hidden" name="Token" value={getCookie("Token")} />
                                    <input type="hidden" name="data" value={JSON.stringify(this.state.dataTempInvoice)} />
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupKirimTunai" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning" id="btnKirimTunai">Kirim</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDetailARAP" tabIndex="-1" aria-labelledby="modalDetailARAP" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Histori Pembayaran</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>Nomor Dokumen</th>
                                                    <th>Saldo Awal</th>
                                                    <th>Pembayaran</th>
                                                    <th>Saldo Akhir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataARAP.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{tanggalIndo(item.DocDate)}</td>
                                                                <td>{item.DocNumber}</td>
                                                                <td>{numberFormat(item.AmountOpen)}</td>
                                                                <td>{numberFormat(item.AmountSettled)}</td>
                                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment >
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export function rendDetailTunaiKacab() {
    App();
}

export default App;