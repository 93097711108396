import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, tanggalIndo, numberFormat, getCookie, pesan } from "../modul";

function App() {
    document.title = "Pembayaran Tunai";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                searchcount: 50,
                index: 0,
                sort: "TanggalPembayaran",
                by: "DESC",
                SalesID: "",
                DocNumber: "",
                detail: {},
                host: window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/"
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        handleDetail(detail) {
            this.setState({ detail: detail });
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                PusatID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID, 
                Token:getCookie("Token")
            }
            let sql = await api("list_sales_all", param);
            if (sql.status == "true") {
                this.setState({ data: sql.data });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Pembayaran Tunai</h2>
                        <div className='row'>
                            <div className='col-md-9'></div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="100px"></th>
                                        <th width="150px">Nomor Dokumen</th>
                                        <th width="100px">Tanggal</th>
                                        <th width="150px">Sales</th>
                                        <th width="150px">Nama Pelanggan</th>
                                        <th width="150px">Saldo Awal</th>
                                        <th width="150px">Pembayaran</th>
                                        <th width="150px">Saldo Akhir</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, i) => {
                                            let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                            return (
                                                <tr>
                                                    <td>
                                                        <button className='btn' onClick={() => this.handleDetail(item)} data-bs-target="#modalDetail" data-bs-toggle="modal">
                                                            <span className='material-icons'>visibility</span>
                                                        </button>
                                                    </td>
                                                    <td>{item.DocNumber}</td>
                                                    <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                    <td>{item.SalesName}</td>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{numberFormat(item.AmountOpen)}</td>
                                                    <td>{numberFormat(item.Amount)}</td>
                                                    <td>{numberFormat(item.AmountBalance)}</td>
                                                    <td>{status}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                    <div className="modal fade" id="modalDetail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <table className='table'>
                                                <tr>
                                                    <td width="150px">Nama Pelanggan</td>
                                                    <td width="10px">:</td>
                                                    <td>{this.state.detail.CustomerName}</td>
                                                </tr>
                                                <tr>
                                                    <td width="150px">Tanggal Pelunasan</td>
                                                    <td width="10px">:</td>
                                                    <td>{tanggalIndo(this.state.detail.TanggalPembayaran)}</td>
                                                </tr>
                                                <tr>
                                                    <td width="150px">Saldo Awal</td>
                                                    <td width="10px">:</td>
                                                    <td>{numberFormat(this.state.detail.AmoutOpen)}</td>
                                                </tr>
                                                <tr>
                                                    <td width="150px">Pembayaran</td>
                                                    <td width="10px">:</td>
                                                    <td>{numberFormat(this.state.detail.Amount)}</td>
                                                </tr>
                                                <tr>
                                                    <td width="150px">Saldo Akhir</td>
                                                    <td width="10px">:</td>
                                                    <td>{numberFormat(this.state.detail.AmountBalance)}</td>
                                                </tr>
                                                <tr>
                                                    <td width="150px">Sales</td>
                                                    <td width="10px">:</td>
                                                    <td>{this.state.detail.SalesName}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className='col-md-6'>
                                            <img src={this.state.host + this.state.detail.Berkas} width="100%" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupKirim" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;