import React, { Fragment } from 'react';
import { api, getCookie, host, pesan, tanggalIndo, numberFormat, saiki, cekStatus } from '../modul';
import { rendDetail } from './Barukasir';
import { rendDetailTunaiKacab } from './Barutunaikacab';
import { rendDetailKacab } from './Barukacab';
import img_upload from '../bg_uppload-image.png';

export function FormsTunai(opt) {
    class FormTunai extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                UserID: getCookie("UserID"),
                NoInvoice: "",
                AmountOpen: "",
                Amount: "",
                Diskon: "0",
                DocDate: saiki(),
                AmountBalance: "",
                CustomerID: "",
                CustomerName: "",
                PaymentType: "Tunai",
                TaxType: "NON PPN",
                Berkas: "",
                ChangeImg: "no",
                DocDateInvoice: saiki(),
                listPelanggan: [],
                listTagihan: [],
                totalSaldoAwal: 0,
                totalTagihanPending: 0,
                totalTagihan: 0,
                btnDelete: "none",
                TanggalPembayaran: saiki(),
                detailTagihan: [],
                SaldoOpen: 0,
                SaldoTertagih: 0,
                SaldoSisa: 0
            }
        }

        async componentDidMount() {
            let ID = this.props.ID;
            if (ID != "") {
                let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("Token") });
                if (sql.status == "true") {
                    this.setState({
                        NoInvoice: sql.data.NoInvoice,
                        AmountOpen: sql.data.AmountOpen,
                        Amount: sql.data.Amount,
                        AmountBalance: sql.data.AmountBalance,
                        CustomerID: sql.data.CustomerID,
                        CustomerName: sql.data.CustomerName,
                        Berkas: sql.data.Berkas,
                        DocDateInvoice: sql.data.DocDateInvoice2,
                        btnDelete: "block",
                        TanggalPembayaran: sql.data.TanggalPembayaran2,
                        TaxType: sql.data.TaxType,
                        DocDate: sql.data.DocDateInvoice2
                    });
                }
            }
        }

        async handleInvoiceList(q = "") {
            if (this.state.CustomerID != "") {
                let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("Token") });
                if (sql.status == "true") {
                    let totalTagihan = 0,
                        totalSaldoAwal = 0,
                        totalTagihanPending = 0;
                    for (let data of sql.data) {
                        totalSaldoAwal += parseFloat(data.AmountBalance);
                        totalTagihanPending += parseFloat(data.Pending);
                        totalTagihan += parseFloat(data.AmountBalance - data.Pending);
                    }
                    this.setState({
                        listTagihan: sql.data,
                        totalSaldoAwal: totalSaldoAwal,
                        totalTagihanPending: totalTagihanPending,
                        totalTagihan: totalTagihan
                    });
                }
            } else {
                alert("Silahkan pilih pelanggan terlebih dahulu");
                document.getElementById('edtCustomerName').focus();
                setTimeout(() => {
                    document.getElementById('btnTutupModalTagihan').click();
                }, 500);
            }
        }

        async cariPelanggan(q) {
            let sql = await api("list_pelanggan", { q: q, SalesID: "all", Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ listPelanggan: sql.data });
            }
        }

        handlePilihPelanggan(i) {
            let pelanggan = this.state.listPelanggan[i];
            this.setState({
                CustomerID: pelanggan.ID,
                CustomerName: pelanggan.Name,
                NoInvoice: "",
                AmountOpen: 0,
                Amount: 0,
                Diskon: 0,
                AmountBalance: 0,
                Berkas: "",
                DocDateInvoice: saiki(),
                TanggalPembayaran: saiki()
            });
            document.getElementById('btnTutupModalPelanggan').click();
        }

        async handlePilihTagihan(i) {
            let tagihan = this.state.listTagihan[i];
            let sql = await api("detail_pembayaran_sales", { NoInvoice: tagihan.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                let Total = 0;
                if (sql.data.length > 0) {
                    for (let data of sql.data) Total += parseFloat(data.Amount);
                    this.setState({
                        detailTagihan: sql.data,
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance - Total,
                        SaldoOpen: tagihan.AmountBalance,
                        SaldoTertagih: Total,
                        SaldoSisa: tagihan.AmountBalance - Total,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    const myModal = new window.bootstrap.Modal('#modalDetailTagihan', {})
                    myModal.show('#modalDetailTagihan');
                } else {
                    this.setState({
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        TanggalJatuhTempoGiro: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    document.getElementById('btnTutupModalTagihan').click();
                }
            }
        }

        handlePilihTrans2() {
            document.getElementById('btnTutupModalDetailPembayaran').click();
            document.getElementById('btnTutupModalTagihan').click();
        }

        handleCancelPilihTrans() {
            this.setState({
                NoInvoice: "",
                TaxType: "NON PPN",
                AmountOpen: 0,
                DocDateInvoice: saiki(),
                AmountBalance: 0,
                SaldoOpen: 0,
                SaldoSisa: 0,
                SaldoTertagih: 0,
                Amount: 0,
                Diskon: 0,
                GiroNumber: "",
                GiroTermDate: saiki(),
                Berkas: "",
                TanggalPembayaran: saiki()
            });
        }

        handlePembayaran(e) {
            let val = e.target.value;
            let total = this.state.AmountOpen - val;
            this.setState({ Amount: val, AmountBalance: total });
        }

        hanldeDiskon(e) {
            let val = e.target.value;
            let total = this.state.AmountOpen - this.state.Amount - val;
            this.setState({ Diskon: val, AmountBalance: total });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ ChangeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        async handleDelete() {
            let sql = await api("delete_data_sales", { ID: this.props.ID, DocNumber: this.state.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                pesan("Berhasil Hapus Data");
                // App("Tunai");
            } else {
                pesan("Gagal Hapus Data");
            }
        }

        handleSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            let form = e.target;
            let btn = form.querySelectorAll("button[type='submit']")[0];
            if (form.checkValidity()) {
                btn.innerHTML = `<span className="spinner-border spinner-border-sm"></span> Simpan`;
                btn.disabled = true;
                let data = new FormData(form);
                fetch(`${host}edit_${this.state.PaymentType}`, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        if (this.props.from == "Barutunaikacab") {
                            rendDetailTunaiKacab(this.props.IDHeader);
                        } else if (this.props.from == "Barukasir") {
                            rendDetail(this.props.IDHeader);
                        } else if (this.props.from == "Barukacab") {
                            rendDetailKacab(this.props.IDHeader);
                        }
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = "Simpan";
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = "Simpan";
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        handleBack() {
            if (this.props.from == "Barutunaikacab") {
                rendDetailTunaiKacab(this.props.IDHeader);
            } else if (this.props.from == "Barukasir") {
                rendDetail(this.props.IDHeader);
            } else if (this.props.from == "Barukacab") {
                rendDetailKacab(this.props.IDHeader);
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );

            if (this.state.Berkas != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/";
                divImg = <img src={host + "file/" + this.state.Berkas} width="256px" height="159px" />
            }

            return (
                <Fragment>
                    <form onSubmit={(e) => this.handleSubmit(e)} noValidate>
                        <div className='container main' id='mainContent'>
                            <input type="hidden" value={this.props.ID} name="ID" />
                            <input type="hidden" value={this.props.menu} name="menu" />
                            <input type="hidden" value={getCookie("Token")} name="Token" />
                            <input type="hidden" value={this.state.UserID} name="UserID" />
                            <input type="hidden" value="Tunai" name="PaymentType" />
                            <input type="hidden" value={saiki()} name="GiroTermDate" />
                            <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                            <input type="hidden" value={this.state.ChangeImg} name="ChangeImg" />
                            <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                            <input type="hidden" value={this.state.TaxType} name="TaxType" />
                            <div className='form-group'>
                                <label>Nama Pelanggan</label>
                                <div className='input-group'>
                                    <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                    <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Nomor Dokumen</label>
                                <div className='input-group'>
                                    <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                    <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Saldo Awal</label>
                                <input type="text" name="AmountOpen" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                            </div>
                            <div className='form-group'>
                                <label>Pembayaran</label>
                                <input type="number" name="Amount" step="0.01" value={this.state.Amount} onChange={(e) => this.handlePembayaran(e)} max={this.state.AmountOpen} className="form-control" placeholder='Jumlah Bayar' required />
                                <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                            </div>
                            <input type="hidden" name="Diskon" value={this.state.Diskon} onChange={(e) => this.hanldeDiskon(e)} max={this.state.SaldoAwal} className="form-control" placeholder='Diskon Jika Ada' />
                            {/* <div className='form-group'>
                                <label>Diskon</label>
                            </div> */}
                            <div className='form-group'>
                                <label>Saldo Akhir</label>
                                <input type="text" name="AmountBalance" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                            </div>
                            <div className='form-group'>
                                <label>Tanggal Penagihan</label>
                                <input type="date" name="TanggalPembayaran" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" placeholder='Tanggal Penagihan' required />
                                <div className="text-danger">Tanggal Tidak boleh dibawah tanggal invoice</div>
                            </div>
                            <div className="form-group">
                                <label>Bukti</label>
                                <div className='image-upload'>
                                    <label id="labelImg" htmlFor="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p style={{ marginTop: "100px" }}></p>
                            <div className="d-flex gap-2">
                                <button type="button" className="btn btn-secondary" id="btnBatal" onClick={() => this.handleBack()}>Batal</button>
                                <button type="submit" className="btn btn-primary" id="btnSimpan">Simpan</button>
                            </div>
                        </div>
                    </form>

                    <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-sm table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listPelanggan.map((item, index) => {
                                                        return (
                                                            <tr onClick={() => this.handlePilihPelanggan(index)} key={index}>
                                                                <td>{item.Name}</td>
                                                                <td>{item.Telp}</td>
                                                                <td>{item.Address}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-sm table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>No Invoice</th>
                                                    <th>Tanggal</th>
                                                    <th>Usia</th>
                                                    <th>PPN</th>
                                                    <th>Saldo Awal</th>
                                                    <th>Pending</th>
                                                    <th>Sisa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listTagihan.map((item, index) => {
                                                        return (
                                                            <tr onClick={() => this.handlePilihTagihan(index)} key={index}>
                                                                <td>{item.DocNumber}</td>
                                                                <td>{tanggalIndo(item.DocDate)}</td>
                                                                <td>{item.UmurNota}</td>
                                                                <td>{item.TaxType}</td>
                                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                                <td>{numberFormat(item.Pending)}</td>
                                                                <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4}>Total {this.state.listTagihan.length}</td>
                                                    <td>{numberFormat(this.state.totalSaldoAwal)}</td>
                                                    <td>{numberFormat(this.state.totalTagihanPending)}</td>
                                                    <td>{numberFormat(this.state.totalTagihan)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDetailTagihan" tabIndex="-1" aria-labelledby="modalDetailTagihan" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Setoran</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnTutupModalDetailPembayaran'></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <div className='table-responsive'>
                                        <h6>Detail Penagihan Tertunda</h6>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>Pembayaran</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.detailTagihan.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                                <td>{numberFormat(item.Amount)}</td>
                                                                <td>{item.State}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}><b>Total</b></td>
                                                    <td><b>{numberFormat(this.state.SaldoTertagih)}</b></td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <h6>Rincian</h6>
                                        <table className='table table-striped'>
                                            <tbody>
                                                <tr>
                                                    <td>Saldo Awal</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoOpen)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Penagihan yang Tertunda</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoTertagih)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sisa Tagihan</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoSisa)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.handleCancelPilihTrans()}>Batal</button>
                                    <button type="button" className="btn btn-primary" onClick={() => this.handlePilihTrans2()} disabled={this.state.AmountOpen > 0 ? false : true}>Pilih</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
    return <FormTunai ID={opt.ID} IDHeader={opt.IDHeader} menu={opt.menu} from={opt.from} />
}

export function FormsTransfer(opt) {
    class FormTransfer extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                UserID: getCookie("UserID"),
                NoInvoice: "",
                DocNumber: "",
                AmountOpen: "0",
                Amount: "0",
                Diskon: "0",
                AmountBalance: "0",
                CustomerID: "",
                CustomerName: "",
                PaymentType: "Transfer",
                TaxType: "NON PPN",
                GiroNumber: "",
                GiroTermDate: "",
                Berkas: "",
                ChangeImg: "no",
                TanggalPembayaran: saiki(),
                DocDateInvoice: saiki(),
                listTagihan: [],
                totalSaldoAwal: 0,
                totalTagihanPending: 0,
                totalTagihan: 0,
                listPelanggan: [],
                btnDelete: "none",
                arrBank: [],
                CoaCode: "",
                detailTagihan: [],
                SaldoOpen: 0,
                SaldoTertagih: 0,
                SaldoSisa: 0,
                DocDate: saiki()
            }
        }

        async componentDidMount() {
            let ID = this.props.ID;
            console.log(this.props.IDHeader);
            let bank = await api("akun_bank", { Token: getCookie("Token") });
            if (bank.status == "true") this.setState({ arrBank: bank.data });
            if (ID != "") {
                let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("Token") });
                if (sql.status == "true") {
                    this.setState({
                        NoInvoice: sql.data.NoInvoice,
                        DocNumber: sql.data.DocNumber,
                        AmountOpen: sql.data.AmountOpen,
                        Amount: sql.data.Amount,
                        Diskon: sql.data.Diskon,
                        AmountBalance: sql.data.AmountBalance,
                        CustomerID: sql.data.CustomerID,
                        CustomerName: sql.data.CustomerName,
                        GiroNumber: sql.data.GiroNumber,
                        GiroTermDate: sql.data.GiroTermDate,
                        TanggalPembayaran: sql.data.TanggalPembayaran2,
                        Berkas: sql.data.Berkas,
                        DocDateInvoice: sql.data.DocDateInvoice2,
                        btnDelete: "block",
                        CoaCode: sql.payment.CoaCode,
                        TaxType: sql.data.TaxType,
                        DocDate: sql.data.DocDateInvoice2
                    });
                }
            }
        }

        async handleInvoiceList(q = "") {
            if (this.state.CustomerID != "") {
                let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("Token") });
                if (sql.status == "true") {
                    let totalTagihan = 0,
                        totalSaldoAwal = 0,
                        totalTagihanPending = 0;
                    for (let data of sql.data) {
                        totalSaldoAwal += parseFloat(data.AmountBalance);
                        totalTagihanPending += parseFloat(data.Pending);
                        totalTagihan += parseFloat(data.AmountBalance - data.Pending);
                    }
                    this.setState({
                        listTagihan: sql.data,
                        totalSaldoAwal: totalSaldoAwal,
                        totalTagihanPending: totalTagihanPending,
                        totalTagihan: totalTagihan
                    });
                }
            } else {
                alert("Silahkan pilih pelanggan terlebih dahulu");
                document.getElementById('edtCustomerName').focus();
                setTimeout(() => {
                    document.getElementById('btnTutupModalTagihan').click();
                }, 500);
            }
        }

        async cariPelanggan(q) {
            let sql = await api("list_pelanggan", { q: q, SalesID: "all", Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ listPelanggan: sql.data });
            }
        }

        handlePilihPelanggan(i) {
            let pelanggan = this.state.listPelanggan[i];
            this.setState({
                CustomerID: pelanggan.ID,
                CustomerName: pelanggan.Name,
                NoInvoice: "",
                SaldoAwal: 0,
                Pembayaran: 0,
                Diskon: 0,
                SaldoAkhir: 0,
                GiroNumber: "",
                TanggalJatuhTempoGiro: saiki(),
                Berkas: "",
                DocDateInvoice: saiki(),
                TanggalPenagihan: saiki(),
                CoaCode: ""
            });
            document.getElementById('btnTutupModalPelanggan').click();
        }

        async handlePilihTagihan(i) {
            let tagihan = this.state.listTagihan[i];
            let sql = await api("detail_pembayaran_sales", { NoInvoice: tagihan.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                let Total = 0;
                if (sql.data.length > 0) {
                    for (let data of sql.data) Total += parseFloat(data.Amount);
                    this.setState({
                        detailTagihan: sql.data,
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance - Total,
                        SaldoOpen: tagihan.AmountBalance,
                        SaldoTertagih: Total,
                        SaldoSisa: tagihan.AmountBalance - Total,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    const myModal = new window.bootstrap.Modal('#modalDetailTagihan', {})
                    myModal.show('#modalDetailTagihan');
                } else {
                    this.setState({
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    document.getElementById('btnTutupModalTagihan').click();
                }
            }
        }

        handlePilihTrans2() {
            document.getElementById('btnTutupModalDetailPembayaran').click();
            document.getElementById('btnTutupModalTagihan').click();
        }

        handleCancelPilihTrans() {
            this.setState({
                NoInvoice: "",
                AmountOpen: 0,
                DocDateInvoice: saiki(),
                AmountBalance: 0,
                SaldoOpen: 0,
                SaldoSisa: 0,
                SaldoTertagih: 0,
                Amount: 0,
                Diskon: 0,
                GiroNumber: "",
                GiroTermDate: saiki(),
                Berkas: "",
                TanggalPembayaran: saiki(),
                TaxType: "NON PPN"
            });
        }

        handlePembayaran(e) {
            let val = e.target.value;
            let total = this.state.AmountOpen - val;
            this.setState({ Amount: val, AmountBalance: total });
        }

        hanldeDiskon(e) {
            let val = e.target.value;
            let total = this.state.AmountOpen - this.state.Amount - val;
            this.setState({ Diskon: val, AmountBalance: total });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ ChangeImg: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        handleSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            let form = e.target;
            let btn = form.querySelectorAll("button[type='submit']")[0];
            if (form.checkValidity()) {
                btn.innerHTML = `<span className="spinner-border spinner-border-sm"></span> Simpan`;
                btn.disabled = true;
                let data = new FormData(form);
                fetch(`${host}edit_${this.state.PaymentType}`, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    console.log(hasil);
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        if (this.props.from == "Barutunaikacab") {
                            rendDetailTunaiKacab(this.props.IDHeader);
                        } else if (this.props.from == "Barukasir") {
                            rendDetail(this.props.IDHeader);
                        } else if (this.props.from == "Barukacab") {
                            rendDetailKacab(this.props.IDHeader);
                        }
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = "Simpan";
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = "Simpan";
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        handleBack() {
            if (this.props.from == "Barutunaikacab") {
                rendDetailTunaiKacab(this.props.IDHeader);
            } else if (this.props.from == "Barukasir") {
                rendDetail(this.props.IDHeader);
            } else if (this.props.from == "Barukacab") {
                rendDetailKacab(this.props.IDHeader);
            }
        }

        render() {
            let divImg = (
                <img src={img_upload} width="256px" height="159px" />
            );
            if (this.state.Berkas != "") {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/";
                divImg = <img src={host + "file/" + this.state.Berkas} width="256px" height="159px" />
            }
            return (
                <Fragment>
                    <form onSubmit={(e) => this.handleSubmit(e)} noValidate>
                        <div className='container main' id='mainContent'>
                            <input type="hidden" value={this.props.ID} name="ID" />
                            <input type="hidden" value={this.props.menu} name="menu" />
                            <input type="hidden" value={getCookie("Token")} name="Token" />
                            <input type="hidden" value={this.state.UserID} name="UserID" />
                            <input type="hidden" value="Transfer" name="PaymentType" />
                            <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                            <input type="hidden" value={this.state.ChangeImg} name="ChangeImg" />
                            <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                            <input type="hidden" value={this.state.TaxType} name="TaxType" />
                            <input type="hidden" name="Diskon" value="0" />
                            <input type="hidden" value={JSON.stringify([])} name="DataGiro" />
                            <input type="hidden" value={saiki()} name="GiroTermDate" />

                            <div className='form-group'>
                                <label>Nama Pelanggan</label>
                                <div className='input-group'>
                                    <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                    <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Nomor Dokumen</label>
                                <div className='input-group'>
                                    <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                    <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Saldo Awal</label>
                                <input type="text" name="AmountOpen" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                            </div>
                            <div className='form-group'>
                                <label>Pembayaran</label>
                                <input type="number" name="Amount" step="0.01" value={this.state.Amount} onChange={(e) => this.handlePembayaran(e)} max={this.state.AmountOpen} className="form-control" placeholder='Jumlah Bayar' required />
                                <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                            </div>
                            <div className='form-group'>
                                <label>Saldo Akhir</label>
                                <input type="text" name="AmountBalance" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                            </div>
                            <div className='form-group'>
                                <label>Tanggal Transfer</label>
                                <input type="date" name="TanggalPembayaran" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" required />
                                <div className="text-danger">Tanggal Tidak boleh dibawah tanggal invoice</div>
                            </div>
                            <div className='form-group'>
                                <label>Akun Bank</label>
                                <select className='form-select' name="CoaCode" value={this.state.CoaCode} onChange={(e) => this.setState({ CoaCode: e.target.value })} required>
                                    <option value="">Silahkan Pilih Bank</option>
                                    {
                                        this.state.arrBank.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Code}>{item.Code} - {item.Description}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Bukti</label>
                                <div className='image-upload'>
                                    <label id="labelImg" htmlFor="image-upload">
                                        {divImg}
                                    </label>
                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                    <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                </div>
                            </div>
                            <p style={{ marginTop: "100px" }}></p>
                            <div className="d-flex gap-2">
                                <button type="button" className="btn btn-secondary" id="btnBatal" onClick={() => this.handleBack()}>Batal</button>
                                <button type="submit" className="btn btn-primary" id="btnSimpan">Simpan</button>
                            </div>
                        </div>
                    </form>

                    <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-sm table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listPelanggan.map((item, index) => {
                                                        return (
                                                            <tr onClick={() => this.handlePilihPelanggan(index)} key={index}>
                                                                <td>{item.Name}</td>
                                                                <td>{item.Telp}</td>
                                                                <td>{item.Address}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped table-sm table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>No Invoice</th>
                                                    <th>Tanggal</th>
                                                    <th>Usia</th>
                                                    <th>PPN</th>
                                                    <th>Saldo Awal</th>
                                                    <th>Pending</th>
                                                    <th>Sisa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listTagihan.map((item, index) => {
                                                        return (
                                                            <tr onClick={() => this.handlePilihTagihan(index)} key={index}>
                                                                <td>{item.DocNumber}</td>
                                                                <td>{tanggalIndo(item.DocDate)}</td>
                                                                <td>{item.UmurNota}</td>
                                                                <td>{item.TaxType}</td>
                                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                                <td>{numberFormat(item.Pending)}</td>
                                                                <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4}>Total {this.state.listTagihan.length}</td>
                                                    <td>{numberFormat(this.state.totalSaldoAwal)}</td>
                                                    <td>{numberFormat(this.state.totalTagihanPending)}</td>
                                                    <td>{numberFormat(this.state.totalTagihan)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDetailTagihan" tabIndex="-1" aria-labelledby="modalDetailTagihan" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Setoran</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnTutupModalDetailPembayaran'></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <div className='table-responsive'>
                                        <h6>Detail Penagihan Tertunda</h6>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>Pembayaran</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.detailTagihan.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                                <td>{numberFormat(item.Amount)}</td>
                                                                <td>{item.State}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}><b>Total</b></td>
                                                    <td><b>{numberFormat(this.state.SaldoTertagih)}</b></td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <h6>Rincian</h6>
                                        <table className='table table-striped'>
                                            <tbody>
                                                <tr>
                                                    <td>Saldo Awal</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoOpen)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Penagihan yang Tertunda</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoTertagih)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sisa Tagihan</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoSisa)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.handleCancelPilihTrans()}>Batal</button>
                                    <button type="button" className="btn btn-primary" onClick={() => this.handlePilihTrans2()} disabled={this.state.AmountOpen > 0 ? false : true}>Pilih</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
    return <FormTransfer ID={opt.ID} IDHeader={opt.IDHeader} menu={opt.menu} from={opt.from} />
}

export function FormsGiro(opt) {
    class FormGiro extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                UserID: getCookie("UserID"),
                NoInvoice: "",
                DocNumber: "",
                AmountOpen: "0",
                Amount: "0",
                Diskon: "0",
                AmountBalance: "0",
                CustomerID: "",
                CustomerName: "",
                PaymentType: "Giro",
                TaxType: "NON PPN",
                GiroNumber: "",
                GiroTermDate: saiki(),
                Berkas: "",
                ChangeImg: "no",
                TanggalPembayaran: saiki(),
                DocDateInvoice: saiki(),
                listTagihan: [],
                totalSaldoAwal: 0,
                totalTagihanPending: 0,
                totalTagihan: 0,
                listPelanggan: [],
                btnDelete: "none",
                tempGiroNumber: "",
                tempGiroTermDate: saiki(),
                tempGiroNominal: 0,
                tempIndex: "tidak",
                tempDataGiro: [],
                totalGiro: 0,
                arrBank: [],
                tempCoaCode: "",
                tempCoaName: "",
                tempCoaID: "",
                tempDocID: "",
                tempID: "",
                tempData: [],
                formMode: "add",
                Berkas: "",
                detailTagihan: [],
                SaldoOpen: 0,
                SaldoTertagih: 0,
                SaldoSisa: 0,
                DocDate: saiki()
            }
        }

        async componentDidMount() {
            let ID = this.props.ID;
            let bank = await api("akun_bank", { Token: getCookie("Token") });
            if (bank.status == "true") this.setState({ arrBank: bank.data });
            let tmp = await api("get_temp_giro", { SalesID: this.state.UserID, Token: getCookie("Token") });
            if (tmp.status == "true") this.setState({ tempDocID: tmp.data });
            if (ID != "") {
                let sql = await api("detail_penagihan", { ID: ID, Token: getCookie("Token") });
                let totalGiro = 0;
                for (let giro of sql.giro) totalGiro += parseFloat(giro.GiroNominal);
                if (sql.status == "true") {
                    this.setState({
                        NoInvoice: sql.data.NoInvoice,
                        AmountOpen: sql.data.AmountOpen,
                        Amount: sql.data.Amount,
                        AmountBalance: sql.data.AmountBalance,
                        CustomerID: sql.data.CustomerID,
                        CustomerName: sql.data.CustomerName,
                        GiroNumber: sql.data.GiroNumber,
                        GiroTermDate: sql.data.GiroTermDate2,
                        TanggalPembayaran: sql.data.TanggalPelmbayaran2,
                        Berkas: sql.data.Berkas,
                        DocDateInvoice: sql.data.DocDateInvoice2,
                        TaxType: sql.data.TaxType,
                        btnDelete: "block",
                        tempDataGiro: sql.giro,
                        totalGiro: totalGiro,
                        formMode: "edit",
                        DocDate: sql.data.DocDateInvoice2
                    });
                }
            }
        }

        async handleInvoiceList(q = "") {
            if (this.state.CustomerID != "") {
                let sql = await api("arap_list", { CardID: this.state.CustomerID, DocNumber: q, Token: getCookie("Token") });
                if (sql.status == "true") {
                    let totalTagihan = 0,
                        totalSaldoAwal = 0,
                        totalTagihanPending = 0;
                    for (let data of sql.data) {
                        totalSaldoAwal += parseFloat(data.AmountBalance);
                        totalTagihanPending += parseFloat(data.Pending);
                        totalTagihan += parseFloat(data.AmountBalance - data.Pending);
                    }
                    this.setState({
                        listTagihan: sql.data,
                        totalSaldoAwal: totalSaldoAwal,
                        totalTagihanPending: totalTagihanPending,
                        totalTagihan: totalTagihan
                    });
                }
            } else {
                alert("Silahkan pilih pelanggan terlebih dahulu");
                document.getElementById('edtCustomerName').focus();
                setTimeout(() => {
                    document.getElementById('btnTutupModalTagihan').click();
                }, 500);
            }
        }

        async cariPelanggan(q) {
            let sql = await api("list_pelanggan", { q: q, SalesID: "all", Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ listPelanggan: sql.data });
            }
        }

        handlePilihPelanggan(i) {
            let pelanggan = this.state.listPelanggan[i];
            this.setState({
                CustomerID: pelanggan.ID,
                CustomerName: pelanggan.Name,
                NoInvoice: "",
                AmountOpen: 0,
                SaldoAwal: 0,
                Pembayaran: 0,
                Diskon: 0,
                SaldoAkhir: 0,
                GiroNumber: "",
                TanggalJatuhTempoGiro: saiki(),
                Berkas: "",
                DocDateInvoice: saiki(),
                TanggalPenagihan: saiki()
            });
            document.getElementById('btnTutupModalPelanggan').click();
        }

        async handlePilihTagihan(i) {
            let tagihan = this.state.listTagihan[i];
            let sql = await api("detail_pembayaran_sales", { NoInvoice: tagihan.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                let Total = 0;
                if (sql.data.length > 0) {
                    for (let data of sql.data) Total += parseFloat(data.Amount);
                    this.setState({
                        detailTagihan: sql.data,
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance - Total,
                        SaldoOpen: tagihan.AmountBalance,
                        SaldoTertagih: Total,
                        SaldoSisa: tagihan.AmountBalance - Total,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        Diskon: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    const myModal = new window.bootstrap.Modal('#modalDetailTagihan', {})
                    myModal.show('#modalDetailTagihan');
                } else {
                    this.setState({
                        NoInvoice: tagihan.DocNumber,
                        AmountOpen: tagihan.AmountBalance,
                        DocDateInvoice: tagihan.DocDateInvoice,
                        TaxType: tagihan.TaxType,
                        AmountBalance: 0,
                        Amount: 0,
                        GiroNumber: "",
                        GiroTermDate: saiki(),
                        Berkas: "",
                        TanggalPembayaran: saiki(),
                        DocDate: tagihan.DocDateInvoice
                    });
                    document.getElementById('btnTutupModalTagihan').click();
                }
            }
        }

        handlePilihTrans2() {
            document.getElementById('btnTutupModalDetailPembayaran').click();
            document.getElementById('btnTutupModalTagihan').click();
        }

        handleCancelPilihTrans() {
            this.setState({
                NoInvoice: "",
                AmountOpen: 0,
                DocDateInvoice: saiki(),
                AmountBalance: 0,
                SaldoOpen: 0,
                SaldoSisa: 0,
                SaldoTertagih: 0,
                Amount: 0,
                Diskon: 0,
                GiroNumber: "",
                GiroTermDate: saiki(),
                Berkas: "",
                TanggalPembayaran: saiki()
            });
        }

        handlePembayaran(e) {
            let val = e.target.value;
            let total = this.state.AmountOpen - val;
            this.setState({ Amount: val, AmountBalance: total });
        }

        hanldeDiskon(e) {
            let val = e.target.value;
            let total = this.state.SaldoAwal - this.state.Pembayaran - val;
            this.setState({ Diskon: val, SaldoAkhir: total });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="256px" height="159px">`;
                    this.setState({ ChangeImg: "yes", Berkas: reader.result });
                }
                reader.readAsDataURL(file)
            }
        }

        async handleDelete() {
            let sql = await api("delete_data_sales", { ID: this.props.ID, DocNumber: this.state.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                pesan("Berhasil Hapus Data");
            } else {
                pesan("Gagal Hapus Data");
            }
        }

        async modalAddGiro(idx = "tidak") {
            if (idx != "tidak") {
                let giro = this.state.tempDataGiro[idx];
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/";
                this.setState({ tempGiroNumber: giro.GiroNumber, tempGiroTermDate: giro.GiroTermDate, tempGiroNominal: giro.GiroNominal, tempCoaCode: giro.CoaCode, tempCoaID: giro.CoaID, tempCoaName: giro.CoaName, tempIndex: idx, tempID: giro.ID, Berkas: this.state.formMode == 'edit' ? `${host}${giro.GiroImg}` : giro.Berkas });
            } else {
                let sql = await api("get_temp_id", { SalesID: this.state.UserID, DocID: this.state.tempDocID, Token: getCookie("Token") });
                if (sql.status == "true") this.setState({ tempGiroNumber: "", tempGiroTermDate: saiki(), tempGiroNominal: 0, tempCoaCode: "", tempCoaID: "", tempCoaName: "", tempIndex: idx, tempID: sql.ID, Berkas: img_upload });
            }
        }

        saveTempGiro(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnSaveTempGiro');
            btn.disabled = true;
            btn.innerText = "Menyimpan . . .";
            let data = this.state.tempDataGiro;
            let totalGiro = 0;
            let index = this.state.tempIndex;
            this.setState({ tempData: data });
            setTimeout(async () => {
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
                let DATA = new FormData(e.target);
                fetch(host + 'add_temp_giro', {
                    method: 'POST',
                    body: DATA,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        pesan(hasil.message);
                        if (this.state.tempIndex == "tidak") {
                            data.push({
                                GiroNumber: this.state.tempGiroNumber,
                                GiroTermDate: this.state.tempGiroTermDate,
                                GiroNominal: this.state.tempGiroNominal,
                                CoaCode: this.state.tempCoaCode,
                                CoaName: this.state.tempCoaName,
                                CoaID: this.state.tempCoaID,
                                GiroImg: hasil.Img
                            });
                        } else {
                            data[index] = {
                                GiroNumber: this.state.tempGiroNumber,
                                GiroTermDate: this.state.tempGiroTermDate,
                                GiroNominal: this.state.tempGiroNominal,
                                CoaCode: this.state.tempCoaCode,
                                CoaName: this.state.tempCoaName,
                                CoaID: this.state.tempCoaID,
                                GiroImg: hasil.Img
                            };
                        }
                        for (let giro of data) totalGiro += parseFloat(giro.GiroNominal);
                        this.setState({ tempDataGiro: data, tempGiroNominal: 0, tempGiroTermDate: saiki(), tempGiroNumber: "", tempCoaCode: "", totalGiro: totalGiro, tempData: JSON.stringify([]) });
                        btn.disabled = false;
                        btn.innerText = "Simpan";
                        document.getElementById('btnTutupModalAddGiro').click();
                    } else {
                        pesan(hasil.message);
                        btn.disabled = false;
                        btn.innerText = "Simpan";
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    pesan("Terjadi kesalahan");
                    btn.disabled = false;
                    btn.innerText = "Simpan";
                });
            }, 500);
        }

        async deleteTempGiro(idx) {
            let data = this.state.tempDataGiro;
            let sql = await api("delete_temp_giro", { ID: this.state.tempID });
            let totalGiro = 0;
            if (sql.status == 'true') {
                data.splice(idx, 1);
                pesan(sql.message);
                for (let giro of data) totalGiro += parseFloat(giro.GiroNominal);
                this.setState({ tempDataGiro: data, totalGiro: totalGiro });
            } else {
                pesan(sql.message);
            }
        }

        handlePilihBank(Code) {
            let data = this.state.arrBank;
            let CoaName = "", CoaID = "";
            for (let dd of data) {
                if (dd.Code == Code) CoaName = dd.Description;
                if (dd.Code == Code) CoaID = dd.ID;
            }
            this.setState({ tempCoaCode: Code, tempCoaName: CoaName, tempCoaID: CoaID });
        }

        handleSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            let form = e.target;
            let btn = form.querySelectorAll("button[type='submit']")[0];
            if (form.checkValidity()) {
                btn.innerHTML = `<span className="spinner-border spinner-border-sm"></span> Simpan`;
                btn.disabled = true;
                let data = new FormData(form);
                fetch(`${host}edit_${this.state.PaymentType}`, {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    console.log(hasil);
                    if (hasil.status === "true") {
                        pesan("Proses Success", hasil.message, "primary");
                        if (this.props.from == "Barutunaikacab") {
                            rendDetailTunaiKacab(this.props.IDHeader);
                        } else if (this.props.from == "Barukasir") {
                            rendDetail(this.props.IDHeader);
                        } else if (this.props.from == "Barukacab") {
                            rendDetailKacab(this.props.IDHeader);
                        }
                    } else {
                        alert(hasil.message);
                        btn.innerHTML = "Simpan";
                        btn.disabled = false;
                    }
                }).catch((error) => {
                    console.log("Error: " + error);
                    btn.innerHTML = "Simpan";
                    btn.disabled = false;
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        handleBack() {
            if (this.props.from == "Barutunaikacab") {
                rendDetailTunaiKacab(this.props.IDHeader);
            } else if (this.props.from == "Barukasir") {
                rendDetail(this.props.IDHeader);
            } else if (this.props.from == "Barukacab") {
                rendDetailKacab(this.props.IDHeader);
            }
        }

        render() {
            let divImg = (<img src={this.state.Berkas} width="256px" height="159px" />);
            return (
                <Fragment>
                    <form onSubmit={(e) => this.handleSubmit(e)} noValidate>
                        <div className='container main' id='mainContent'>
                            <input type="hidden" value={this.props.ID} name="ID" />
                            <input type="hidden" value={this.props.menu} name="menu" />
                            <input type="hidden" value={getCookie("Token")} name="Token" />
                            <input type="hidden" value={this.state.UserID} name="UserID" />
                            <input type="hidden" value="Giro" name="PaymentType" />
                            <input type="hidden" value={this.state.CustomerID} name="CustomerID" />
                            <input type="hidden" value="no" name="ChangeImg" />
                            <input type="hidden" value={this.state.DocDateInvoice} name="DocDateInvoice" />
                            <input type="hidden" value={this.state.TaxType} name="TaxType" />
                            <div className='form-group'>
                                <label>Nama Pelanggan</label>
                                <div className='input-group'>
                                    <input type="text" className='form-control' id='edtCustomerName' name="CustomerName" value={this.state.CustomerName} onFocus={() => this.cariPelanggan("")} placeholder='Silahkan Pilih Nama Pelanggan' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalPelanggan" />
                                    <button className='btn btn-primary' type="button" onClick={() => this.cariPelanggan("")} data-bs-toggle="modal" data-bs-target="#modalPelanggan"><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Nomor Dokumen</label>
                                <div className='input-group'>
                                    <input type="text" className='form-control' name="NoInvoice" value={this.state.NoInvoice} onFocus={() => this.handleInvoiceList("")} placeholder='Silahkan Pilih Nomor Dokumen' readOnly={true} data-bs-toggle="modal" data-bs-target="#modalInvoice" />
                                    <button className='btn btn-primary' type="button" onClick={() => this.handleInvoiceList("")} data-bs-toggle="modal" data-bs-target="#modalInvoice"><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Saldo Awal</label>
                                <input type="text" name="AmountOpen" value={this.state.AmountOpen} className="form-control" placeholder='Saldo Awal' readOnly={true} />
                            </div>
                            <div className='form-group'>
                                <label>Pembayaran</label>
                                <input type="number" name="Amount" step="0.01" value={this.state.Amount} onChange={(e) => this.handlePembayaran(e)} max={this.state.AmountOpen} onFocus={(e) => e.target.select()} className="form-control" placeholder='Jumlah Bayar' required />
                                <div className='invalid-feedback'>Silahkan Isi Nilai Pembayaran</div>
                            </div>
                            <input type="hidden" name="Diskon" value={this.state.Diskon} onChange={(e) => this.hanldeDiskon(e)} max={this.state.SaldoAwal} className="form-control" placeholder='Diskon Jika Ada' />
                            <div className='form-group'>
                                <label>Saldo Akhir</label>
                                <input type="text" name="AmountBalance" value={this.state.AmountBalance} className="form-control" placeholder='Saldo Akhir' readOnly={true} />
                            </div>
                            <input type="hidden" value={JSON.stringify(this.state.tempDataGiro)} name="DataGiro" />
                            <div className='form-group'>
                                <label>Tanggal Penagihan</label>
                                <input type="date" name="TanggalPembayaran" min={this.state.DocDate} value={this.state.TanggalPembayaran} onChange={(e) => this.setState({ TanggalPembayaran: e.target.value })} className="form-control" required />
                                <div className="text-danger">Tanggal Tidak boleh dibawah tanggal invoice</div>
                            </div>
                            <button className='btn btn-default w-100' type="button" data-bs-toggle="modal" data-bs-target="#modalAddGiro" onClick={() => this.modalAddGiro("tidak")}>Tambah Giro</button>
                            <div className='table-responsive'>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Nomor Giro</th>
                                            <th>Tanggal</th>
                                            <th>Nominal</th>
                                            <th>Bank</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.tempDataGiro.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.GiroNumber}</td>
                                                        <td>{tanggalIndo(item.GiroTermDate)}</td>
                                                        <td>{numberFormat(item.GiroNominal)}</td>
                                                        <td>{item.CoaName}</td>
                                                        <td>
                                                            <span className='material-icons' data-bs-toggle="modal" data-bs-target="#modalAddGiro" onClick={() => this.modalAddGiro(String(index, item.ID))}>edit</span>
                                                            <span className='material-icons' onClick={() => this.deleteTempGiro(String(index))}>delete</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="2">Total</td>
                                            <td style={{ color: this.state.totalGiro != this.state.Amount ? "red" : "black" }}>{numberFormat(this.state.totalGiro)}</td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <p style={{ marginTop: "100px" }}></p>
                            <div className="d-flex gap-2">
                                <button type="button" className="btn btn-secondary" id="btnBatal" onClick={() => this.handleBack()}>Batal</button>
                                <button type="submit" className="btn btn-primary" id="btnSimpan">Simpan</button>
                            </div>
                        </div>
                    </form>

                    <div className="modal fade" id="modalAddGiro" tabIndex="-1" aria-labelledby="modalAddGiro" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <form onSubmit={(e) => this.saveTempGiro(e)} id="formAddGiro">
                                <input type="hidden" name="ID" value={this.state.tempID} />
                                <input type="hidden" value={getCookie("Token")} name="Token" />
                                <input type="hidden" name="ChangeImg" value={this.state.ChangeImg} />
                                <input type="hidden" name="formMode" value={this.state.formMode} />
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel" onClick={(e) => this.deleteTempGiro()}>Tambah Giro</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body" id='modalBodyInvoice'>
                                        <div className='form-group'>
                                            <label>Nomor Giro</label>
                                            <input type="text" className='form-control' placeholder='Nomor Giro' value={this.state.tempGiroNumber} onChange={(e) => this.setState({ tempGiroNumber: e.target.value })} required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Tanggal Jatuh Tempo Giro</label>
                                            <input type="date" className='form-control' value={this.state.tempGiroTermDate} onChange={(e) => this.setState({ tempGiroTermDate: e.target.value })} required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Nominal</label>
                                            <input type="number" className='form-control' step="0.01" placeholder='Nomor Giro' value={this.state.tempGiroNominal} onChange={(e) => this.setState({ tempGiroNominal: e.target.value })} onFocus={(e) => e.target.select()} min="1" required />
                                        </div>
                                        <div className='form-group'>
                                            <label>Akun Bank</label>
                                            <select className='form-select' value={this.state.tempCoaCode} onChange={(e) => this.handlePilihBank(e.target.value)} required>
                                                <option value="">Silahkan pilih Bank</option>
                                                {
                                                    this.state.arrBank.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.Code}>{item.Code} - {item.Description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Bukti</label>
                                            <div className='image-upload'>
                                                <label id="labelImg" htmlFor="image-upload">
                                                    {divImg}
                                                </label>
                                                <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e)} />
                                                <div className="invalid-feedback">Silahkan pilih Gambar</div>
                                            </div>
                                        </div>
                                        <input type="hidden" name="Data" value={JSON.stringify(this.state.tempData)} />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary" id='btnSaveTempGiro'>Simpan</button>
                                        <button type="button" className="btn btn-secondary" id='btnTutupModalAddGiro' data-bs-dismiss="modal">Tutup</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="modal fade" id="modalPelanggan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Pelanggan</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.cariPelanggan(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Alamat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listPelanggan.map((item, index) => {
                                                        return (
                                                            <tr onClick={() => this.handlePilihPelanggan(index)} key={index}>
                                                                <td>{item.Name}</td>
                                                                <td>{item.Telp}</td>
                                                                <td>{item.Address}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalPelanggan' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Data Tagihan</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <input className='form-control' placeholder='Cari Data Tagihan' onChange={(e) => this.handleInvoiceList(e.target.value)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>No Invoice</th>
                                                    <th>Tanggal</th>
                                                    <th>Usia</th>
                                                    <th>PPN</th>
                                                    <th>Saldo Awal</th>
                                                    <th>Pending</th>
                                                    <th>Sisa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.listTagihan.map((item, index) => {
                                                        return (
                                                            <tr onClick={() => this.handlePilihTagihan(index)} key={index}>
                                                                <td>{item.DocNumber}</td>
                                                                <td>{tanggalIndo(item.DocDate)}</td>
                                                                <td>{item.UmurNota}</td>
                                                                <td>{item.TaxType}</td>
                                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                                <td>{numberFormat(item.Pending)}</td>
                                                                <td>{numberFormat(item.AmountBalance - item.Pending)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4}>Total {this.state.listTagihan.length}</td>
                                                    <td>{numberFormat(this.state.totalSaldoAwal)}</td>
                                                    <td>{numberFormat(this.state.totalTagihanPending)}</td>
                                                    <td>{numberFormat(this.state.totalTagihan)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id='btnTutupModalTagihan' data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDetailTagihan" tabIndex="-1" aria-labelledby="modalDetailTagihan" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Setoran</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btnTutupModalDetailPembayaran'></button>
                                </div>
                                <div className="modal-body" id='modalBodyInvoice'>
                                    <div className='table-responsive'>
                                        <h6>Detail Penagihan Tertunda</h6>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>Pembayaran</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.detailTagihan.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                                <td>{numberFormat(item.Amount)}</td>
                                                                <td>{item.State}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={2}><b>Total</b></td>
                                                    <td><b>{numberFormat(this.state.SaldoTertagih)}</b></td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <h6>Rincian</h6>
                                        <table className='table table-striped'>
                                            <tbody>
                                                <tr>
                                                    <td>Saldo Awal</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoOpen)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Penagihan yang Tertunda</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoTertagih)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sisa Tagihan</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.SaldoSisa)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => this.handleCancelPilihTrans()}>Batal</button>
                                    <button type="button" className="btn btn-primary" onClick={() => this.handlePilihTrans2()} disabled={this.state.AmountOpen > 0 ? false : true}>Pilih</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
    return <FormGiro ID={opt.ID} IDHeader={opt.IDHeader} menu={opt.menu} from={opt.from} />
}
