import React, { Fragment } from 'react';
import "../Login.css";
import { setCookie, pesan } from "../modul";

function App() {
    document.title = "Halaman Login";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = { username: '', pwd: "", Code: "" };
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleSubmit(e) {
            e.preventDefault();
            let btn = document.getElementById("btnLogin");
            let form = document.getElementById('iniForm');
            let data = new FormData(form);
            // let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "http://192.168.9.150:2005/api_mobile/";
            let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
            fetch(host + "user_login", {
                method: 'POST',
                body: data,
            }).then(response => response.json()).then(hasil => {
                console.log(hasil);
                if (hasil.status == "true") {
                    setCookie("UserID", hasil.data.ID, 30);
                    setCookie("UserName", hasil.data.Nama, 30);
                    setCookie("UserType", hasil.data.UserType, 30);
                    setCookie("IsSu", hasil.data.IsSu, 30);
                    setCookie(`NamaUsaha`, hasil.NamaUsaha, 30);
                    setCookie(`Token`, hasil.Token, 30);
                    window.location.href = "./";
                } else {
                    alert(hasil.message);
                    btn.innerHTML = "Login";
                    btn.disabled = false;
                }
            }).catch((error) => {
                console.log("Error: " + error);
                btn.innerHTML = "Login";
                btn.disabled = false;
            });
        }

        render() {
            return (
                <Fragment>
                    <div className='row'>
                        <div className='col-md-8 mb-1 container-login'>
                            <div className='bg-login'>
                                <div style={{ position: "absolute", left: "30px" }}></div>
                                <div></div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-1'>
                            <div className="card-login">
                                <h3 style={{ lineHeight: "30px", fontSize: "20px" }}>Login Admin</h3>
                                <h6 style={{ lineHeight: "18px", fontSize: "12px" }}>Selamat datang kembali</h6>
                                <form id="iniForm" onSubmit={(e) => this.handleSubmit(e)}>
                                    <div className='input-icons'>
                                        <span className='material-icons icon'>vpn_key_off</span>
                                        <input className="form-control login-form" type="text" name="Code" placeholder="Kode Perusahaan" value={this.state.Code} onChange={(e) => this.handleChange(e, "Code")} required />
                                    </div>
                                    <div className='input-icons'>
                                        <span className='material-icons icon'>person</span>
                                        <input className="form-control login-form" type="text" name="Username" placeholder="Username" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
                                    </div>
                                    <div className='input-icons'>
                                        <span className='material-icons icon'>lock</span>
                                        <input className="form-control login-form" type="password" name="Pwd" placeholder="Password" value={this.state.pwd} onChange={(e) => this.handleChange(e, "pwd")} required />
                                    </div>
                                    <p></p>
                                    <button type="submit" className=" btn w-100 btn-login" id="btnLogin">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    return (<Main />);
}

export default App;