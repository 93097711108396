import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { pilihMenu } from './App.js';
import XLSX from 'xlsx';

export let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";

export const rendMenu = async function () {
    let elm = [], UserType = getCookie("UserType");
    if (UserType == "sales") {
        elm = (
            <Fragment>
                <li id="Tunai" key="Tunai" onClick={() => pilihMenu("Tunai", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">payments</i> <span className="nama-menu">Tunai</span>
                    </a>
                </li>
                <li id="Transfer" key="Transfer" onClick={() => pilihMenu("Transfer", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">credit_score</i> <span className="nama-menu">Transfer</span>
                    </a>
                </li>
                <li id="Giro" key="Giro" onClick={() => pilihMenu("Giro", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">price_change</i> <span className="nama-menu">Giro</span>
                    </a>
                </li>
                <li id="ChangePwd" key="ChangePwd">
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2' data-bs-target="#modalGantiPassword" data-bs-toggle="modal">
                        <i className="material-icons icon-menu">lock_open</i> <span className="nama-menu">Ganti Password</span>
                    </a>
                </li>
                <li id="Logout" key="Logout" onClick={() => logout()}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">logout</i> <span className="nama-menu">Keluar</span>
                    </a>
                </li>
                <p></p>
            </Fragment>
        );
    } else if (UserType == "kasir") {
        elm = (
            <Fragment>
                <li id="Dashboard" key="Dashboard" onClick={() => pilihMenu("Dashboard", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">dashboard</i> <span className="nama-menu">Dashboard</span>
                    </a>
                </li>
                <li id="Barukasir" key="Barukasir" onClick={() => pilihMenu("Barukasir", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">note_add</i> <span className="nama-menu">Dokumen Baru</span>
                    </a>
                </li>
                <li id="Selesaikasir" key="Selesaikasir" onClick={() => pilihMenu("Selesaikasir", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">check_circle_outline</i> <span className="nama-menu">Dokumen Selesai</span>
                    </a>
                </li>
                <li id="ChangePwd" key="ChangePwd">
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2' data-bs-target="#modalGantiPassword" data-bs-toggle="modal">
                        <i className="material-icons icon-menu">lock_open</i> <span className="nama-menu">Ganti Password</span>
                    </a>
                </li>
                <li id="Logout" key="Logout" onClick={() => logout()}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">logout</i> <span className="nama-menu">Keluar</span>
                    </a>
                </li>
                <p></p>
            </Fragment>
        )
    } else if (UserType == "kacab") {
        elm = (
            <Fragment>
                <li id="Dashboard" key="Dashboard" onClick={() => pilihMenu("Dashboard", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">dashboard</i> <span className="nama-menu">Dashboard</span>
                    </a>
                </li>
                <li id="Barukacab" key="Barukacab" onClick={() => pilihMenu("Barukacab", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">note_add</i> <span className="nama-menu">Dokumen Baru</span>
                    </a>
                </li>
                <li id="Selesaikacab" key="Selesaikacab" onClick={() => pilihMenu("Selesaikacab", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">check_circle_outline</i> <span className="nama-menu">Dokumen Selesai</span>
                    </a>
                </li>
                <li id="Barutunaikacab" key="Barutunaikacab" onClick={() => pilihMenu("Barutunaikacab", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">payments</i> <span className="nama-menu">Dokumen Baru Tunai</span>
                    </a>
                </li>
                <li id="Selesaitunaikacab" key="Selesaitunaikacab" onClick={() => pilihMenu("Selesaitunaikacab", true)}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">check_circle_outline</i> <span className="nama-menu">Dokumen Selesai Tunai</span>
                    </a>
                </li>
                <li id="ChangePwd" key="ChangePwd">
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2' data-bs-target="#modalGantiPassword" data-bs-toggle="modal">
                        <i className="material-icons icon-menu">lock_open</i> <span className="nama-menu">Ganti Password</span>
                    </a>
                </li>
                <li id="Logout" key="Logout" onClick={() => logout()}>
                    <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                        <i className="material-icons icon-menu">logout</i> <span className="nama-menu">Keluar</span>
                    </a>
                </li>
                <p></p>
            </Fragment>
        )
    } else if (UserType == "pusat") {
        if (getCookie("IsSu") == 1) {
            elm = (
                <Fragment>
                    <li id="Dashboard" key="Dashboard" onClick={() => pilihMenu("Dashboard", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">dashboard</i> <span className="nama-menu">Dashboard</span>
                        </a>
                    </li>
                    <li id="Barupusat" key="Barupusat" onClick={() => pilihMenu("Barupusat", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">note_add</i> <span className="nama-menu">Dokumen Baru</span>
                        </a>
                    </li>
                    <li id="Selesaipusat" key="Selesaipusat" onClick={() => pilihMenu("Selesaipusat", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">check_circle_outline</i> <span className="nama-menu">Dokumen Selesai</span>
                        </a>
                    </li>
                    <li id="Done" key="Done" onClick={() => pilihMenu("Done", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">checklist</i> <span className="nama-menu">Sudah Ditarik</span>
                        </a>
                    </li>
                    <li id="History" key="History" onClick={() => pilihMenu("History", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">update</i> <span className="nama-menu">Riwayat</span>
                        </a>
                    </li>
                    <li id="Users" key="Users" onClick={() => pilihMenu("Users", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">person</i> <span className="nama-menu">Users</span>
                        </a>
                    </li>
                    <li id="ChangePwd" key="ChangePwd">
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2' data-bs-target="#modalGantiPassword" data-bs-toggle="modal">
                            <i className="material-icons icon-menu">lock_open</i> <span className="nama-menu">Ganti Password</span>
                        </a>
                    </li>
                    <li id="Logout" key="Logout" onClick={() => logout()}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">logout</i> <span className="nama-menu">Keluar</span>
                        </a>
                    </li>
                    <p></p>
                </Fragment>
            )
        } else {
            elm = (
                <Fragment>
                    <li id="Barupusat" key="Barupusat" onClick={() => pilihMenu("Barupusat", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">note_add</i> <span className="nama-menu">Dokumen Baru</span>
                        </a>
                    </li>
                    <li id="Selesaipusat" key="Selesaipusat" onClick={() => pilihMenu("Selesaipusat", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">check_circle_outline</i> <span className="nama-menu">Dokumen Selesai</span>
                        </a>
                    </li>
                    <li id="Done" key="Done" onClick={() => pilihMenu("Done", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">checklist</i> <span className="nama-menu">Sudah Ditarik</span>
                        </a>
                    </li>
                    <li id="History" key="History" onClick={() => pilihMenu("History", true)}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">update</i> <span className="nama-menu">Riwayat</span>
                        </a>
                    </li>
                    <li id="ChangePwd" key="ChangePwd">
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2' data-bs-target="#modalGantiPassword" data-bs-toggle="modal">
                            <i className="material-icons icon-menu">lock_open</i> <span className="nama-menu">Ganti Password</span>
                        </a>
                    </li>
                    <li id="Logout" key="Logout" onClick={() => logout()}>
                        <a href="#" className='d-flex justify-content-start align-items-center gap-2'>
                            <i className="material-icons icon-menu">logout</i> <span className="nama-menu">Keluar</span>
                        </a>
                    </li>
                    <p></p>
                </Fragment>
            )
        }
    }
    ReactDOM.render(elm, document.getElementById('divMenu'));
    router();
}

export const router = async function router(ID = 0) {
    let path = ["Dashboard", "Tunai", "Transfer", "Giro", "Barukasir", "Selesaikasir", "Barukacab", "Selesaikacab", "Barutunaikacab", "Selesaitunaikacab", "Barupusat", "Selesaipusat", "Done", "History", "Users"];
    let dataMenu = window.location.pathname.replace("/", "");
    if (path.includes(dataMenu)) {
        document.getElementById(dataMenu).click();
    } else {
        if (document.getElementById('Dashboard')) document.getElementById("Dashboard").click();
    }
    setTimeout(() => {
        let element = document.getElementsByTagName("dx-license");
        for (let index = element.length - 1; index >= 0; index--) {
            element[index].innerHTML = "";
            // element[index].parentNode.removeChild(element[index]);
        }
    }, 2000);
}

export const rendTable = async function (opt) {
    let dataType = opt.dataset ? "dataset" : "url";
    let data = dataType === "url" ? await api(opt.url, opt.param) : opt.dataset;
    let sql = data;
    let host = window.location.hostname === "localhost" ? "http://localhost:2005/" : "https://penagihanapi.pxa.co.id/api_mobile/";
    let field = opt.thead.field ? opt.thead.field : sql.field.thead;
    let TH = [];
    for (let th of field) {
        let width = th.width ? th.width : "auto";
        if (th.sort) {
            let align = th.align ? th.align : "left";
            TH.push(<th style={{ cursor: "pointer", textAlign: align, width: width }} onClick={opt.sort} data-sort={th.sort} key={th.sort}>{th.cap}</th>);
        } else {
            TH.push(<th width={width} key={th.sort}>{th.cap}</th>);
        }
    }
    let TD = [];
    if (sql.data.length > 0) {
        let ctTd = 0;
        for (let i in sql.data) {
            let data = sql.data[i];
            let dom = [];
            for (let b in sql.field.thead) {
                let td = sql.field.thead[b];
                if (td.type === "opsi") {
                    let OPSI = [];
                    for (let opsi of opt.tbody.opsi) {
                        if (opsi.type === "edit") {
                            OPSI.push(<i className="material-icons icon-color opsi-edit" data-id={data.ID} onClick={opsi.event}>edit</i>);
                        } else if (opsi.type === "other") {
                            OPSI.push(<i className="material-icons icon-color" data-id={data.ID} onClick={opsi.event}>{opsi.icon}</i>);
                        } else if (opsi.type === "view") {
                            OPSI.push(<i className="material-icons icon-color" data-id={data.ID} onClick={opsi.event}>visibility</i>);
                        } else if (opsi.type === "delete") {
                            OPSI.push(<i className="material-icons icon-color" data-id={data.ID} onClick={opsi.event}>delete</i>);
                        }
                    }
                    dom.push(<td width={td.width}><div className="opsi-desktop">{OPSI}</div></td>);
                } else if (td.type === "month") {
                    dom.push(<td width={td.width}>{TampilBulan(data[td.sort])}</td>);
                } else if (td.type === "time") {
                    dom.push(<td width={td.width}>{data[td.sort].substring(11, 19)}</td>);
                } else if (td.type === "img") {
                    let urlImg = host + "file/" + data[td.sort];
                    dom.push(<td width={td.width} align='center'><img src={urlImg} alt="img-table" width="42px" height="42px" style={{ borderRadius: "50%" }} /></td>);
                } else if (td.type === "thumbnail") {
                    let indx = data[td.sort].indexOf("?v=");
                    let src = data[td.sort].substring(indx + 3)
                    dom.push(<td width={td.width} align='center'><img src={"https://img.youtube.com/vi/" + src + "/default.jpg"} alt="img-table" width="50px" height="50px" /></td>);
                } else if (td.type === "str") {
                    dom.push(<td width={td.width}>{data[td.sort]}</td>);
                } else if (td.type === "html") {
                    dom.push(<td width={td.width} dangerouslySetInnerHTML={{ __html: data[td.sort] }} />);
                } else if (td.type === "link") {
                    dom.push(<td width={td.width}><a href="#" className="btn-link" data-id={data.ID} onClick={td.link}>{data[td.sort]}</a></td>);
                } else {
                    if (data[td.sort] != null) {
                        if (isNaN(data[td.sort])) {
                            if (Number.isNaN(Date.parse(data[td.sort]))) {
                                dom.push(<td width={td.width}>{data[td.sort]}</td>);
                            } else {
                                dom.push(<td width={td.width}>{tanggalIndo(data[td.sort])}</td>);
                            }
                        } else {
                            dom.push(<td width={td.width} align="right">{numberFormat(data[td.sort])}</td>);
                        }
                    } else {
                        dom.push(<td width={td.width}></td>);
                    }
                }
            }
            TD.push(<tr key={ctTd}>{dom}</tr>);
            ctTd++;
        }
    } else {
        TD.push(<tr key={1}><td colSpan={sql.field.thead.length}>Tidak ada data</td></tr>);
    }
    let tblCls = opt.table.cls ? opt.table.cls : "table ";
    let thCls = opt.thead.cls ? opt.thead.cls : "color-bg-biru";
    let tbID = opt.tbody.id ? opt.tbody.id : "tbodyData";
    let THEAD = <thead className={thCls}><tr>{TH}</tr></thead>;
    let TBODY = <tbody id={tbID}>{TD}</tbody>;
    let TABLE = (<table className={tblCls}>{THEAD}{TBODY}</table>);
    ReactDOM.render(TABLE, document.getElementById(opt.div));
    if (opt.paggination) {
        let pg = opt.paggination.div ? opt.paggination.div : "paggination";
        rendPagginations({ jml: sql.field.j, fn: opt.paggination.event, index: opt.paggination.index, div: pg });
    }
}

export const rendPagginations = function (opt) {
    let page = opt.index,
        jml = opt.jml,
        pg = opt.div ? opt.div : "paggination",
        awal = 0,
        akhir = 0;
    if (jml > 0) {
        let pref = page == 1
            ? (<li className="page-item"><span className="page-link"><i className="material-icons" style={{ cursor: "notAllowed", fontSize: "12px", display: "inline-flex", verticalAlign: "middle" }}>keyboard_arrow_left</i></span></li>)
            : <li className="page-item btn-pg" style={{ cursor: "pointer" }} data-val={parseInt(page) - 1} onClick={opt.fn} > <span className="page-link"><i className="material-icons" style={{ fontSize: "12px", display: "inlineFlex", verticalAlign: "middle", cursor: "pointer" }}>keyboard_arrow_left</i></span></li >;

        if (page > 7) { awal = page; } else { awal = 1; }
        if (jml > 8) { if (page > 7) { akhir = parseInt(page) + 7; } else { akhir = 8; } } else { akhir = jml; }
        if (page >= jml) akhir = page;
        // if (page >= jml - 7) awal = jml - 7;
        let arrBtn = [];
        for (let i = awal; i <= akhir; i++) {
            if (i == page) arrBtn.push({ ind: i, cls: "active" });
            else arrBtn.push({ ind: i, cls: "" });
        }
        let btn = arrBtn.map((elm) =>
            <li key={elm.ind} className={"page-item " + elm.cls}>
                <a className="page-link btn-pg" href="#" data-val={elm.ind} onClick={opt.fn}>{elm.ind}</a>
            </li>
        );

        let next = page >= jml
            ? (<li className="page-item"><span className="page-link"><i className="material-icons" style={{ cursor: "notAllowed", fontSize: "12px", display: "inline-flex", verticalAlign: "middle" }}>keyboard_arrow_right</i></span></li>)
            : (<li className="page-item btn-pg" data-val={parseInt(page) + 1} onClick={opt.fn}><a className="page-link" href="#"><i className="material-icons" style={{ fontSize: "12px", display: "inline - flex", verticalAlign: "middle" }}>keyboard_arrow_right</i></a></li>);

        let div = (
            <ul className="pagination justify-content-end">
                {pref}{btn}{next}
            </ul>
        );
        ReactDOM.render(div, document.getElementById(pg));
    } else {
        ReactDOM.render("", document.getElementById(pg));
    }
}

export const TampilBulan = function (date) {
    let bulan = date.substring(5);
    let tahun = date.substring(0, 4);
    let BulanIndo = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"];
    let hasil = `${BulanIndo[bulan - 1]} ${tahun}`;
    return hasil;
}

export const saiki = function (first = null) {
    var today = new Date();
    var dd = first == null ? today.getDate() : first;
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

export const loading = function (type = "hide") {
    if (type == "show") {
        document.getElementById('loading').style.display = "block";
    } else {
        var s = document.getElementById('loading').style;
        s.opacity = 1;
        (function fade() { (s.opacity -= .1) < 0 ? s.display = "none" : setTimeout(fade, 100) })();
    }
}

export const pesan = function (title, details, jenis, waktu = 5000) {
    let node = document.createElement("div");
    node.className = `alert alert-${jenis} alert-dismissible animated alert-dismissible fade show flipInX`;
    node.role = "alert";
    node.innerHTML = `<strong>${title}</strong>
                <p>${details}</p>
                <span class="close" style="cursor:pointer" data-bs-dismiss="alert">
                    <i class='material-icons'>close</i>
                </span>`;
    document.getElementById("divPesan").appendChild(node);

    setTimeout(function () {
        node.className += " flipOutX";
        setTimeout(function () {
            node.remove();
        }, 1000);
    }, waktu);
}

export const isJson = function (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = function (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const checkCookie = function () {
    var user = getCookie("username");
    if (user != "") {
        alert("Welcome again " + user);
    } else {
        user = prompt("Please enter your name:", "");
        if (user != "" && user != null) {
            setCookie("username", user, 365);
        }
    }
}

export const logout = function logout() {
    sessionStorage.clear();
    setCookie("UserID", "", -30);
    setCookie("UserName", "", -30);
    setCookie("UserType", "", -30);
    setCookie("IsSu", "", -30);
    setCookie("Token", "", -30);
    window.location.href = './';
}

export const YYMMDD = function (date) {
    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const tanggalIndo = function (data) {
    var d = new Date(data),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let hasil = [year, month, day].join('-');

    if (hasil == "0000-00-00" || hasil == null) {
        return (hasil);
    } else {
        let BulanIndo = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agust", "Sept", "Okt", "Nov", "Des"];

        let tahun = hasil.substring(2, 4);
        let bulan = hasil.substring(5, 7);
        let tgl = hasil.substring(8, 10);

        let result = `${tgl} ${BulanIndo[bulan - 1]} ${tahun}`;
        return result;
    }
}

export const randomRgb = () => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
}

export const getDayOfMonth = function (month, year) {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    // let bln = month < 10 ? month.substring(1) : month;
    while (date.getUTCMonth() === month) {
        days.push(YYMMDD(new Date(date)));
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}

export const getLastDay = (y, m) => {
    var d = new Date(y, m, 0);
    return `${y}-${m}-${d.getDate()}`;
}

export const getAllDay = function (date) {
    var month = date.substring(5, 7);
    var year = date.substring(0, 4);
    var d = new Date(year, month, 0).getDate();
    let day = [];
    for (let i = 1; i <= d; i++) {
        day.push(`${year}-${month}-${i}`);
    }
    return day;
}

export const numberFormat = function (ini) {
    var formatter = new Intl.NumberFormat("en-GB", { style: "decimal" });
    var nmr = 0;
    if (isNaN(ini)) {
        nmr = 0;
    } else {
        nmr = ini;
    }
    return formatter.format(nmr.toString().replace(/,/g, ""));
}

export const lazyLoad = function (lokasi = "tampil", col = 4, row = 3) {
    let div = `<table className="table table-borderless"><tbody>`;
    for (let i = 0; i < row; i++) {
        if (i % 2 == 0) {
            div += `<tr><td><div className="row">`;
            for (let r = 0; r < col; r++) {
                if (r % 2 == 0) {
                    div += `<div className="col"> <div className="mid lazyColor"></div> </div>`;
                } else {
                    div += `<div className="col"> <div className="mid-big-subtitle lazyColor"></div></div>`;
                }
            }
            div += `</td></div></tr>`;
        } else {
            div += `<tr><td><div className="row">`;
            for (let r = 0; r < col; r++) {
                if (r % 2 == 0) {
                    div += `<div className="col"> <div className="mid-big-subtitle lazyColor"></div> </div>`;
                } else {
                    div += `<div className="col"> <div className="mid lazyColor"></div></div>`;
                }
            }
            div += `</div></td></tr>`;
        }
    }
    div += `</tbody> </table>`;
    document.getElementById(lokasi).innerHTML = div;
}

export const load = function (url, id = "tampil") {
    fetch(encodeURI(url))
        .then(response => response.text())
        .then(hasil => {
            document.getElementById(id).innerHTML = hasil;
            if (hasil.includes("<script>")) {
                for (let i = 0; i < (hasil.match(/<script>/g) || []).length; i++) {
                    let start = hasil.indexOf("<script>", i) + 8;
                    let end = hasil.indexOf("</script>", i);
                    let script = hasil.substring(start, end);
                    let node = document.createElement("script");
                    let ss = document.createTextNode(script);
                    node.appendChild(ss);
                    document.getElementById(id).appendChild(node);
                }
            }
        }).catch((error) => {
            console.log("Error: " + error);
        });
}

export const execFunction = function (functionName, context = window) {
    let args = Array.prototype.slice.call(arguments, 2);
    let namespaces = functionName.split(".");
    let func = namespaces.pop();
    for (let i = 0; i < namespaces.length; i++) {
        context = context[namespaces[i]];
    }
    return context[func].apply(context, args);
}

export const rendModal = function (opt) {
    let body = opt.body, cls = opt.cls || " ", id = opt.id || "modal", title = opt.title || "",
        formIn = opt.form ? `<form id="iniForm" className="needs-validation" novalidate>` : "", formOut = opt.form ? "</form>" : "",
        btnFoot = opt.footer || "", foot = btnFoot == "btnSave" ? `<button type="submit" className="btn btn-default"><i className="material-icons">save</i> Simpan</button>` : btnFoot;

    let node = document.createElement("div");
    let modal = `<div data-bs-target="#${id}" data-bs-toggle="modal" id="triger${id}"></div>
                    <div className="modal fade" id="${id}" tabindex="-10000" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog ${cls}"><div className="modal-content" id="modalKonten${id}">
                            ${formIn}
                            <div className="modal-header">
                                <h5 className="modal-title">${title}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" id="modalBody${id}"></div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="btnCloseModal${id}" data-bs-dismiss="modal">Tutup</button>
                                ${foot}
                            </div>
                            ${formOut}
                        </div>
                    </div>
                </div>`;
    node.innerHTML = modal;
    document.getElementById("divModal").appendChild(node);
    document.getElementById(`triger${id}`).click();
    ReactDOM.render(body, document.getElementById(`modalBody${id}`));
    let script = document.createElement("script");
    let ss = document.createTextNode(`
        document.getElementById('${id}').addEventListener('hidden.bs.modal', function (e) {
            document.getElementById("${id}").remove();
            document.getElementById("triger${id}").remove();
        })
    `);
    script.appendChild(ss);
    document.getElementById("modalKonten" + id).appendChild(script);
}

export const rendModals = function (opt) {
    let cls = opt.cls || " ";
    let id = opt.id || "modal";
    let title = opt.title || "";
    let body = opt.body;
    let foot = opt.footer || "";
    let btn = foot == "btnSave" ? (<button className="btn btn-default"><i className="material-icons">save</i> Simpan</button>) : foot;
    let content = (
        <>
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" id={"modalBody" + id}>{body}</div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" id={"btnCloseModal" + id} data-bs-dismiss="modal">Tutup</button>
                {btn}
            </div>
        </>
    );
    let form = opt.form ? (<form id="iniForm" className="needs-validation" novalidate>{content}</form>) : <>{content}</>;
    let modal = (
        <>
            <div data-bs-target={"#" + id} data-bs-toggle="modal" id={"triger" + id}></div>
            <div className="modal fade" id={id} tabindex="-10000" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className={"modal-dialog" + cls}>
                    <div className="modal-content" id={"modalKonten" + id}>
                        {form}
                    </div>
                </div>
            </div>
        </>
    );
    ReactDOM.render(modal, document.getElementById("divModal"));
    document.getElementById(`triger${id}`).click();
}

export const submitForm = function (e, opt) {
    e.preventDefault();
    e.stopPropagation();
    let f = opt.form ? opt.form : "#iniForm";
    let form = document.querySelector(f);
    let btn = form.querySelectorAll("button[type='submit']")[0];
    let oldBtn = btn.innerHTML.trim();
    let clsModal = opt.modal ? opt.modal : "no";
    if (form.checkValidity()) {
        btn.innerHTML = `<span className="spinner-border spinner-border-sm"></span> ${oldBtn}`;
        btn.disabled = true;
        let data = new FormData(form);
        fetch(host + opt.act, {
            method: 'POST',
            body: data,
        }).then(response => response.json()).then(hasil => {
            if (hasil.status === "true") {
                pesan("Proses Success", hasil.message, "primary");
                if (opt.reload === true) router();
                if (opt.click) document.getElementById(opt.click).click();
                if (clsModal !== "no") document.getElementById(clsModal).click();
                // if (opt.fn) () => opt.fn;
            } else {
                alert(hasil.message);
                btn.innerHTML = oldBtn;
                btn.disabled = false;
            }
        }).catch((error) => {
            console.log("Error: " + error);
            btn.innerHTML = oldBtn;
            btn.disabled = false;
        });
    } else {
        let forms = document.getElementsByClassName('needs-validation');
        let ululu = Array.prototype.filter.call(forms, function (form) {
            form.classList.add('was-validated');
        });
    }
}

export const api = function (url, dd) {
    try {
        return new Promise((resolve, reject) => {
            fetch(encodeURI(host + url), {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dd),
            }).then(response => response.json()).then(hasil => {
                resolve(hasil);
            }).catch((error) => {
                reject(error)
            });
        });
    } catch (e) {
        pesan("Terjadi Kesalahan", "Mohon maaf terjadi kesalahan saat load data" + e, "danger");
        console.log(e);
    }
}

export const rendLokasi = async function (all = "yes") {
    let ID = getCookie("ID");
    let sql = await api("location_admin", { iddata: ID });
    let dom = ``;
    if (all == "yes" && sql.ho == 1) {
        dom = `<option value="0">Semua Lokasi</option>`;
        sessionStorage.setItem("location", "0");
        let ct = 0;
        for (let ul of sql.data) if (ul.isLock == 1) dom += `<option value="${ul.ID}">${ul.NameLocation}</option>`; ct++;
    } else {
        let ct = 0;
        for (let ul of sql.data) {
            if (ul.isLock == 1) {
                if (ct == 0) {
                    sessionStorage.setItem("location", ul.ID);
                    dom += `<option value="${ul.ID}">${ul.NameLocation}</option>`;
                } else {
                    dom += `<option value="${ul.ID}">${ul.NameLocation}</option>`;
                }
                ct++;
            }
        }
    }
    document.getElementById('ddLocation').innerHTML = dom;
}

export function changeLocation(id) {
    sessionStorage.setItem("location", id.target.value);
    sessionStorage.setItem("index", "1");
}

export function rowspan(cls) {
    let pay = document.querySelectorAll(cls);
    pay.forEach((elm, td) => {
        let id = elm.dataset.id;
        let pp = document.querySelectorAll(cls + "-" + id);
        if (pp.length > 1) {
            pp[0].rowSpan = pp.length;
            for (let TD in pp) {
                if (TD > 0) pp[TD].remove();
            }
        }
    });
}

export async function cekStatus() {
    let sql = await api("status_admin", { id: getCookie("ID") });
    if (sql.LimitPeriod > 0) {
        let today = new Date();
        let limit = sql.LimitPeriod;
        today.setDate(today.getDate() - limit);
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yyyy = today.getFullYear();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        let appointment = yyyy + '-' + mm + '-' + dd;
        if (document.getElementById('edtD1')) document.getElementById('edtD1').min = appointment;
        if (document.getElementById('edtD2')) document.getElementById('edtD2').min = appointment;
        if (document.getElementById('edtMonth')) document.getElementById('edtMonth').disabled = true;
        if (document.getElementById('pills-bulan-tab')) document.getElementById('pills-bulan-tab').disabled = true;
        if (document.getElementById('chkAll')) document.getElementById('chkAll').disabled = true;
    }
}

export async function exportData(sql, title, thead = false) {
    let arr = [];
    let header = [];
    for (let th of thead) header.push(th.text);

    arr.push(header);
    for (let i in sql) {
        let data = sql[i];
        var innerRowData = [];
        for (let b in thead) {
            let td = thead[b];
            innerRowData.push(data[td.field]);
        }
        arr.push(innerRowData);
    }
    var filename = title + ".xlsx";
    var ws_name = "Data";
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, filename);
}

export function compareJSON(obj1, obj2) {
    function compare(obj1, obj2, path) {
        let differences = {};

        for (let key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                const newPath = path ? `${path}.${key}` : key;

                if (!obj2.hasOwnProperty(key)) {
                    differences[newPath] = { oldValue: obj1[key], newValue: undefined };
                } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object' && obj1[key] !== null && obj2[key] !== null) {
                    const nestedDifferences = compare(obj1[key], obj2[key], newPath);
                    if (Object.keys(nestedDifferences).length > 0) {
                        differences = { ...differences, ...nestedDifferences };
                    }
                } else if (obj1[key] !== obj2[key]) {
                    differences[newPath] = { oldValue: obj1[key], newValue: obj2[key] };
                }
            }
        }

        for (let key in obj2) {
            if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
                const newPath = path ? `${path}.${key}` : key;
                differences[newPath] = { oldValue: undefined, newValue: obj2[key] };
            }
        }

        return differences;
    }

    return compare(obj1, obj2, '');
}