import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, tanggalIndo, numberFormat, getCookie, pesan } from "../modul";
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

function App() {
    document.title = "Data Kacab Selesai";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                D1: window.localStorage.getItem("D1") || saiki("1"),
                D2: window.localStorage.getItem("D2") || saiki(),
                searchcount: 50,
                index: 0,
                sort: "DocDate",
                by: "DESC",
                SalesID: "",
                Detail: {},
                DetailPayment: [],
                ChangeImg: "no",
                dataPayment: [],
                CoaCode: "",
                Amount: "",
                DocID: "",
                tanggalSetor: saiki(),
                totalPayment: 0,
                dataTempInvoice: [],
                dataPenagihanTunai: [],
                tempItem: {},
                tempCoaCode: "",
                tempCoaName: "",
                tempCoaID: "",
                tempAmount: 0,
                TotalTagihan: 0,
                totalTerpilih: 0,
                IsFilter: false,
                dataARAP: [],
                host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
                divImg: "",
                Field: [
                    { cap: "No Pusat", sort: "PusatID" },
                    { cap: "No Invoice", sort: "NoInvoice" },
                    { cap: "Sales", sort: "SalesName" },
                    { cap: "Pelanggan", sort: "CustomerName" },
                    { cap: "Jenis Pembayaran", sort: "PaymentType" },
                    { cap: "Saldo Awal", sort: "AmountOpen", type: "number" },
                    { cap: "Pembayaran", sort: "Amount", type: "number" },
                    { cap: "Saldo Akhir", sort: "AmountBalance", type: "number" },
                ]
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            let sql = await api("akun_bank", { Token: getCookie("Token") });
            if (sql.status == "true") this.setState({ dataPayment: sql.data });
            this.main();
        }

        handleChangeDate(dd, val) {
            window.localStorage.setItem(dd, val);
            this.setState({ IsFilter: true });
            if (dd == "D1") {
                this.setState({ "D1": val });
            } else {
                this.setState({ "D2": val });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        async main() {
            let param = {
                search: this.state.search,
                D1: this.state.D1,
                D2: this.state.D2,
                IsFilter: this.state.IsFilter,
                index: this.state.index,
                searchcount: this.state.searchcount,
                KacabID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token")
            }
            let sql = await api("list_tagihan_selesai_tunai_kacab", param);
            if (sql.status == "true") {
                this.setState({ data: sql.data });
            }
        }

        async handleDetail(Detail) {
            let sql = await api("detail_giro", { ID: Detail.ID, Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({ Detail: Detail, DetailPayment: sql.data, divImg: <img src={this.state.host + Detail.Berkas} style={{ width: "100%" }} /> });
            }
        }

        render() {
            let tinggiLayar = window.screen.height;
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Kacab Selesai Tunai</h2>
                        <div className='row'>
                            <div className='col-md-3'></div>
                            <div className='col-md-9'>
                                <div className='input-group gap-1'>
                                    <input type="search" className='form-control' placeholder='Cari No Invoice' value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} />
                                    <input type="date" className='form-control' value={this.state.D1} onChange={(e) => this.handleChangeDate('D1', e.target.value)} />
                                    <input type="date" className='form-control' value={this.state.D2} onChange={(e) => this.handleChangeDate('D2', e.target.value)} />
                                    <button className='btn btn-primary' onClick={(e) => this.main()}><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <DataGrid dataSource={this.state.data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            <Column caption=""
                                cellRender={(rowData) => (
                                    <div>
                                        <button className='btn' data-bs-toggle="modal" data-bs-target="#modalDetail" type="button" onClick={() => this.handleDetail(rowData.data)}>
                                            <span className='material-icons'>visibility</span>
                                        </button>
                                    </div>
                                )}
                            />
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <TotalItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                                <GroupItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Awal : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Pembayaran : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Akhir : {0}" showInGroupFooter={false} alignByColumn={true} />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                    </div>

                    <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="modalDetail" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <table className='table'>
                                                <tbody>
                                                    <tr>
                                                        <td width="120px">Nama Pelanggan</td>
                                                        <td width="15px">:</td>
                                                        <td>{this.state.Detail.CustomerName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>No Invoice</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.NoInvoice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Awal</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountOpen)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.Amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Akhir</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountBalance)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tanggal Pelunasan</td>
                                                        <td>:</td>
                                                        <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.PaymentType}</td>
                                                    </tr>
                                                    {this.state.TRReject}
                                                </tbody>
                                            </table>
                                            <p></p>
                                            <div className='table-responsive'>{this.state.tableGiro}</div>
                                            <p></p>
                                            <div className='form-group'>
                                                <label>Catatan</label>
                                                <textarea name='Notes' className="form-control" placeholder='Isi Keterangan' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            {this.state.divImg}
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment >
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}
export default App;