import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, tanggalIndo, numberFormat, getCookie, pesan } from "../modul";
import { FormsTransfer, FormsTunai, FormsGiro } from './Forms';
import img_upload from '../bg_uppload-image.png';
import Pagination from "../components/Pagination";
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

function App() {
    document.title = "Data Kacab Baru";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                searchcount: 50,
                index: 1,
                jml: 0,
                sort: "DocDate",
                by: "DESC",
                SalesID: "",
                Detail: {},
                ChangeImg: "no",
                dataPayment: [],
                CoaCode: "",
                Amount: "",
                DocID: "",
                tanggalSetor: saiki(),
                totalPayment: 0,
                dataTempInvoice: [],
                dataPenagihanTunai: [],
                tempItem: {},
                tempCoaCode: "",
                tempCoaName: "",
                tempCoaID: "",
                tempAmount: 0,
                Field: [
                    { type: "date", sort: "DocDate", cap: "Tanggal" },
                    { type: "str", sort: "ID", cap: "Doc. Number" },
                    { type: "str", sort: "PaymentType", cap: "Jenis Pembayaran" },
                    { type: "number", sort: "TotalInvoice", cap: "Total Invoice" },
                    { type: "html", sort: "Customer", cap: "Pelanggan" },
                ]
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            let sql = await api("akun_bank", { Token: getCookie("Token") });
            if (sql.status == "true") this.setState({ dataPayment: sql.data });
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            let page = e.target.dataset.page;
            this.setState({ index: page });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        handleDetail(detail) {
            console.log(detail);
            rendDetailKacab(detail.DocNumber)
        }

        async handleModalKirim(Detail) {
            let sql = await api("detail_payment", { DocNumber: Detail.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                let tt = 0;
                for (let pay of sql.payment) tt += parseFloat(pay.Amount);
                this.setState({ Detail: Detail, ChangeImg: "no", dataPayment: sql.data, totalPayment: tt });
            }
        }

        async handleKirim(e) {
            e.preventDefault();
            e.stopPropagation();
            let form = e.target;
            if (form.checkValidity()) {
                document.getElementById('btnKirim').disabled = true;
                document.getElementById('btnKirim').innerHTML = "Mengirim . . .";
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
                let data = new FormData(form);
                fetch(host + "kirim_tagihan_kacab", {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        this.main();
                        pesan("Proses Success", hasil.message, "primary");
                        document.getElementById('btnKirim').disabled = false;
                        document.getElementById('btnKirim').innerHTML = "kirim";
                        document.getElementById('btnTutupKirim').click();
                    } else {
                        document.getElementById('btnKirim').disabled = false;
                        document.getElementById('btnKirim').innerHTML = "kirim";
                        pesan("Peringatan", hasil.message, "danger");
                    }
                }).catch((error) => {
                    document.getElementById('btnKirim').disabled = false;
                    document.getElementById('btnKirim').innerHTML = "kirim";
                    console.log("Error: " + error);
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                jml: 100,
                KacabID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token")
            }
            document.getElementById("labelImg").innerHTML = `<img src="${img_upload}" width="150px">`;
            let sql = await api("list_tagihan_kacab", param);
            if (sql.status == "true") {
                this.setState({ data: sql.data, jml: sql.ct });
            }
        }

        openFile(e, id) {
            document.getElementById(id).innerHTML = '';
            let files = e.target.files;
            let file;
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    let reader = new FileReader();
                    file = files[i];
                    reader.onload = (file) => {
                        document.getElementById(id).innerHTML += `<img src="${reader.result}" width="150px">`;
                        this.setState({ ChangeImg: "yes" });
                    }
                    reader.readAsDataURL(file)
                }
            } else {
                document.getElementById(id).innerHTML += `<img src="${img_upload}" width="150px">`;
            }
        }

        async handleAddPayment(td, ID) {
            let bank = await api("akun_bank", { Token: getCookie("Token") });
            if (bank.status == "true") {
                this.setState({ DocID: ID, CoaCode: "", Amount: 0 });
                let dom = <Fragment>
                    <div className='input-group'>
                        <select className='form-select form-select-sm' onChange={(e) => this.setState({ CoaCode: e.target.value })}>
                            <option value=''>Pilih Akun Bank</option>
                            {
                                bank.data.map((val, i) => {
                                    return (
                                        <option value={val.Code}>{val.Description}</option>
                                    )
                                })
                            }
                        </select>
                        <input type="number" className='form-control form-control-sm' step="0.01" onChange={(e) => this.setState({ Amount: e.target.value })} />
                        <button className='btn btn-sm btn-danger' type="button">
                            <span className='material-icons'>delete</span>
                        </button>
                        <button className='btn btn-sm btn-primary' type="button" onClick={() => this.handleSavePayment()}>
                            <span className='material-icons'>save</span>
                        </button>
                    </div>
                </Fragment>;
                ReactDOM.render(dom, document.getElementById(td));
            }
        }

        async handleSavePayment(ID) {
            if (this.state.CoaCode != "") {
                if (this.state.Amount > 0) {
                    let param = {
                        DocID: this.state.DocID,
                        CoaCode: this.state.CoaCode,
                        Amount: this.state.Amount,
                        DocNumber: this.state.Detail.DocNumber,
                        Token: getCookie("Token")
                    }

                    let sql = await api("save_payment", param);

                    if (sql.status == "true") {
                        pesan("Berhasil", sql.message, "primary");
                        this.handleModalKirim(this.state.Detail);
                    } else {
                        pesan("Gagal", sql.message, "danger");
                    }
                } else {
                    pesan("Gagal", "Jumlah Payment Tidak Boleh 0", "danger");
                }
            } else {
                pesan("Peringatan", "Silahkan Pilih Akun Bank", "danger");
            }
        }

        async handleCariInvoice(val) {
            let sql = await api("data_inovoice_tunai", { NoInvoice: val, Token: getCookie("Token") });
            if (sql.status == "true") this.setState({ dataPenagihanTunai: sql.data });
        }

        pilihInvoice(item) {
            this.setState({ tempItem: item, tempAmount: item.Amount });
        }

        handlePilihCoa(i) {
            let data = this.state.dataPayment;
            let CoaCode = data[i].Code;
            let CoaID = data[i].ID;
            let CoaName = data[i].Description;
            this.setState({ tempCoaCode: CoaCode, tempCoaName: CoaName, tempCoaID: CoaID });
        }

        addInvoiceTunai(e) {
            e.stopPropagation();
            e.preventDefault();
            if (this.state.tempCoaCode != "") {
                let data = this.state.dataTempInvoice;
                let item = this.state.tempItem;
                let isTrue = true;
                for (let ii of data) if (ii.ID == item.ID) isTrue = false;
                if (isTrue == true) {
                    let tempData = {
                        ID: item.ID,
                        NoInvoice: item.NoInvoice,
                        SalesName: item.SalesName,
                        CustomerName: item.CustomerName,
                        AmountOpen: item.AmountOpen,
                        Amount: item.Amount,
                        AmountBalance: item.AmountBalance,
                        CoaCode: this.state.tempCoaCode,
                        CoaName: this.state.tempCoaName,
                        CoaID: this.state.tempCoaID
                    };

                    data.push(tempData);
                    this.setState({ dataTempInvoice: data });
                    document.getElementById('btnTutupInvoiceTunai').click();
                    document.getElementById('btnTutupDataInvoice').click();
                } else {
                    pesan("Pemberitahuan", "No Invoice Sudah Di pilih", "warning");
                }
            } else {
                pesan("Pemberitahuan", "Silahkan pilih Akun Bank", "warning");
            }
        }

        deleteInvocieTunai(i) {
            let data = this.state.dataTempInvoice;
            data.splice(i, 1);
            this.setState({ dataTempInvoice: data });
        }

        async handleKirimTunai(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnKirimTunai');
            btn.disabled = true;
            btn.innerText = 'Memproses . . .';
            let form = e.target;
            if (form.checkValidity()) {
                document.getElementById('btnKirim').disabled = true;
                document.getElementById('btnKirim').innerHTML = "Mengirim . . .";
                let host = window.location.hostname === "localhost" ? "http://localhost:2005/api_mobile/" : "https://penagihanapi.pxa.co.id/api_mobile/";
                let data = new FormData(form);
                fetch(host + "kirim_pembayaran_tunai", {
                    method: 'POST',
                    body: data,
                }).then(response => response.json()).then(hasil => {
                    if (hasil.status === "true") {
                        this.main();
                        this.setState({ dataTempInvoice: [], tempAmount: 0, tempCoaCode: "", tempCoaID: "", tempCoaName: "", tempItem: "" })
                        pesan("Proses Success", hasil.message, "primary");
                        btn.disabled = false;
                        btn.innerHTML = "Kirim";
                        document.getElementById('btnTutupKirimTunai').click();
                    } else {
                        btn.disabled = false;
                        btn.innerHTML = "Kirim";
                        pesan("Peringatan", hasil.message, "danger");
                    }
                }).catch((error) => {
                    btn.disabled = false;
                    btn.innerHTML = "Kirim";
                    console.log("Error: " + error);
                });
            } else {
                let forms = document.getElementsByClassName('needs-validation');
                let ululu = Array.prototype.filter.call(forms, function (form) {
                    form.classList.add('was-validated');
                });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Kacab Baru</h2>
                        <p></p>
                        <DataGrid dataSource={this.state.data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            <Column caption="" cellRender={(rowData) => (
                                <div className='d-flex gap-2'>
                                    <button className='btn' type="button" onClick={() => this.handleDetail(rowData.data)}>
                                        <span className='material-icons'>more_horiz</span>
                                    </button>
                                    <button className='btn' type="button" onClick={() => this.handleModalKirim(rowData.data)} data-bs-toggle="modal" data-bs-target="#modalKirim">
                                        <span className='material-icons'>check_circle_outline</span>
                                    </button>
                                </div>
                            )} />
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                    </div>

                    <div className="modal fade" id="modalKirimKePusat" tabIndex="-1" aria-labelledby="modalKirimKePusat" aria-hidden="true">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <form id="formKirim" onSubmit={(e) => this.handleKirimTunai(e)} className="needs-validation" noValidate>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modalKirimKePusatLabel">Kirim Ke Pusat</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='d-flex justify-content-between align-items-center gap-2'>
                                            <button className='btn btn-default' type="button" data-bs-toggle="modal" data-bs-target="#modalDataInvoice" onClick={() => this.handleCariInvoice("")}>Tambah Invoice</button>
                                            <div className='d-flex justify-content-end align-items-center'>
                                                <label>Tanggal Pembayaran</label>
                                                <input type="date" value={this.state.tanggalSetor} onChange={(e) => this.setState({ tanggalSetor: e.target.value })} className="form-control" name="TanggalPembayaran" />
                                            </div>
                                        </div>
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>No Invoice</th>
                                                        <th>Sales</th>
                                                        <th>Pelanggan</th>
                                                        <th>Saldo Awal</th>
                                                        <th>Pembayaran</th>
                                                        <th>Saldo Akhir</th>
                                                        <th>Bank</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.dataTempInvoice.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.NoInvoice}</td>
                                                                    <td>{item.SalesName}</td>
                                                                    <td>{item.CustomerName}</td>
                                                                    <td>{numberFormat(item.AmountOpen)}</td>
                                                                    <td>{numberFormat(item.Amount)}</td>
                                                                    <td>{numberFormat(item.AmountBalance)}</td>
                                                                    <td>{item.CoaName}</td>
                                                                    <td>
                                                                        <span className='material-icons' onClick={() => this.deleteInvocieTunai(index)}>delete</span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <center style={{ display: "none" }}>
                                            <div className="form-group">
                                                <label style={{ fontSize: "16px" }}>Upload Bukti Transfer</label>
                                                <div className='image-upload'>
                                                    <label id="labelImgTunai" htmlFor="image-upload-tunai">
                                                        <img src={img_upload} style={{ width: "150px" }} />
                                                    </label>
                                                    <input type="file" accept='image/*' name="Img" id="image-upload-tunai" onChange={(e) => this.openFile(e, "labelImgTunai")} required={this.state.Detail.PaymentType == "Tunai" ? true : false} />
                                                    <div className="invalid-feedback">Silahkan Pilih Bukti Transfer</div>
                                                </div>
                                            </div>
                                        </center>
                                    </div>
                                    <input type="hidden" name="ChangeImg" value={this.state.ChangeImg} />
                                    <input type="hidden" name="Token" value={getCookie("Token")} />
                                    <input type="hidden" name="data" value={JSON.stringify(this.state.dataTempInvoice)} />
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupKirimTunai" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning" id="btnKirimTunai">Kirim</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDataInvoice" tabIndex="-1" aria-labelledby="modalDataInvoice" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalDataInvoiceLabel">Cari Data</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type="search" placeholder='Cari No Invoice' className='form-control' onKeyUp={(e) => this.handleCariInvoice(e.target.value)} />
                                    <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>No Invoice</th>
                                                    <th>Sales</th>
                                                    <th>Pelanggan</th>
                                                    <th>Saldo Awal</th>
                                                    <th>Pembayaran</th>
                                                    <th>Saldo Akhir</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataPenagihanTunai.map((item, index) => {
                                                        return (
                                                            <tr key={index} onClick={() => this.pilihInvoice(item)} data-bs-toggle="modal" data-bs-target="#modalAddInvoiceTunai">
                                                                <td>{item.NoInvoice}</td>
                                                                <td>{item.SalesName}</td>
                                                                <td>{item.CustomerName}</td>
                                                                <td>{numberFormat(item.AmountOpen)}</td>
                                                                <td>{numberFormat(item.Amount)}</td>
                                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDataInvoice" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalAddInvoiceTunai" tabIndex="-1" aria-labelledby="modalAddInvoiceTunai" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form onSubmit={(e) => this.addInvoiceTunai(e)} className="needs-validation" noValidate>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modalAddInvoiceTunaiLabel">Pilih Akun Bank</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='form-group'>
                                            <label>Akun Bank</label>
                                            <select className='form-select' onChange={(e) => this.handlePilihCoa(e.target.value)} required={true}>
                                                <option value="">Silahkan Pilih Akun Bank</option>
                                                {
                                                    this.state.dataPayment.map((item, index) => {
                                                        return (<option key={index} value={index}>{item.Description}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label>Jumlah</label>
                                            <input type="number" className='form-control' step="0.01" value={this.state.tempAmount} onChange={(e) => this.setState({ tempAmount: e.target.value })} readOnly={true} />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupInvoiceTunai" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning" id="btnAddInvoiceTunai">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div >

                    <div className="modal fade" id="modalKirim" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <form id="formKirim" onSubmit={(e) => this.handleKirim(e)} className="needs-validation" noValidate>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Kirim Ke Pusat</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" name="DocNumber" value={this.state.Detail.DocNumber} />
                                        <input type="hidden" name="KacabID" value={getCookie("UserID")} />
                                        <input type="hidden" name="ChangeImg" value={this.state.ChangeImg} />
                                        <input type="hidden" name="Token" value={getCookie("Token")} />
                                        <table className='table'>
                                            <tr>
                                                <td width="120px">Nomor Dokumen</td>
                                                <td width="15px">:</td>
                                                <td>{this.state.Detail.DocNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Jenis Pembayaran</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Transfer</td>
                                                <td>:</td>
                                                <td>{tanggalIndo(this.state.Detail.DocDate)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Setor</td>
                                                <td>:</td>
                                                <td>
                                                    <input type="date" className='form-control form-control-sm w-50' name="TanggalSetor" value={saiki()} onChange={(e) => this.setState({ tanggalSetor: e.target.value })} />
                                                </td>
                                            </tr>
                                        </table>
                                        <div className='table-responsive'>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th width="150px">Nomor Invoice</th>
                                                        <th width="150px">Pembayaran</th>
                                                        <th>Akun Pembayraan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.dataPayment.map((item, i) => {
                                                            let Payment = [];
                                                            let Pay = item.Payment != null ? item.Payment.split(",") : [];
                                                            for (let pay of Pay) {
                                                                Payment.push(
                                                                    <Fragment>
                                                                        <span>{pay}</span>
                                                                        <br />
                                                                    </Fragment>
                                                                );
                                                            }
                                                            return (
                                                                <tr>
                                                                    <td>{item.NoInvoice}</td>
                                                                    <td>{numberFormat(item.Amount)}</td>
                                                                    <td id={"td" + item.ID}>
                                                                        {Payment}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>{numberFormat(this.state.Detail.Amount)}</td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <center>
                                            <div className="form-group" style={{ display: "none" }}>
                                                <label style={{ fontSize: "16px" }}>Upload Bukti Transfer</label>
                                                <div className='image-upload'>
                                                    <label id="labelImg" htmlFor="image-upload">
                                                        <img src={img_upload} style={{ width: "150px" }} />
                                                    </label>
                                                    <input type="file" accept='image/*' name="Img" id="image-upload" onChange={(e) => this.openFile(e, "labelImg")} required={this.state.Detail.PaymentType == "Tunai" ? true : false} />
                                                    <div className="invalid-feedback">Silahkan Pilih Bukti Transfer</div>
                                                </div>
                                            </div>
                                        </center>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupKirim" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning" id="btnKirim">Kirim</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment >
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export function rendDetailKacab(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
                Detail: {},
                dataGiro: [],
                TRReject: [],
                btnKirim: true,
                payment: [],
                CoaCode: "",
                Amount: "",
                DocID: "",
                DocNumber: "",
                akunBank: [],
                SisaTagihan: 0,
                Notes: "",
                tableGiro: [],
                divImg: ""
            }
        }

        async componentDidMount() {
            let ctx = this;
            let sql = await api("tagihan_detail_kacab", { DocNumber: this.props.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") this.setState({ data: sql.data, Notes: sql.data[0].Notes, btnKirim: sql.data.Status == "0" ? true : false, DocNumber: ctx.props.DocNumber });
            let bank = await api("akun_bank", { Token: getCookie("Token") });
            if (bank.status == "true") this.setState({ akunBank: bank.data });
        }

        async handleDetail(detail) {
            this.setState({ Detail: detail, Notes: detail.Notes, tableGiro: [], divImg: <img src={this.state.host + detail.Berkas} style={{ width: "100%" }} /> });
            if (detail.PaymentType == "Giro") {
                let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
                let tableGiro = <Fragment>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Nomor</th>
                                <th>Tanggal Jatuh Tempo</th>
                                <th>Nominal</th>
                                <th>Bank</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sql.data.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{val.GiroNumber}</td>
                                            <td>{tanggalIndo(val.GiroTermDate)}</td>
                                            <td>{numberFormat(val.Amount)}</td>
                                            <td>{val.CoaName}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>;
                let divImg = [];
                for (let img of sql.data) {
                    divImg.push(<div className='col-md-6 mb-2'><img src={this.state.host + img.Berkas} style={{ width: "100%" }} /></div>)
                }
                let divRow = <div className='row'>{divImg}</div>;
                this.setState({ tableGiro: tableGiro, divImg: divRow });
            } else {
                let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
                let tableGiro = <Fragment>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Nominal</th>
                                <th>Bank</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sql.data.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{numberFormat(val.Amount)}</td>
                                            <td>{val.CoaName}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>;
                this.setState({ tableGiro: tableGiro });
            }

            if (detail.Notes != "") {
                let dom = (
                    <Fragment>
                        <tr>
                            <td>Catatan</td>
                            <td>:</td>
                            <td>{detail.Notes}</td>
                        </tr>
                    </Fragment>
                )
                this.setState({ TRReject: dom });
            }
        }

        async handleReject(ID, DocNumber) {
            let btn = document.getElementById('btnRejectKacab');
            btn.disabled = true;
            btn.innerText = 'Memproses . . .';
            let sql = await api("reject_tagihan_kacab", { ID: ID, DocNumber: DocNumber, Token: getCookie("Token"), UserID: getCookie("UserID"), Notes: this.state.Notes });
            if (sql.status == "true") {
                btn.disabled = false;
                btn.innerText = 'Reject';
                pesan("Pemberitahuan", sql.message, "primary");
                App();
                document.getElementById('btnTutupDetail').click();
            } else {
                btn.disabled = false;
                btn.innerText = 'Reject';
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleApprove(ID, DocNumber, NoInvoice) {
            let btn = document.getElementById('btnApproveKacab');
            btn.disabled = true;
            btn.innerText = "Memproses . . .";
            let sql = await api("approve_tagihan_kacab", { ID: ID, DocNumber: DocNumber, NoInvoice: NoInvoice, Notes: this.state.Notes, Token: getCookie("Token"), UserID: getCookie("UserID") });
            if (sql.status == "true") {
                console.log(sql);
                btn.disabled = false;
                btn.innerText = "Approve";
                this.setState({ data: sql.data });
                pesan("Pemberitahuan", sql.message, "primary");
                document.getElementById('btnTutupDetail').click();
            } else {
                btn.disabled = false;
                btn.innerText = "Approve";
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleKirimKacab() {
            let sql = await api("kirim_tagihan_kacab", { ID: this.state.id, Token: getCookie("Token") });
            if (sql.status == "true") {
                pesan("Pemberitahuan", sql.message, "primary");
                App();
            } else {
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleSavePayment(e) {
            e.preventDefault();
            e.stopPropagation();
            let param = {
                DocID: this.state.Detail.ID,
                CoaCode: this.state.CoaCode,
                Amount: this.state.Amount,
                DocNumber: this.state.Detail.KacabID,
                Token: getCookie("Token")
            }
            let sql = await api("save_payment", param);
            if (sql.status == "true") {
                let SisaTagihan = 0;
                for (let item of sql.data) SisaTagihan += item.Amount;
                pesan("Berhasil", sql.message, "primary");
                this.setState({
                    Amount: 0,
                    CoaCode: "",
                    payment: sql.data,
                    SisaTagihan: SisaTagihan
                })
                document.getElementById('btnTutupModalAdd').click();
            } else {
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        handleSisa() {
            let Total = this.state.Detail.Amount - this.state.SisaTagihan;
            this.setState({ Amount: Total })
            console.log(this.state.Amount);
        }

        async deletePayment(ID) {
            let sql = await api("delete_payment", { ID: ID, Token: getCookie("Token") });
            if (sql.status == "true") {
                this.handleDetail(this.state.Detail);
                pesan("Berhasil", sql.message, "primary");
            } else {
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleEdit(data, type) {
            if (type == 'Transfer') {
                ReactDOM.render(<FormsTransfer ID={data.ID} IDHeader={this.props.DocNumber} menu="Kacab" from="Barukacab" />, document.getElementById("tampil"));
            } else if (type == "Tunai") {
                ReactDOM.render(<FormsTunai ID={data.ID} IDHeader={this.props.DocNumber} menu="Kacab" from="Barukacab" />, document.getElementById("tampil"));
            } else {
                ReactDOM.render(<FormsGiro ID={data.ID} IDHeader={this.props.DocNumber} menu="Kacab" from="Barukacab" />, document.getElementById("tampil"));
            }
        }

        render() {
            let CTTunai = 0, SWTunai = 0, PMTunai = 0, SATunai = 0;
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Baru</h2>
                        <div className='row'>
                            <div className='col-md-9'></div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="100px"></th>
                                        <th width="150px">No Invoice</th>
                                        <th width="150px">Pelanggan</th>
                                        <th width="150px">Sales</th>
                                        <th width="120px">Saldo Awal</th>
                                        <th width="120px">Pembayaran</th>
                                        <th width="120px">Saldo Akhir</th>
                                        <th width="110px">Tanggal Pelunasan</th>
                                        <th width="80px"></th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            let State = <span className="badge bg-danger">Reject</span>;
                                            if (item.KacabState == 0) {
                                                State = <span className='badge bg-warning'>Menunggu</span>;
                                            } else if (item.KacabState == 1) {
                                                State = <span className='badge bg-primary'>Approve</span>;
                                            }
                                            let TaxType = <span className="badge bg-danger">NON PPN</span>;
                                            if (item.TaxType == "PPN") TaxType = <span className='badge bg-primary'>PPN</span>;
                                            CTTunai++;
                                            SWTunai += item.AmountOpen;
                                            PMTunai += item.Amount;
                                            SATunai += item.AmountBalance;
                                            let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                            return (
                                                <tr>
                                                    <td className='d-flex'>
                                                        <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            <span className='material-icons'>visibility</span>
                                                        </button>
                                                        <button className='btn' onClick={() => this.handleEdit(item, 'Transfer')}>
                                                            <span className='material-icons'>edit</span>
                                                        </button>
                                                    </td>
                                                    <td>{item.NoInvoice}</td>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{item.SalesName}</td>
                                                    <td>{numberFormat(item.AmountOpen)}</td>
                                                    <td>{numberFormat(item.Amount)}</td>
                                                    <td>{numberFormat(item.AmountBalance)}</td>
                                                    <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                    <td>{status} {TaxType}</td>
                                                    <td>{State}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>{CTTunai}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{numberFormat(SWTunai)}</td>
                                        <td>{numberFormat(PMTunai)}</td>
                                        <td>{numberFormat(SATunai)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <table className='table'>
                                                <tbody>
                                                    <tr>
                                                        <td width="120px">Nama Pelanggan</td>
                                                        <td width="15px">:</td>
                                                        <td>{this.state.Detail.CustomerName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>No Invoice</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.NoInvoice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Awal</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountOpen)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.Amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Akhir</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountBalance)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tanggal Pelunasan</td>
                                                        <td>:</td>
                                                        <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.PaymentType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>PPN</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.TaxType}</td>
                                                    </tr>
                                                    {this.state.TRReject}
                                                </tbody>
                                            </table>
                                            <p></p>
                                            <div className='table-responsive'>{this.state.tableGiro}</div>
                                            <p></p>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>Akun Bank</th>
                                                        <th>Jumlah</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.payment.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item.CoaName}</td>
                                                                    <td>{numberFormat(item.Amount)}</td>
                                                                    <td>
                                                                        <button className='btn btn-sm' type="button" onClick={() => this.deletePayment(item.ID)}>
                                                                            <span className='material-icons'>delete</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            <div className='form-group'>
                                                <label>Catatan</label>
                                                <textarea name='Notes' className="form-control" placeholder='Isi Keterangan' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            {this.state.divImg}
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                    <button type="button" id="btnRejectKacab" className="btn btn-danger" onClick={() => this.handleReject(this.state.Detail.ID, this.state.Detail.KacabID)}>Reject</button>
                                    <button type="button" id="btnApproveKacab" className="btn btn-warning" onClick={() => this.handleApprove(this.state.Detail.ID, this.state.Detail.KacabID, this.state.Detail.NoInvoice)}>Approve</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalAddPay" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <form onSubmit={(e) => this.handleSavePayment(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Tambah Pembayaran</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='form-group'>
                                            <label>Akun Bank</label>
                                            <select className='form-select' onChange={(e) => this.setState({ CoaCode: e.target.value })} value={this.state.CoaCode} required>
                                                <option value=''>Pilih Akun Bank</option>
                                                {
                                                    this.state.akunBank.map((item, index) => {
                                                        return (
                                                            <option value={item.Code} key={index}>{item.Description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label>Jumlah</label>
                                            <div className='input-group'>
                                                <input className='form-control' value={this.state.Amount} onChange={(e) => this.setState({ Amount: e.target.value })} required />
                                                <button type="button" className='btn btn-primary' onClick={() => this.handleSisa()}>Sisa</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupModalAdd" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment >
            )
        }
    }
    ReactDOM.render(<Form DocNumber={ini} />, document.getElementById('tampil'));
}
export default App;