import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, numberFormat, tanggalIndo, getCookie, exportData, pesan, compareJSON } from '../modul';
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'
const diff = require('deep-diff').diff;

function App() {
    document.title = "Dashboard";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                totalSales: 0,
                totalKasir: 0,
                totalKacab: 0,
                totalPusat: 0,
                ctSales: 0,
                ctKasir: 0,
                ctKacab: 0,
                ctPusat: 0,
                data: [],
                filter: "dbdokumentranssales",
                search: "",
                filter: "sales",
                thead: ["", "Tanggal", "Nomor Dokumen", "Jumlah Invoice"],
                tbody: [],
                Detail: {},
                UserType: getCookie("UserType"),
                arrPayment: [],
                host: window.location.hostname === "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
                dataFilter: [],
                dataFilter2: [],
                theadFilter: [],
                DocFrom: "Pusat",
                qDoc: "",
                filKacabID: "",
                filPusatID: "",
                filNoInvoice: "",
                filPxaID: "",
                dataPenagihan: [],
                dataHistoryEdit: [],
                DataYangDiubah: [],
                Field: [
                    { cap: "Tanggal", sort: "TanggalPembayaran", type: "date" },
                    { cap: "Nama Sales", sort: "SalesName" },
                    { cap: "Nama Pelanggan", sort: "CustomerName" },
                    { cap: "No Invoice", sort: "NoInvoice" },
                    { cap: "PPN", sort: "TaxType" },
                    { cap: "Saldo Awal", sort: "AmountOpen", type: "number" },
                    { cap: "Pembayaran", sort: "Amount", type: "number" },
                    { cap: "Saldo Akhir", sort: "AmountBalance", type: "number" },
                ]
            };
        }

        async componentDidMount() {
            let sql = await api("data_dashboard", { Token: getCookie("Token") });
            if (sql.status == "true") {
                this.setState({
                    totalSales: sql.totalSales,
                    totalKasir: sql.totalKasir,
                    totalKacab: sql.totalKacab,
                    totalPusat: sql.totalPusat,
                    ctSales: sql.ctSales,
                    ctKasir: sql.ctKasir,
                    ctKacab: sql.ctKacab,
                    ctPusat: sql.ctPusat,
                });
            }
            let UserType = getCookie("UserType");
            if (UserType == "sales") {
                document.getElementById('divCardSales').click();
            } else if (UserType == "kasir") {
                document.getElementById('divCardKasir').click();
            } else if (UserType == "kacab") {
                document.getElementById('divCardKacab').click();
            } else if (UserType == "pusat") {
                document.getElementById('divCardPusat').click();
            }
        }

        handleExport() {
            exportData(this.state.data, "Data", this.state.thead);
        }

        async handleFilter(e, filter = "sales") {
            let cls = document.getElementsByClassName("card-bg");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.remove("active");
            }

            e.target.classList.add("active");

            let field = [
                // { text: "", field: "" },
                { text: "Tanggal", field: "TanggalPembayaran", type: "date" },
                { text: "Nama Sales", field: "SalesName" },
                { text: "Nama Pelanggan", field: "CustomerName" },
                { text: "No Invoice", field: "NoInvoice" },
                { text: "PPN", field: "TaxType" },
                { text: "Saldo Awal", field: "AmountOpen", type: "number" },
                { text: "Pembayaran", field: "Amount", type: "number" },
                { text: "Saldo Akhir", field: "AmountBalance", type: "number" },
            ];
            let filter2 = "";
            if (filter == "sales") {
                filter2 = "dbdokumentranssales";
            } else if (filter == "kasir") {
                filter2 = "dbdokumentranskasir";
            } else if (filter == "kacab") {
                filter2 = "dbdokumentranskacab";
            } else if (filter == "pusat") {
                filter2 = "dbdokumentranspusat";
            }
            this.setState({ filter: filter, thead: field, filter: filter2 });
            let sql = await api("data_dashboard_detail", { filter: filter, Token: getCookie("Token"), search: this.state.search });
            if (sql.status == "true") this.setState({ data: sql.data });
        }

        async handleCari(e) {
            this.setState({ search: e.target.value });
            let sql = await api("data_dashboard_detail", { filter: this.state.filter, Token: getCookie("Token"), search: e.target.value });
            if (sql.status == "true") this.setState({ data: sql.data });
        }

        async handleDetail(DocNumber) {
            let sql = await api("detail_data_dashboard", { DocNumber: DocNumber, Token: getCookie("Token"), filter: this.state.filter });
            if (sql.status == "true") {
                let field = [
                    { text: "", field: "detail" },
                    { text: "No Invoice", field: "NoInvoice" },
                    { text: "Tipe Pembayaran", field: "PaymentType" },
                    { text: "Pelanggan", field: "CustomerName" },
                    { text: "Saldo Awal", field: "SaldoAwal", type: "number" },
                    { text: "Pembayaran", field: "Pembayaran", type: "number" },
                    { text: "Saldo Akhir", field: "SaldoAkhir", type: "number" }
                ];
                this.setState({ thead: field, data: sql.data });
            }
        }

        async hancldeCariDokumen() {
            if (this.state.filKacabID != "" || this.state.filPusatID != "" || this.state.filPxaID != "" || this.state.filNoInvoice != "") {
                let btn = document.getElementById('btnSearch');
                btn.disabled = true;
                btn.innerHTML = '<div class="spinner-border spinner-border-sm text-danger" role="status"></div>';
                let sql = await api("data_history_document", {
                    KacabID: this.state.filKacabID,
                    PusatID: this.state.filPusatID,
                    NoInvoice: this.state.filNoInvoice,
                    PxaID: this.state.filPxaID,
                    Token: getCookie("Token")
                });

                if (sql.status == "true") {
                    this.setState({ dataFilter: sql.data });
                    btn.disabled = false;
                    btn.innerHTML = `<span class='material-icons'>search</span>`;
                }
            } else {
                pesan("Peringatan", "Silahkan masukan keyword", "danger");
            }
        }

        async handleCariHistory(val = "") {
            let sql = await api("riwayat_document", { NoInvoice: val });
            if (sql.status == "true") this.setState({ dataPenagihan: sql.data });
        }

        async getHistoryEdit(DocNumber) {
            let sql = await api("data_riwayat_edit", { DocNumber: DocNumber });
            if (sql.status == "true") this.setState({ dataHistoryEdit: sql.data });
        }

        CompareData(data) {
            let DataBefore = JSON.parse(data.DataBefore);
            let DataAfter = JSON.parse(data.DataAfter);
            let detail = diff(DataBefore.dbtinvoice, DataAfter.dbtinvoice);
            this.setState({ DataYangDiubah: detail });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Dashboard</h2>
                        <p></p>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='card card-bg bg-primary active' id="divCardSales" onClick={(e) => this.handleFilter(e, "sales")}>
                                    <h6 className='d-flex justify-content-between align-items-center'>Sales <span className='badge bg-danger'>{this.state.ctSales}</span></h6>
                                    <h4>{this.state.totalSales}</h4>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='card card-bg bg-primary' id="divCardKasir" onClick={(e) => this.handleFilter(e, "kasir")}>
                                    <h6 className='d-flex justify-content-between align-items-center'>Kasir <span className='badge bg-danger'>{this.state.ctKasir}</span></h6>
                                    <h4>{this.state.totalKasir}</h4>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='card card-bg bg-primary' id="divCardKacab" onClick={(e) => this.handleFilter(e, "kacab")}>
                                    <h6 className='d-flex justify-content-between align-items-center'>Kacab <span className='badge bg-danger'>{this.state.ctKacab}</span></h6>
                                    <h4>{this.state.totalKacab}</h4>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='card card-bg bg-primary' id="divCardPusat" onClick={(e) => this.handleFilter(e, "pusat")}>
                                    <h6 className='d-flex justify-content-between align-items-center'>Pusat <span className='badge bg-danger'>{this.state.ctPusat}</span></h6>
                                    <h4>{this.state.totalPusat}</h4>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <button className='btn btn-sm btn-primary' onClick={(e) => this.handleExport()}><span className='material-icons'>file_download</span> Export Excel</button>
                            <button className='btn btn-sm btn-primary' data-bs-toggle="modal" data-bs-target="#modalFilter" ><span className='material-icons'>filter_alt</span> Cek Status Dokumen</button>
                            <button className='btn btn-sm btn-primary' data-bs-toggle="modal" data-bs-target="#modalRiwayat" onClick={(e) => this.handleCariHistory("")} ><span className='material-icons'>history</span> Riwayat Edit</button>
                        </div>
                        <p></p>
                        <DataGrid dataSource={this.state.data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <TotalItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="{0}" />
                                <TotalItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="{0}" />
                                <TotalItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="{0}" />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                                <GroupItem column="AmountOpen" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Awal : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Pembayaran : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="AmountBalance" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Saldo Akhir : {0}" showInGroupFooter={false} alignByColumn={true} />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                    </div>

                    <div className="modal fade" id="modalFilter" tabindex="-1" aria-labelledby="modalFilter" aria-hidden="true">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Cek Status Dokumen</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='input-group input-group-sm gap-1'>
                                        <input className='form-control form-control-sm' placeholder='Nomor Dokumen Kacab' value={this.state.filKacabID} onChange={(e) => this.setState({ filKacabID: e.target.value })} />
                                        <input className='form-control form-control-sm' placeholder='Nomor Dokumen Pusat' value={this.state.filPusatID} onChange={(e) => this.setState({ filPusatID: e.target.value })} />
                                        <input className='form-control form-control-sm' placeholder='Nomor Dokumen Invoice' value={this.state.filNoInvoice} onChange={(e) => this.setState({ filNoInvoice: e.target.value })} />
                                        <input className='form-control form-control-sm' placeholder='Nomor Dokumen PXA' value={this.state.filPxaID} onChange={(e) => this.setState({ filPxaID: e.target.value })} />
                                        <button className='btn btn-sm btn-primary' id='btnSearch' onClick={() => this.hancldeCariDokumen()}>
                                            <span className='material-icons'>search</span>
                                        </button>
                                    </div>
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-hover table-stripped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Tanggal Penagihan</th>
                                                    <th>Code</th>
                                                    <th>Coa</th>
                                                    <th>Status Payment</th>
                                                    <th>Status PXA</th>
                                                    <th>Payment Type</th>
                                                    <th>State</th>
                                                    <th>Tanggal Invoice</th>
                                                    <th>No. Invoice</th>
                                                    <th>No. Kacab</th>
                                                    <th>No. Pusat</th>
                                                    <th>No. PXA</th>
                                                    <th>Status Approval</th>
                                                    <th>Sales</th>
                                                    <th>Customer</th>
                                                    <th>Status Penagihan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataFilter.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{tanggalIndo(item.TanggalDocPenagihan)}</td>
                                                                <td>{item.CoaCode}</td>
                                                                <td>{item.Coa}</td>
                                                                <td>{item.StatusPayment}</td>
                                                                <td>{item.StatusPXA}</td>
                                                                <td>{item.PaymentType}</td>
                                                                <td>{item.State}</td>
                                                                <td>{tanggalIndo(item.TanggalInvoice)}</td>
                                                                <td>{item.NoInvoice}</td>
                                                                <td>{item.KacabID}</td>
                                                                <td>{item.PusatID}</td>
                                                                <td>{item.DocNumberPXA}</td>
                                                                <td>{item.StatusApproval}</td>
                                                                <td>{item.SalesName}</td>
                                                                <td>{item.CustomerName}</td>
                                                                <td>{item.StatusDocumentPenagihan}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupFilter" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDetail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <table className='table table-bordered'>
                                                <tr>
                                                    <td>Nama Pelanggan</td>
                                                    <td>:</td>
                                                    <td>{this.state.Detail.CustomerName}</td>
                                                </tr>
                                                <tr>
                                                    <td>No Invoice</td>
                                                    <td>:</td>
                                                    <td>{this.state.Detail.NoInvoice}</td>
                                                </tr>
                                                <tr>
                                                    <td>Saldo Awal</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.Detail.SaldoAwal)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Diskon</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.Detail.Diskon)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Saldo Akhir</td>
                                                    <td>:</td>
                                                    <td>{numberFormat(this.state.Detail.SaldoAkhir)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tanggal Pelunasan</td>
                                                    <td>:</td>
                                                    <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Jenis Pembayaran</td>
                                                    <td>:</td>
                                                    <td>{this.state.Detail.PaymentType}</td>
                                                </tr>
                                                {this.state.dataGiro}
                                            </table>
                                            <p></p>
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>Nama Bank</th>
                                                        <th>Jumlah</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.arrPayment.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>{item.CoaName}</td>
                                                                    <td>{numberFormat(item.Amount)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='col-md-6'>
                                            <img src={this.state.host + this.state.Detail.Berkas} style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalRiwayat" tabindex="-1" aria-labelledby="lblRiwayat" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="lblRiwayat">Riwayat Edit</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type="search" className="form-control" placeholder='Cari Nomor Dokumen' onChange={(e) => this.handleCariHistory(e.target.value)} />
                                    <p></p>
                                    <div className="table-responsive" style={{ maxHeight: "500px", height: "500px", overflowY: "auto" }}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>No. Invoice</th>
                                                    <th>Pelanggan</th>
                                                    <th>Tanggal Penagihan</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataPenagihan.map((tr, i) => {
                                                        return (
                                                            <tr key={i} onClick={() => this.getHistoryEdit(tr.NoInvoice)} data-bs-toggle="modal" data-bs-target="#modalRiwayatEdit">
                                                                <td>{tr.NoInvoice}</td>
                                                                <td>{tr.CustomerName}</td>
                                                                <td>{tanggalIndo(tr.DocDate)}</td>
                                                                <td>{numberFormat(tr.Amount)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalRiwayatEdit" tabindex="-1" aria-labelledby="lblRiwayat" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="lblRiwayat">Riwayat Edit</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>Oleh</th>
                                                    <th>Dari Menu</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataHistoryEdit.map((tr, i) => {
                                                        return (
                                                            <tr key={i} onClick={() => this.CompareData(tr)} data-bs-toggle="modal" data-bs-target="#modalDataYangDiubah">
                                                                <td>{tr.Waktu}</td>
                                                                <td>{tr.UserName}</td>
                                                                <td>{tr.DocType}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalDataYangDiubah" tabindex="-1" aria-labelledby="lblRiwayat" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="lblRiwayat">Riwayat Edit</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="table-responsive">
                                        <div className="table-responsive">
                                            <table className="table table-stripped">
                                                <thead>
                                                    <tr>
                                                        <th>Data</th>
                                                        <th>Sebelum</th>
                                                        <th>Setelah</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.DataYangDiubah.map((tr, i) => {
                                                            return (<tr key={i}>
                                                                <td>{tr.path[0]}</td>
                                                                <td>{tr.lhs}</td>
                                                                <td>{tr.rhs}</td>
                                                            </tr>)
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;