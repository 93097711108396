import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, tanggalIndo, host, rendModal, numberFormat, rendTable, getCookie, submitForm, pesan } from "../modul";
import { FormsTransfer, FormsTunai, FormsGiro } from './Forms';
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

function App() {
    document.title = "Penagihan Baru";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 0,
                sort: "DocDate",
                by: "DESC",
                SalesID: "",
                Field: [
                    { cap: "Tanggal", sort: "DocDate", type: "date" },
                    { cap: "Nomor Dokumen", sort: "ID" },
                    { cap: "Sales", sort: "SalesName" },
                    { cap: "Total Invoice", sort: "TotalInvoice", type: "number" },
                    { cap: "Pelanggan", sort: "Customer", type: "html" },
                ],
                Data: [],
                data: []
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                KasirID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token")
            }
            let sql = await api("list_tagihan_kasir", param);
            this.setState({ Data: sql.data });
        }

        async handleModalKirim() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                KasirID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token")
            }

            let sql = await api("list_tagihan_kasir", param);
            if (sql.status == "true") {
                let dd = [];
                for (let da of sql.data) {
                    dd.push({
                        DocNumber: da.ID,
                        SalesName: da.SalesName,
                        Customer: da.Customer,
                        DocDate: da.DocDate,
                        TotalInvoice: da.TotalInvoice,
                        isChk: false
                    });
                }
                this.setState({ data: dd });
            }
        }

        async handleKirim(e) {
            e.preventDefault();
            e.stopPropagation();
            let btn = document.getElementById('btnKirimKeKacab');
            btn.disabled = true;
            btn.innerText = "Memproses . . .";
            let sql = await api("kirim_tagihan_kasir", { data: this.state.data, Token: getCookie("Token"), UserID: getCookie("UserID") });
            if (sql.status == "true") {
                pesan("Berhasil", "Data berhasil dikirim", "primary");
                btn.disabled = false;
                btn.innerText = "Kirim Ke Kacab";
                document.getElementById('btnTutupKirim').click();
                this.main();
            } else {
                btn.disabled = false;
                btn.innerText = "Kirim Ke Kacab";
                pesan("error", sql.message, "danger");
            }
        }

        handleChk(e, ind) {
            let dd = this.state.data;
            for (let i = 0; i < dd.length; i++) {
                if (ind == i) {
                    if (e.target.checked == true) {
                        dd[i].isChk = true;
                    } else {
                        dd[i].isChk = false;
                    }
                }
            }
            this.setState({ data: dd });
        }

        handleChkAll(e) {
            let dd = this.state.data;
            if (e.target.checked == true) {
                for (let i = 0; i < dd.length; i++) {
                    dd[i].isChk = true;
                }
            } else {
                for (let i = 0; i < dd.length; i++) {
                    dd[i].isChk = false;
                }
            }
            this.setState({ data: dd });
        }

        handlePrint(data) {
            ReactDOM.render(<Cetak ID={data.ID} />, document.getElementById('divPrint'));
            setTimeout(() => window.print(), 500);
        }

        handleModalCetak(e) {
            ReactDOM.render(<Print />, document.getElementById('divPrint'));
            setTimeout(() => window.print(), 500);
        }

        CustomCell({ data }) {
            return <div dangerouslySetInnerHTML={{ __html: data.Customer }} />
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Baru</h2>
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className='d-flex gap-2'>
                                    <button className='btn btn-default' onClick={() => this.handleModalKirim()} data-bs-toggle="modal" data-bs-target="#modalKirim">Kirim Ke Kacab</button>
                                    <button className='btn btn-default' onClick={() => this.handleModalCetak()}>Cetak</button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <DataGrid dataSource={this.state.Data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            <Column caption="Actions"
                                cellRender={(rowData) => (
                                    <div>
                                        <i className="material-icons" onClick={() => rendDetail(rowData.data.ID)}>more_horiz</i>
                                        <i className="material-icons" onClick={() => this.handlePrint(rowData.data)}>print</i>
                                    </div>
                                )}
                            />
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                                <GroupItem column="GrandTotal" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Nominal : {0}" showInGroupFooter={false} alignByColumn={true} />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                    <div className="modal fade" id="modalKirim" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <form id="formKirim" onSubmit={(e) => this.handleKirim(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Approve Invoice</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="chkAll" onChange={(e) => this.handleChkAll(e)} />
                                                            <label className="form-check-label" htmlFor="chkAll">
                                                                Nomor Dokumen
                                                            </label>
                                                        </div>
                                                    </th>
                                                    <th>Tanggal</th>
                                                    <th>Sales</th>
                                                    <th>Total Invoice</th>
                                                    <th>Pelanggan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.map((val, i) => {
                                                        if (val.TotalInvoice > 0) {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" onChange={(e) => this.handleChk(e, i)} value={val.DocNumber} id={"chkAll" + val.DocNumber} checked={val.isChk} />
                                                                            <label className="form-check-label" for={"chkAll" + val.DocNumber}>
                                                                                {val.DocNumber}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>{val.DocDate}</td>
                                                                    <td>{val.SalesName}</td>
                                                                    <td>{val.TotalInvoice}</td>
                                                                    <td dangerouslySetInnerHTML={{ __html: val.Customer }}></td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupKirim" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-warning" id='btnKirimKeKacab'>Kirim Ke Kacab</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export function rendDetail(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: typeof ini == "object" ? ini.target.dataset.id : ini,
                data: [],
                host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
                Detail: {},
                dataGiro: [],
                TRReject: [],
                btnKirim: true,
                CTTunai: 0,
                CTTransfer: 0,
                CTGiro: 0,
                CTSetoran: 0,
                Notes: "",
                tableGiro: [],
                ReffDocNumber: "",
                divImg: "",
                PaymentType: "Tunai",
                ID: ""
            }
        }

        async componentDidMount() {
            ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><span className="material-icons">arrow_back</span> Kembali</div>, document.getElementById('divHeaderLabel'));
            if (this.state.id !== "") {
                let sql = await api("tagihan_detail_kasir", { DocNumber: this.state.id, Token: getCookie("Token") });
                if (sql.status == "true") {
                    this.setState({ data: sql.data, btnKirim: sql.data.Status == "0" ? true : false });
                    let tunai = 0, transfer = 0, giro = 0, setoran = 0;
                    for (let dd of sql.data) {
                        if (dd.PaymentType == "Tunai") tunai++;
                        if (dd.PaymentType == "Transfer") transfer++;
                        if (dd.PaymentType == "Giro") giro++;
                        if (dd.PaymentType == "Setoran") setoran++;
                    }
                    this.setState({ CTTunai: tunai, CTTransfer: transfer, CTGiro: giro, CTSetoran: setoran });
                }
            }
        }

        async handleDetail(detail) {
            this.setState({ Detail: detail, Notes: detail.Notes, tableGiro: [], divImg: <img src={this.state.host + detail.Berkas} style={{ width: "100%" }} /> });
            if (detail.PaymentType == "Giro") {
                let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
                let tableGiro = <Fragment>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Nomor</th>
                                <th>Tanggal Jatuh Tempo</th>
                                <th>Nominal</th>
                                <th>Bank</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sql.data.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{val.GiroNumber}</td>
                                            <td>{tanggalIndo(val.GiroTermDate)}</td>
                                            <td>{numberFormat(val.Amount)}</td>
                                            <td>{val.CoaName}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>;
                let divImg = [];
                for (let img of sql.data) {
                    divImg.push(<div className='col-md-6 mb-2'><img src={this.state.host + img.Berkas} style={{ width: "100%" }} /></div>)
                }
                let divRow = <div className='row'>{divImg}</div>;
                this.setState({ tableGiro: tableGiro, divImg: divRow });
            } else if (detail.PaymentType == "Transfer") {
                let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
                let tableGiro = <Fragment>
                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th>Nominal</th>
                                <th>Bank</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sql.data.map((val, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{numberFormat(val.Amount)}</td>
                                            <td>{val.CoaName}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>;
                this.setState({ tableGiro: tableGiro });
            }

            if (detail.Notes != "") {
                let dom = (
                    <Fragment>
                        <tr>
                            <td>Catatan</td>
                            <td>:</td>
                            <td>{detail.Notes}</td>
                        </tr>
                    </Fragment>
                )
                this.setState({ TRReject: dom });
            }
        }

        async handleReject(e, ID, DocNumber) {
            let btn = e.target;
            btn.disabled = true;
            btn.innerText = "Memproses . . .";
            let sql = await api("reject_tagihan_kasir", { ID: ID, DocNumber: DocNumber, Token: getCookie("Token"), UserID: getCookie("UserID"), Notes: this.state.Notes });
            if (sql.status == "true") {
                this.setState({ data: sql.data });
                pesan("Pemberitahuan", sql.message, "primary");
                btn.disabled = false;
                btn.innerText = "Reject";
                document.getElementById('btnTutupDetail').click();
            } else {
                pesan("Pemberitahuan", sql.message, "danger");
                btn.disabled = false;
                btn.innerText = "Reject";
            }
        }

        async handleApprove(e, ID, DocNumber) {
            let btn = e.target;
            btn.disabled = true;
            btn.innerText = "Memproses . . .";
            let sql = await api("approve_tagihan_kasir", { ID: ID, DocNumber: DocNumber, Notes: this.state.Notes, Token: getCookie("Token"), UserID: getCookie('UserID') });
            if (sql.status == "true") {
                this.setState({ data: sql.data });
                if (sql.CT == 0) this.setState({ btnKirim: false });
                pesan("Pemberitahuan", sql.message, "primary");
                btn.disabled = false;
                btn.innerText = "Approve";
                document.getElementById('btnTutupDetail').click();
            } else {
                btn.disabled = false;
                btn.innerText = "Approve";
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleKirimKacab() {
            let sql = await api("kirim_tagihan_kasir", { ID: this.state.id, Token: getCookie("Token"), UserID: getCookie("UserID") });
            if (sql.status == "true") {
                pesan("Pemberitahuan", sql.message, "primary");
                App();
            } else {
                pesan("Pemberitahuan", sql.message, "danger");
            }
        }

        async handleEdit(data, type) {
            if (type == 'Transfer') {
                ReactDOM.render(<FormsTransfer ID={data.ID} IDHeader={this.state.id} menu="Kasir" from="Barukasir" />, document.getElementById("tampil"));
            } else if (type == "Tunai") {
                ReactDOM.render(<FormsTunai ID={data.ID} IDHeader={this.state.id} menu="Kasir" from="Barukasir" />, document.getElementById("tampil"));
            } else {
                ReactDOM.render(<FormsGiro ID={data.ID} IDHeader={this.state.id} menu="Kasir" from="Barukasir" />, document.getElementById("tampil"));
            }
        }

        render() {
            let CTTunai = 0, SWTunai = 0, PMTunai = 0, SATunai = 0,
                CTTransfer = 0, SWTransfer = 0, PMTransfer = 0, SATransfer = 0,
                CTGiro = 0, SWGiro = 0, PMGiro = 0, SAGiro = 0,
                CTSetoran = 0, SWSetoran = 0, PMSetoran = 0, SASetoran = 0;
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Detail</h2>
                        <div className='row'>
                            <div className='col-md-9'></div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" style={{ display: this.state.CTTunai == 0 ? "none" : "block" }}>
                            <h6>Pembayaran Tunai</h6>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="150px"></th>
                                        <th width="180px">No Invoice</th>
                                        <th width="150px">Pelanggan</th>
                                        <th width="150px">Jenis Pembayaran</th>
                                        <th width="120px">Saldo Awal</th>
                                        <th width="120px">Pembayaran</th>
                                        <th width="120px">Saldo Akhir</th>
                                        <th width="150px">Tanggal Pembayaran</th>
                                        <th width="100px"></th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            if (item.PaymentType == "Tunai") {
                                                let State = <span className="badge bg-danger">Reject</span>;
                                                if (item.KasirState == 0) {
                                                    State = <span className='badge bg-warning'>Menunggu</span>;
                                                } else if (item.KasirState == 1) {
                                                    State = <span className='badge bg-primary'>Approve</span>;
                                                }
                                                let TaxType = <span className="badge bg-danger">NON PPN</span>;
                                                if (item.TaxType == "PPN") TaxType = <span className='badge bg-primary'>PPN</span>;
                                                CTTunai++;
                                                SWTunai += item.AmountOpen;
                                                PMTunai += item.Amount;
                                                SATunai += item.AmountBalance;
                                                let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                                return (
                                                    <tr key={index}>
                                                        <td className='d-flex'>
                                                            <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <span className='material-icons'>visibility</span>
                                                            </button>
                                                            <button className='btn' onClick={() => this.handleEdit(item, 'Tunai')}>
                                                                <span className='material-icons'>edit</span>
                                                            </button>
                                                        </td>
                                                        <td>{item.NoInvoice}</td>
                                                        <td>{item.CustomerName}</td>
                                                        <td>{item.PaymentType}</td>
                                                        <td>{numberFormat(item.AmountOpen)}</td>
                                                        <td>{numberFormat(item.Amount)}</td>
                                                        <td>{numberFormat(item.AmountBalance)}</td>
                                                        <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                        <td>{status}{TaxType}</td>
                                                        <td>{State}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>{CTTunai}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{numberFormat(SWTunai)}</td>
                                        <td>{numberFormat(PMTunai)}</td>
                                        <td>{numberFormat(SATunai)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='table-responsive' style={{ display: this.state.CTTransfer == 0 ? "none" : "block" }}>
                            <h6>Pembayaran Transfer</h6>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="150px"></th>
                                        <th width="180px">No Invoice</th>
                                        <th width="150px">Pelanggan</th>
                                        <th width="150px">Jenis Pembayaran</th>
                                        <th width="120px">Saldo Awal</th>
                                        <th width="120px">Pembayaran</th>
                                        <th width="120px">Saldo Akhir</th>
                                        <th width="150px">Tanggal Pembayaran</th>
                                        <th width="100px"></th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            if (item.PaymentType == "Transfer") {
                                                let State = <span className="badge bg-danger">Reject</span>;
                                                if (item.KasirState == 0) {
                                                    State = <span className='badge bg-warning'>Menunggu</span>;
                                                } else if (item.KasirState == 1) {
                                                    State = <span className='badge bg-primary'>Approve</span>;
                                                }
                                                let TaxType = <span className="badge bg-danger">NON PPN</span>;
                                                if (item.TaxType == "PPN") TaxType = <span className='badge bg-primary'>PPN</span>;
                                                CTTransfer++;
                                                SWTransfer += item.AmountOpen;
                                                PMTransfer += item.Amount;
                                                SATransfer += item.AmountBalance;
                                                let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                                return (
                                                    <tr key={index}>
                                                        <td className='d-flex'>
                                                            <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <span className='material-icons'>visibility</span>
                                                            </button>
                                                            <button className='btn' onClick={() => this.handleEdit(item, 'Transfer')}>
                                                                <span className='material-icons'>edit</span>
                                                            </button>
                                                        </td>
                                                        <td>{item.NoInvoice}</td>
                                                        <td>{item.CustomerName}</td>
                                                        <td>{item.PaymentType}</td>
                                                        <td>{numberFormat(item.AmountOpen)}</td>
                                                        <td>{numberFormat(item.Amount)}</td>
                                                        <td>{numberFormat(item.AmountBalance)}</td>
                                                        <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                        <td>{status} {TaxType}</td>
                                                        <td>{State}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>{CTTransfer}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{numberFormat(SWTransfer)}</td>
                                        <td>{numberFormat(PMTransfer)}</td>
                                        <td>{numberFormat(SATransfer)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='table-responsive' style={{ display: this.state.CTGiro == 0 ? "none" : "block" }}>
                            <h6>Pembayaran Giro</h6>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="150px"></th>
                                        <th width="180px">No Invoice</th>
                                        <th width="150px">Pelanggan</th>
                                        <th width="150px">Jenis Pembayaran</th>
                                        <th width="120px">Saldo Awal</th>
                                        <th width="120px">Pembayaran</th>
                                        <th width="120px">Saldo Akhir</th>
                                        <th width="120px">Tanggal Pembayaran</th>
                                        <th width="100px"></th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            if (item.PaymentType == "Giro") {
                                                let State = <span className="badge bg-danger">Reject</span>;
                                                if (item.KasirState == 0) {
                                                    State = <span className='badge bg-warning'>Menunggu</span>;
                                                } else if (item.KasirState == 1) {
                                                    State = <span className='badge bg-primary'>Approve</span>;
                                                }
                                                let TaxType = <span className="badge bg-danger">NON PPN</span>;
                                                if (item.TaxType == "PPN") TaxType = <span className='badge bg-primary'>PPN</span>;
                                                CTGiro++;
                                                SWGiro += item.AmountOpen;
                                                PMGiro += item.Amount;
                                                SAGiro += item.AmountBalance;
                                                let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                                return (
                                                    <tr key={index}>
                                                        <td className='d-flex'>
                                                            <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <span className='material-icons'>visibility</span>
                                                            </button>
                                                            <button className='btn' onClick={() => this.handleEdit(item, 'Giro')}>
                                                                <span className='material-icons'>edit</span>
                                                            </button>
                                                        </td>
                                                        <td>{item.NoInvoice}</td>
                                                        <td>{item.CustomerName}</td>
                                                        <td>{item.PaymentType}</td>
                                                        <td>{numberFormat(item.AmountOpen)}</td>
                                                        <td>{numberFormat(item.Amount)}</td>
                                                        <td>{numberFormat(item.AmountBalance)}</td>
                                                        <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                        <td>{status} {TaxType}</td>
                                                        <td>{State}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>{CTGiro}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{numberFormat(SWGiro)}</td>
                                        <td>{numberFormat(PMGiro)}</td>
                                        <td>{numberFormat(SAGiro)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="table-responsive" style={{ display: this.state.CTSetoran == 0 ? "none" : "block" }}>
                            <h6>Setoran Bank</h6>
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="150px"></th>
                                        <th width="180px">No Invoice</th>
                                        <th width="150px">Pelanggan</th>
                                        <th width="150px">Jenis Pembayaran</th>
                                        <th width="120px">Saldo Awal</th>
                                        <th width="120px">Pembayaran</th>
                                        <th width="120px">Saldo Akhir</th>
                                        <th width="150px">Tanggal Pelunasan</th>
                                        <th width="100px"></th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            if (item.PaymentType == "Setoran") {
                                                let State = <span className="badge bg-danger">Reject</span>;
                                                if (item.State == 0) {
                                                    State = <span className='badge bg-warning'>Menunggu</span>;
                                                } else if (item.State == 1) {
                                                    State = <span className='badge bg-primary'>Approve</span>;
                                                }
                                                let TaxType = <span className="badge bg-danger">NON PPN</span>;
                                                if (item.TaxType == "PPN") TaxType = <span className='badge bg-primary'>PPN</span>;
                                                CTSetoran++;
                                                SWSetoran += item.AmountOpen;
                                                PMSetoran += item.Amount;
                                                SASetoran += item.AmountBalance;
                                                let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                                return (
                                                    <tr key={index}>
                                                        <td className='d-flex'>
                                                            <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                <span className='material-icons'>visibility</span>
                                                            </button>
                                                            <button className='btn' onClick={() => this.handleEdit(item)} data-bs-toggle="modal" data-bs-target="#modalEdit">
                                                                <span className='material-icons'>edit</span>
                                                            </button>
                                                        </td>
                                                        <td>{item.NoInvoice}</td>
                                                        <td>{item.CustomerName}</td>
                                                        <td>{item.PaymentType}</td>
                                                        <td>{numberFormat(item.AmountOpen)}</td>
                                                        <td>{numberFormat(item.Amount)}</td>
                                                        <td>{numberFormat(item.AmountBalance)}</td>
                                                        <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                        <td>{status} {TaxType}</td>
                                                        <td>{State}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>{CTSetoran}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{numberFormat(SWSetoran)}</td>
                                        <td>{numberFormat(PMSetoran)}</td>
                                        <td>{numberFormat(SASetoran)}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <table className='table table-striped'>
                                                <tbody>
                                                    <tr>
                                                        <td>Nama Pelanggan</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.CustomerName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>No Invoice</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.NoInvoice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Awal</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountOpen)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.Amount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Saldo Akhir</td>
                                                        <td>:</td>
                                                        <td>{numberFormat(this.state.Detail.AmountBalance)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tanggal Pelunasan</td>
                                                        <td>:</td>
                                                        <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis Pembayaran</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.PaymentType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis PPN</td>
                                                        <td>:</td>
                                                        <td>{this.state.Detail.TaxType}</td>
                                                    </tr>
                                                    {this.state.TRReject}
                                                </tbody>
                                            </table>
                                            <div className='table-responsive'>{this.state.tableGiro}</div>
                                            <div className='form-group'>
                                                <label>Notes</label>
                                                <textarea className='form-control' name='Notes' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            {this.state.divImg}
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                    <button type="button" className="btn btn-danger" onClick={(e) => this.handleReject(e, this.state.Detail.ID, this.state.Detail.KasirID)} disabled={this.state.Detail.KasirState == 0 ? false : true}>Reject</button>
                                    <button type="button" className="btn btn-warning" onClick={(e) => this.handleApprove(e, this.state.Detail.ID, this.state.Detail.KasirID)} disabled={this.state.Detail.KasirState == 0 ? false : true}>Approve</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="modalEdit" tabIndex="-1" aria-labelledby="labelModaledit" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <form onSubmit={(e) => this.handleEditTrans(e)} noValidate>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="labelModaledit">Detail Invoice</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            this.state.PaymentType == "Tunai" ?
                                                <FormsTunai ID={this.state.ID} /> :
                                                this.state.PaymentType == "Transfer" ? <FormsTransfer ID={this.state.ID} /> :
                                                    <FormsGiro ID={this.state.ID} />
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupEdit" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-primary">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
    ReactDOM.render(<Form />, document.getElementById('tampil'));
}

class Print extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.ID,
            Data: [],
            DocDate: saiki(),
            totalTunai: 0,
            totalTF: 0,
            totalGiro: 0,
        }
    }

    async componentDidMount() {
        if (this.state.id !== "") {
            let sql = await api("cetak_kasir", { Token: getCookie("Token") });
            console.log(sql.data);
            if (sql.status == "true") {
                let totalTunai = 0;
                let totalTF = 0;
                let totalGiro = 0;
                for (let dd of sql.data) {
                    totalTunai += parseFloat(dd.AmountTunai);
                    totalTF += parseFloat(dd.AmountTF);
                    totalGiro += parseFloat(dd.AmountGiro);
                }
                this.setState({ Data: sql.data, totalTunai: totalTunai, totalTF: totalTF, totalGiro: totalGiro });
            }
        }
    }

    render() {
        return (
            <Fragment>
                <h6 style={{ textAlign: "center" }}>Data Pembayaran Tanggal {tanggalIndo(this.state.DocDate)}</h6>
                <p></p>
                {
                    this.state.Data.map((item, index) => {
                        return (
                            <Fragment>
                                <h6><u>{item.SalesName}</u></h6>
                                <table className='table table-borderless' key={index}>
                                    <tbody>
                                        <tr>
                                            <td width="150px">Transfer</td>
                                            <td width="5px">:</td>
                                            <td width="200px">{numberFormat(item.AmountTF)}</td>
                                            <td rowSpan={3}>TTD Sales</td>
                                        </tr>
                                        <tr>
                                            <td>Tunai</td>
                                            <td>:</td>
                                            <td>{numberFormat(item.AmountTunai)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cek / Giro</td>
                                            <td>:</td>
                                            <td>{numberFormat(item.AmountGiro)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Fragment>
                        )
                    })
                }
                <Fragment>
                    <h6><u>Total Setoran (Keseluruhan Sales)</u></h6>
                    <table className='table table-borderless'>
                        <tbody>
                            <tr>
                                <td width="150px">Transfer</td>
                                <td width="5px">:</td>
                                <td width="200px">{numberFormat(this.state.totalTF)}</td>
                                <td rowSpan={3}>TTD Kasir</td>
                            </tr>
                            <tr>
                                <td>Tunai</td>
                                <td>:</td>
                                <td>{numberFormat(this.state.totalTunai)}</td>
                            </tr>
                            <tr>
                                <td>Cek / Giro</td>
                                <td>:</td>
                                <td>{numberFormat(this.state.totalGiro)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Fragment>
            </Fragment>
        )
    }
}

class Cetak extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.ID,
            DocDate: saiki(),
            DocNumber: "",
            Data: [],
            NamaSales: "",
            NamaKasir: getCookie("UserName"),
            Total: ""
        }
    }

    async componentDidMount() {
        if (this.state.id !== "") {
            let sql = await api("tagihan_detail_kasir", { DocNumber: this.state.id, Token: getCookie("Token") });
            if (sql.status == "true") {
                let Total = 0;
                for (let data of sql.data) Total += parseFloat(data.Pembayaran);
                this.setState({ Data: sql.data, DocNumber: sql.data[0].DocNumber, NamaSales: sql.data[0].SalesName, Total: Total });
            }
        }
    }

    render() {
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-md-6'>
                        <h6>Tanggal # {tanggalIndo(this.state.DocDate)}</h6>
                        <h6>Nomor Dokumen # {this.state.DocNumber}</h6>
                    </div>
                    <div className='col-md-6'></div>
                </div>
                <p></p>
                <table className='table'>
                    <thead>
                        <th>Nomor Invoice</th>
                        <th>Nama Pelanggan</th>
                        <th>Tipe Pembayaran</th>
                        <th>Nominal</th>
                    </thead>
                    <tbody>
                        {
                            this.state.Data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.NoInvoice}</td>
                                        <td>{item.CustomerName}</td>
                                        <td>{item.PaymentType}</td>
                                        <td>{numberFormat(item.Amount)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan='2'><b>Total</b></td>
                            <td><b>{numberFormat(this.state.Total)}</b></td>
                        </tr>
                    </tfoot>
                </table>
                <div className='row'>
                    <div className='col-6'>
                        <center>
                            <h6 style={{ textAlign: "center" }}>{this.state.NamaSales}</h6>
                            <p style={{ color: "#FEFEFE" }}>0</p>
                            <h6>(........................)</h6>
                        </center>
                    </div>
                    <div className='col-6'>
                        <center>
                            <h6 style={{ textAlign: "center" }}>{this.state.NamaKasir}</h6>
                            <p style={{ color: "#FEFEFE" }}>0</p>
                            <h6>(........................)</h6>
                        </center>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default App;