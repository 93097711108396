import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, getCookie, saiki, tanggalIndo } from "../modul";

function App() {
    document.title = "Users";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                searchcount: 50,
                index: 0,
                sort: "Username",
                by: "DESC",
                d1: saiki(),
                d2: saiki()
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        handleDetail(detail) {
            ReactDOM.render(<Detail detail={detail} />, document.getElementById('tampil'))
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                PusatID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                Token: getCookie("Token"),
                d1: this.state.d1,
                d2: this.state.d2
            }

            let sql = await api("history_dokumen", param);
            console.log(sql);
            if (sql.status == "true") {
                this.setState({ data: sql.data });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Riwayat Dokumen</h2>
                        <div className='row'>
                            <div className='col-md-6'></div>
                            <div className='col-md-6'>
                                <div className='input-group'>
                                    <input type="date" value={this.state.d1} onChange={(e) => this.setState({ d1: e.target.value })} className="form-control" />
                                    <input type="date" value={this.state.d2} onChange={(e) => this.setState({ d2: e.target.value })} className="form-control" />
                                    <button className='btn btn-default' onClick={() => this.main()}><span className='material-icons'>search</span></button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="120px"></th>
                                        <th width="200px">Tanggal</th>
                                        <th width="150px">Nomor Dokumen</th>
                                        <th width="250px">Keterangan</th>
                                        <th>Oleh</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <button className='btn btn-sm' onClick={() => this.handleDetail(item.Detail)}>
                                                            <span className='material-icons'>more_horiz</span>
                                                        </button>
                                                    </td>
                                                    <td>{tanggalIndo(item.Waktu)} - {item.Waktu.substr(11, 8)}</td>
                                                    <td>{item.DocNumber}</td>
                                                    <td>{item.Notes}</td>
                                                    <td>{item.Nama}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
        }
    }

    async componentDidMount() {
        let ctx = this;
        console.log(JSON.parse(this.props.detail));
        ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><span className="material-icons">arrow_back</span> Kembali</div>, document.getElementById('divHeaderLabel'));
        this.setState({ data: JSON.parse(ctx.props.detail) });
    }

    render() {
        return (
            <Fragment>
                <div className="card-form">
                    <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Baru</h2>
                    <div className='row'>
                        <div className='col-md-9'></div>
                        <div className='col-md-3'>
                            <div className='input-icons'>
                                <span className='material-icons icon-search'>search</span>
                                <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className="table-responsive" id="divView">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Nomor</th>
                                    <th>No Invoice</th>
                                    <th>Pelanggan</th>
                                    <th>Sales</th>
                                    <th>PPN</th>
                                    <th>Saldo Awal</th>
                                    <th>Pembayaran</th>
                                    <th>Saldo Akhir</th>
                                    <th>Tanggal Pelunasan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.data.map((item, i) => {
                                        return (
                                            <Fragment>
                                                <tr>
                                                    <td>{item.DocNumber}</td>
                                                    <td>{item.NoInvoice}</td>
                                                    <td>{item.CustomerName}</td>
                                                    <td>{item.SalesName}</td>
                                                    <td>{item.TaxType}</td>
                                                    <td>{item.SaldoAwal}</td>
                                                    <td>{item.Pembayaran}</td>
                                                    <td>{item.SaldoAkhir}</td>
                                                    <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                </tr>
                                            </Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default App;