import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import profile from './profile.png';
import { rendMenu, pesan, submitForm, getCookie, logout, api, setCookie } from './modul.js';
import Dashboard from './page/Dashboard';
import Barupusat from './page/Barupusat';
import Done from './page/Done';
import History from './page/History';
import Selesaipusat from './page/Selesaipusat';

// Kasir
import Barukasir from './page/Barukasir';
import Selesaikasir from './page/Selesaikasir';

// Kacab
import Barukacab from './page/Barukacab';
import Barutunaikacab from './page/Barutunaikacab';
import Selesaitunaikacab from './page/Selesaitunaikacab';
import Selesaikacab from './page/Selesaikacab';

// Sales
import Tunai from './page/Tunai';
import Transfer from './page/Transfer';
import Giro from './page/Giro';

import Users from './page/Users';
import Login from './page/Login';

function App() {
  setTimeout(async () => {
    if (getCookie("UserID") != "") {
      rendMenu();
      window.addEventListener('popstate', function (event) {
        if (event.state != null) {
          let dataMenu = window.location.pathname.replace("/", "");
          pilihMenu(dataMenu, false);
        }
      });
    } else {
      ReactDOM.render(<Login />, document.getElementById('root'));
    }
  }, 500);
  let port = getCookie("IsSu") == "1" ? <select className='form-select form-select-sm w-50' onChange={(e) => handleSwitch(e.target.value)} value={getCookie("UserType")}>
    <option value="sales">Sales</option>
    <option value="kasir">Kasir</option>
    <option value="kacab">Kepala Cabang</option>
    <option value="pusat">Pusat</option>
  </select> : [];
  return (
    <Fragment>
      <div style={{ position: 'fixed', bottom: '0px', height: '100%', width: '100%' }}>
        <div className="wrapper">
          <nav id="sidebar">
            <div className="sidebar-header">
              <h1 style={{ color: "#fefefefe", paddingLeft: "32px" }} onClick={(e) => window.location.reload()}>{getCookie("UserType").toUpperCase()}</h1>
            </div>
            <div className="transbox">
              <ul className="list-unstyled components" id="divMenu"></ul>
            </div>
          </nav>
          <div id="content">
            <div className="card" style={{ padding: '5px 10px 0 0', backgroundColor: '#FEFEFE', borderRadius: '60px 0 0 0', border: "none" }}>
              <div className='row' style={{ marginLeft: "39px", marginRight: "52px", border: "none" }}>
                <div className='col-md-7' style={{ marginTop: "41px" }}>
                  <div className='d-flex justify-content-start align-items-center'>
                    <button className='btn' onClick={() => toggleSidebar()}>
                      <span className='material-icons'>menu</span>
                    </button>
                    <div id='divHeaderLabel'></div>
                  </div>
                </div>
                <div className='col-md-5' style={{ marginTop: "20px" }}>
                  <div className='d-flex justify-content-end align-items-center gap-2'>
                    {port}
                    <div>
                      <label style={{ fontSize: '16px', paddingRight: "24px" }} id="lblNamaAdmin">Hai, {getCookie("UserName")}</label>
                      <br></br>
                      <label style={{ fontSize: '16px', paddingRight: "24px", fontWeight: "bold" }}>{getCookie("NamaUsaha")}</label>
                    </div>
                    <img src={profile} className='img-profile' />
                  </div>
                </div>
              </div>
            </div>
            <div id="tampil" className="card" style={{ border: "none", minHeight: '95vh', maxHeight: '95vh', borderRadius: '0 0 0 60px', overflowY: 'scroll', paddingBottom: "50px" }}></div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalGantiPassword" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={(e) => handleChangePassword(e)}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Ganti Password</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className='form-group'>
                  <label>Password Lama</label>
                  <input type="password" className='form-control' id='oldPassword' placeholder='Password Lama' onInvalid={(e) => e.target.setCustomValidity('Silahkan Masukan Password Lama')} onInput={(e) => e.target.setCustomValidity('')} required />
                </div>
                <div className='form-group'>
                  <label>Password Baru</label>
                  <input type="password" className='form-control' id='newPassword' placeholder='Password Baru' onInvalid={(e) => e.target.setCustomValidity('Silahkan Masukan Password Baru')} onInput={(e) => e.target.setCustomValidity('')} required />
                </div>
                <div className='form-group'>
                  <label>Ulangi Password Baru</label>
                  <input type="password" className='form-control' id='reNewPassword' placeholder='Ulangi Password Baru' onInvalid={(e) => e.target.setCustomValidity('Silahkan Ulangi Password Baru')} onInput={(e) => e.target.setCustomValidity('')} required />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" id='btnTutupModalPwd' data-bs-dismiss="modal">Batal</button>
                <button type="submit" className="btn btn-primary">Simpan</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

function toggleSidebar() {
  document.getElementById('sidebar').classList.toggle('active');
}

async function handleChangePassword(e) {
  e.preventDefault();
  e.stopPropagation();
  let OldPwd = document.getElementById('oldPassword').value;
  let NewPwd = document.getElementById('newPassword').value;
  let ReNewPwd = document.getElementById('reNewPassword').value;
  if (NewPwd == ReNewPwd) {
    let sql = await api("ganti_password_admin", { ID: getCookie("UserID"), OldPwd: OldPwd, NewPwd: NewPwd, Token: getCookie("Token") });
    if (sql.status == "true") {
      pesan("Info", "Password berhasil diganti", "primary");
      document.getElementById('btnTutupModalPwd').click();
    } else {
      pesan("Info", sql.message, "danger");
    }
  } else {
    pesan("Info", "Password Baru dan Ulangi Password Baru tidak sama", "danger");
  }
}

function handleSwitch(UserType) {
  setCookie("UserType", UserType, 3);
  window.location.reload();
}

export const pilihMenu = function (path, ch = true) {
  var e = document.getElementsByClassName("active");
  for (let h = 0; h < e.length; h++) { e[h].classList.remove("active"); }
  if (ch == true) window.history.pushState(path, "data", path);
  document.getElementById(path).className = "active";
  switch (path) {
    case 'Barupusat':
      Barupusat();
      break;

    case 'Done':
      Done();
      break;

    case 'Selesaipusat':
      Selesaipusat();
      break;

    case 'Barukacab':
      Barukacab();
      break;

    case 'Barutunaikacab':
      Barutunaikacab();
      break;

    case 'Selesaitunaikacab':
      Selesaitunaikacab();
      break;

    case 'Selesaikacab':
      Selesaikacab();
      break;

    case 'Barukasir':
      Barukasir();
      break;

    case 'Selesaikasir':
      Selesaikasir();
      break;

    case 'Tunai':
      Tunai();
      break;

    case 'Transfer':
      Transfer();
      break;

    case 'Giro':
      Giro();
      break;

    case 'Users':
      Users();
      break;

    case 'History':
      History();
      break;

    case 'Logout':
      logout();
      break;

    default: {
      Dashboard();
    } break;
  }
  var x = window.matchMedia("(max-width: 768px)");
  if (x.matches == true) {
    document.getElementById("sidebar").classList.add("active");
  } else {
    document.getElementById("sidebar").classList.remove("active");
  }
}

export default App;
