import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, tanggalIndo, rendModal, numberFormat, rendTable, getCookie, submitForm, pesan } from "../modul";

function App() {
    document.title = "Penagihan Sudah Di Approve Pusat";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                searchcount: 50,
                index: 0,
                sort: "DocDate",
                by: "DESC",
                SalesID: "",
                DocNumber: "",
                totalCt: 0,
                totalAmount: 0,
                D1: window.localStorage.getItem("D1") || saiki("1"),
                D2: window.localStorage.getItem("D2") || saiki(),
                filterDate: true
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChangeDate(dd, val) {
            window.localStorage.setItem(dd, val);
            if (dd == "D1") {
                this.setState({ "D1": val });
            } else {
                this.setState({ "D2": val });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        handleDetail(detail) {
            ReactDOM.render(<Form DocNumber={detail.DocNumber} />, document.getElementById('tampil'));
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                PusatID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                D1: this.state.D1,
                D2: this.state.D2,
                Token: getCookie("Token"),
                filterDate: this.state.filterDate
            }
            let sql = await api("list_tagihan_selesai_pusat", param);
            if (sql.status == "true") {
                let totalCt = 0, totalAmount = 0;
                for (let dd of sql.data) {
                    totalCt += parseFloat(dd.Total);
                    totalAmount += parseFloat(dd.Amount);
                }
                this.setState({ data: sql.data, totalCt: totalCt, totalAmount: totalAmount });
            }
        }

        async handleRejectTrans(e) {
            e.preventDefault();
            e.stopPropagation();
            let sql = await api("reject_doc_pusat", { DocNumber: this.state.DocNumber, Token: getCookie("Token") });
            if (sql.status == "true") {
                pesan("success", "Data berhasil proses", "primary");
                document.getElementById('btnTutupKirim').click();
                this.main();
            } else {
                pesan("error", sql.message, "danger");
            }
        }

        async handlePrint() {
            let param = {
                D1: this.state.D1,
                D2: this.state.D2,
                Token: getCookie("Token")
            }
            let sql = await api("list_detail_tagihan_selesai_pusat", param);
            let dom = [];
            if (sql.status == "true") {
                for (let tgl of sql.docdate) {
                    let TotalSales = 0, TotalType = 0;
                    dom.push(<Fragment>
                        <h3 style={{ textAlign: "center" }}>{tanggalIndo(tgl.DocDate)}</h3>
                        {
                            sql.sales.map((sales, salesi) => {
                                let ctTF = [], domTF = [], ctTunai = [], domTunai = [], ctGiro = [], domGiro = [], ctAll = 0;
                                for (let data of sql.data) if (data.SalesID == sales.SalesID && data.DocDate == tgl.DocDate) ctAll++;
                                if (ctAll > 0) {
                                    for (let data of sql.data) if (data.SalesID == sales.SalesID && data.PaymentType == "Transfer" && data.DocDate == tgl.DocDate) ctTF.push(data);
                                    for (let data of sql.data) if (data.SalesID == sales.SalesID && data.PaymentType == "Tunai" && data.DocDate == tgl.DocDate) ctTunai.push(data);
                                    for (let data of sql.data) if (data.SalesID == sales.SalesID && data.PaymentType == "Giro" && data.DocDate == tgl.DocDate) ctGiro.push(data);
                                    if (ctTF.length > 0) {
                                        domTF = (
                                            <Fragment>
                                                <h6>Transfer</h6>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Invoice</th>
                                                                <th>Nama Customer</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ctTF.map((item, index) => {
                                                                if (item.DocDate == tgl.DocDate) {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.NoInvoice}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan='2' style={{ textAlign: "right" }}>Total</td>
                                                                <td>{numberFormat(ctTF.reduce((a, b) => a + b.AmountOpen, 0))}</td>
                                                                <td>{numberFormat(ctTF.reduce((a, b) => a + b.Amount, 0))}</td>
                                                                <td>{numberFormat(ctTF.reduce((a, b) => a + b.AmountBalance, 0))}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                    if (ctTunai.length > 0) {
                                        domTunai = (
                                            <Fragment>
                                                <h6>Tunai</h6>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Invoice</th>
                                                                <th>Nama Customer</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ctTunai.map((item, index) => {
                                                                if (item.DocDate == tgl.DocDate) {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.NoInvoice}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan='2' style={{ textAlign: "right" }}>Total</td>
                                                                <td>{numberFormat(ctTunai.reduce((a, b) => a + b.AmountOpen, 0))}</td>
                                                                <td>{numberFormat(ctTunai.reduce((a, b) => a + b.Amount, 0))}</td>
                                                                <td>{numberFormat(ctTunai.reduce((a, b) => a + b.AmountBalance, 0))}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                    if (ctGiro.length > 0) {
                                        domGiro = (
                                            <Fragment>
                                                <h6>Giro</h6>
                                                <div className='table-responsive'>
                                                    <table className='table table-bordered'>
                                                        <thead>
                                                            <tr>
                                                                <th>No Invoice</th>
                                                                <th>Nama Customer</th>
                                                                <th>Saldo Awal</th>
                                                                <th>Pembayaran</th>
                                                                <th>Saldo Akhir</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ctGiro.map((item, index) => {
                                                                if (item.DocDate == tgl.DocDate) {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.NoInvoice}</td>
                                                                            <td>{item.CustomerName}</td>
                                                                            <td>{numberFormat(item.AmountOpen)}</td>
                                                                            <td>{numberFormat(item.Amount)}</td>
                                                                            <td>{numberFormat(item.AmountBalance)}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan='2' style={{ textAlign: "right" }}>Total</td>
                                                                <td>{numberFormat(ctGiro.reduce((a, b) => a + b.AmountOpen, 0))}</td>
                                                                <td>{numberFormat(ctGiro.reduce((a, b) => a + b.Amount, 0))}</td>
                                                                <td>{numberFormat(ctGiro.reduce((a, b) => a + b.AmountBalance, 0))}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </Fragment>
                                        )
                                    }

                                    return (<div style={{ border: "solid black 1px", padding: "20px" }}>
                                        <h6>{sales.SalesName}</h6>
                                        {domTF}
                                        {domTunai}
                                        {domGiro}
                                    </div>);
                                }
                            })
                        }
                        <div className='row'>
                            <div className='col-md-6 mb-2'>
                                <h4>Total Per Sales</h4>
                                <table className='table' style={{ fontSize: "24px" }}>
                                    {
                                        sql.totalSales.map((sales, index) => {
                                            if (sales.DocDate == tgl.DocDate) {
                                                TotalSales += parseFloat(sales.Total);
                                                return (
                                                    <tr>
                                                        <td width="120px">{sales.SalesName}</td>
                                                        <td width="10px">:</td>
                                                        <td>{numberFormat(sales.Total)}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                    <tr>
                                        <td width="120px" style={{ fontWeight: "bold" }}>Total</td>
                                        <td width="10px" style={{ fontWeight: "bold" }}>:</td>
                                        <td style={{ fontWeight: "bold" }}>{numberFormat(TotalSales)}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className='col-md-6 mb-2'>
                                <h4>Total Per Tipe Pembayaran</h4>
                                <table className='table' style={{ fontSize: "24px" }}>
                                    {
                                        sql.totalType.map((tipe, index) => {
                                            if (tipe.DocDate == tgl.DocDate) {
                                                TotalType += parseFloat(tipe.Total);
                                                return (
                                                    <tr>
                                                        <td width="120px">{tipe.PaymentType}</td>
                                                        <td width="10px">:</td>
                                                        <td>{numberFormat(tipe.Total)}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                    <tr>
                                        <td width="120px" style={{ fontWeight: "bold" }}>Total</td>
                                        <td width="10px" style={{ fontWeight: "bold" }}>:</td>
                                        <td style={{ fontWeight: "bold" }}>{numberFormat(TotalType)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </Fragment>)
                }
            }
            ReactDOM.render(dom, document.getElementById('divPrint'));
            setTimeout(() => window.print(), 500);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Selesai Approve</h2>
                        <div className='row'>
                            <div className='col-md-5'></div>
                            <div className='col-md-7'>
                                <div className='d-flex justify-content-end align-items-start gap-1'>
                                    <div>
                                        <div className='input-group'>
                                            <input type="date" className='form-control' value={this.state.D1} onChange={(e) => this.handleChangeDate('D1', e.target.value)} disabled={this.state.filterDate} />
                                            <input type="date" className='form-control' value={this.state.D2} onChange={(e) => this.handleChangeDate('D2', e.target.value)} disabled={this.state.filterDate} />
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={this.state.filterDate} onChange={(e) => this.setState({ filterDate: e.target.checked })} />
                                            <label className="form-check-label" for="flexSwitchCheckDefault">Semua Tanggal</label>
                                        </div>
                                    </div>
                                    <button className='btn btn-primary' onClick={() => this.main()}>
                                        <span className='material-icons'>search</span>
                                    </button>
                                    <button className='btn btn-primary' onClick={() => this.handlePrint()}>
                                        <span className='material-icons'>print</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th width="100px"></th>
                                        <th width="150px">Nomor Dokumen</th>
                                        <th width="120px">Tanggal</th>
                                        <th width="150px">Jenis Pembayaran</th>
                                        <th width="150px">Jumlah Invoice</th>
                                        <th width="150px">Jumlah Tagihan</th>
                                        <th>Pelanggan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <button className='btn' onClick={() => this.handleDetail(item)}>
                                                            <span className='material-icons'>more_horiz</span>
                                                        </button>
                                                    </td>
                                                    <td>{item.DocNumber}</td>
                                                    <td>{tanggalIndo(item.DocDate)}</td>
                                                    <td>{item.PaymentType}</td>
                                                    <td>{item.Total}</td>
                                                    <td>{numberFormat(item.Amount)}</td>
                                                    <td dangerouslySetInnerHTML={{ __html: item.Customer }}></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4"><b>Total</b></td>
                                        <td><b>{numberFormat(this.state.totalCt)}</b></td>
                                        <td><b>{numberFormat(this.state.totalAmount)}</b></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <nav id="paggination" style={{ paddingRight: "50px" }}></nav>

                    <div className="modal fade" id="modalReplay" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Reject Invoice</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menolak data ini</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" id="btnTutupKirim" data-bs-dismiss="modal">Tutup</button>
                                    <button type="button" className="btn btn-danger" onClick={(e) => this.handleRejectTrans(e)}>Reject</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

class Form extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            data: [],
            host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
            Detail: {},
            dataGiro: [],
            btnKirim: true,
            arrPayment: [],
            Notes: "",
            tableGiro: [],
            divImg: []
        }
    }

    async componentDidMount() {
        let ctx = this;
        ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><span className="material-icons">arrow_back</span> Kembali</div>, document.getElementById('divHeaderLabel'));
        let sql = await api("tagihan_detail_pusat", { DocNumber: ctx.props.DocNumber, Token: getCookie("Token") });
        if (sql.status == "true") {
            this.setState({ data: sql.data, btnKirim: sql.data.Status == "0" ? true : false });
        }
    }

    async handleDetail(detail) {
        this.setState({ Detail: detail, Notes: detail.Notes, tableGiro: [], divImg: <img src={this.state.host + detail.Berkas} style={{ width: "100%" }} /> });
        if (detail.PaymentType == "Giro") {
            let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
            let tableGiro = <Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Nomor</th>
                            <th>Tanggal Jatuh Tempo</th>
                            <th>Nominal</th>
                            <th>Bank</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sql.data.map((val, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{val.GiroNumber}</td>
                                        <td>{tanggalIndo(val.GiroTermDate)}</td>
                                        <td>{numberFormat(val.Amount)}</td>
                                        <td>{val.CoaName}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Fragment>;
            let divImg = [];
            for (let img of sql.data) {
                divImg.push(<div className='col-md-6 mb-2'><img src={this.state.host + img.Berkas} style={{ width: "100%" }} /></div>)
            }
            let divRow = <div className='row'>{divImg}</div>;
            this.setState({ tableGiro: tableGiro, divImg: divRow });
        } else {
            let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
            let tableGiro = <Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Nominal</th>
                            <th>Bank</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sql.data.map((val, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{numberFormat(val.Amount)}</td>
                                        <td>{val.CoaName}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Fragment>;
            this.setState({ tableGiro: tableGiro });
        }
    }

    async handleReject(ID, DocNumber) {
        let btn = document.getElementById('btnProsesReject');
        btn.disabled = true;
        btn.innerText = 'Memproses . . .';
        let sql = await api("reject_tagihan_pusat", { ID: ID, DocNumber: DocNumber, Token: getCookie("Token"), UserID: getCookie("UserID"), Notes: this.state.Notes });
        if (sql.status == "true") {
            btn.disabled = false;
            btn.innerText = 'Reject';
            pesan("Pemberitahuan", sql.message, "primary");
            App();
            document.getElementById('btnTutupDetail').click();
        } else {
            btn.disabled = false;
            btn.innerText = 'Reject';
            pesan("Pemberitahuan", sql.message, "danger");
        }
    }

    handleModalDelete(item) {
        this.setState({ id: item.ID, DocNumber: item.DocNumber });
    }

    async handleDelete() {
        let sql = await api("delete_trans_pusat", { ID: this.state.id, DocNumber: this.state.DocNumber, Token: getCookie("Token") });
        if (sql.status == "true") {
            pesan("Pemberitahuan", sql.message, "primary");
            let ctx = this;
            let sql2 = await api("tagihan_detail_pusat", { DocNumber: ctx.props.DocNumber, Token: getCookie("Token") });
            if (sql2.status == "true") {
                this.setState({ data: sql2.data, btnKirim: sql2.data.Status == "0" ? true : false });
            }
            document.getElementById('btnTutupModalDelete').click();
        } else {
            pesan("Pemberitahuan", sql.message, "danger");
        }
    }

    render() {
        let CTTunai = 0, SWTunai = 0, PMTunai = 0, SATunai = 0;
        return (
            <Fragment>
                <div className="card-form">
                    <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Selesai</h2>
                    <div className='row'>
                        <div className='col-md-9'></div>
                        <div className='col-md-3'>
                            <div className='input-icons'>
                                <span className='material-icons icon-search'>search</span>
                                <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className="table-responsive" id="divView">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>No Pusat</th>
                                    <th>No Invoice</th>
                                    <th>Pelanggan</th>
                                    <th>Sales</th>
                                    <th>Saldo Awal</th>
                                    <th>Pembayaran</th>
                                    <th>Saldo Akhir</th>
                                    <th>Tanggal Pelunasan</th>
                                    <th>PPN</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.data.map((item, index) => {
                                        let State = <span className="badge bg-primary">Approve</span>;
                                        if (item.PXAState == 1) {
                                            State = <span className='badge bg-danger'>Ditarik PXA</span>;
                                        }
                                        let btnDelete = <button className='btn' onClick={() => this.handleModalDelete(item)} data-bs-toggle="modal" data-bs-target="#modalDelete"><span className='material-icons'>delete</span></button>;
                                        if (item.PXAState == 1) btnDelete = [];
                                        CTTunai++;
                                        SWTunai += item.AmountOpen;
                                        PMTunai += item.Amount;
                                        SATunai += item.AmountBalance;
                                        return (
                                            <tr>
                                                <td>
                                                    <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        <span className='material-icons'>visibility</span>
                                                    </button>
                                                    {btnDelete}
                                                </td>
                                                <td>{item.PusatID}</td>
                                                <td>{item.NoInvoice}</td>
                                                <td>{item.CustomerName}</td>
                                                <td>{item.SalesName}</td>
                                                <td>{numberFormat(item.AmountOpen)}</td>
                                                <td>{numberFormat(item.Amount)}</td>
                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                <td>{item.TaxType}</td>
                                                <td>{State}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td>{CTTunai}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{numberFormat(SWTunai)}</td>
                                    <td>{numberFormat(PMTunai)}</td>
                                    <td>{numberFormat(SATunai)}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <table className='table'>
                                            <tr>
                                                <td>Nama Pelanggan</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.CustomerName}</td>
                                            </tr>
                                            <tr>
                                                <td>No Invoice</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.NoInvoice}</td>
                                            </tr>
                                            <tr>
                                                <td>Saldo Awal</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.AmountOpen)}</td>
                                            </tr>
                                            <tr>
                                                <td>Pembayaran</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.Amount)}</td>
                                            </tr>
                                            <tr>
                                                <td>Saldo Akhir</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.AmountBalance)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Pelunasan</td>
                                                <td>:</td>
                                                <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                            </tr>
                                            <tr>
                                                <td>Jenis Pembayaran</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.PaymentType}</td>
                                            </tr>
                                            {this.state.dataGiro}
                                        </table>
                                        <p></p>
                                        <div className='table-responsive'>{this.state.tableGiro}</div>
                                        <div className='form-group'>
                                            <label>Reject Notes</label>
                                            <textarea className='form-control' name='Notes' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {this.state.divImg}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                <button type="button" className="btn btn-danger" id='btnProsesReject' onClick={() => this.handleReject(this.state.Detail.ID, this.state.Detail.PusatID)}>Reject</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabindex="-1" aria-labelledby="modalDelete" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Hapus Data</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h6>Apakah anda yakin akan menghapus data ini?</h6>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="btnTutupModalDelete" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-danger" onClick={() => this.handleDelete(this.state.Detail.ID, this.state.Detail.DocNumber)}>Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default App;