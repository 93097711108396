import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { api, tanggalIndo, numberFormat, getCookie, pesan } from "../modul";
import DataGrid, { Column, Paging, FilterRow, HeaderFilter, Grouping, GroupPanel, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css'

function App() {
    document.title = "Penagihan Baru";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                search: '',
                searchcount: 50,
                index: 0,
                sort: "DocDate",
                by: "DESC",
                SalesID: "",
                DocNumber: "",
                act: "approve_doc_pusat",
                totalCt: 0,
                totalAmount: 0,
                totalPending: 0,
                totalDone: 0,
                Field: [
                    { cap: "No.Dokumen", sort: "DocNumber" },
                    { cap: "Tanggal", sort: "DocDate", type: "date" },
                    { cap: "Jenis Pembayaran", sort: "PaymentType" },
                    { cap: "Jumlah Invoice", sort: "Total", type: "number" },
                    { cap: "Jumlah Tagihan", sort: "Amount", type: "number" },
                    { cap: "Pelanggan", sort: "Customer", type: "html" },
                ]
            };
        }

        async componentDidMount() {
            ReactDOM.render("", document.getElementById('divHeaderLabel'));
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => { this.main(); }, 100);
        }

        handleDetail(detail) {
            console.log(detail);
            ReactDOM.render(<Form DocNumber={detail.DocNumber} />, document.getElementById('tampil'));
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                PusatID: getCookie("UserID"),
                sort: this.state.sort,
                by: this.state.by,
                state: "1",
                SalesID: this.state.SalesID,
                DocType: "Tunai",
                Token: getCookie("Token")
            }
            let sql = await api("list_tagihan_pusat", param);
            if (sql.status == "true") {
                let totalCt = 0, totalAmount = 0, totalDone = 0, totalPending = 0;
                for (let dd of sql.data) {
                    totalCt += parseFloat(dd.TotalInv);
                    totalAmount += parseFloat(dd.Amount);
                    totalDone = parseFloat(dd.Done);
                    totalPending = parseFloat(dd.Pending);
                }
                this.setState({ data: sql.data, totalCt: totalCt, totalAmount: totalAmount, totalDone: totalDone, totalPending: totalPending });
            }
        }

        async handleKirim(e) {
            let btn = document.getElementById('btnKirimPusat');
            btn.disabled = true;
            btn.innerText = 'Memproses . . .';
            e.preventDefault();
            e.stopPropagation();
            let sql = await api("approve_doc_pusat", { DocNumber: this.state.DocNumber, Token: getCookie("Token"), UserID: getCookie("UserID") });
            if (sql.status == "true") {
                btn.disabled = false;
                btn.innerText = 'Approve';
                pesan("success", "Data berhasil proses", "primary");
                document.getElementById('btnTutupKirim').click();
                this.main();
            } else {
                btn.disabled = false;
                btn.innerText = 'Approve';
                pesan("error", sql.message, "danger");
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-form">
                        <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Baru</h2>
                        <div className='row'>
                            <div className='col-md-9'></div>
                            <div className='col-md-3'>
                                <div className='input-icons'>
                                    <span className='material-icons icon-search'>search</span>
                                    <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <DataGrid dataSource={this.state.data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true}>
                            <Column caption=""
                                cellRender={(item) => (
                                    <div className='d-flex gap-2'>
                                        <button className='btn' onClick={() => this.handleDetail(item.data)}>
                                            <span className='material-icons'>more_horiz</span>
                                        </button>
                                        <button className='btn' onClick={() => this.setState({ DocNumber: item.data.DocNumber, act: "approve_doc_pusat" })} data-bs-toggle="modal" data-bs-target="#modalApprove">
                                            <span className='material-icons'>check_circle</span>
                                        </button>
                                    </div>
                                )}
                            />
                            {
                                this.state.Field.map((tr, ii) => {
                                    if (tr.type == "number") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                    } else if (tr.type == "date") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                    } else if (tr.type == "datetime") {
                                        return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                    } else if (tr.type == "html") {
                                        return <Column caption={tr.cap} cellRender={(rowData) => (
                                            <div dangerouslySetInnerHTML={{ __html: rowData.data[tr.sort] }} />
                                        )} />
                                    } else {
                                        return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                    }
                                })
                            }
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={true} />
                            <GroupPanel visible={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={true} />
                            <Summary>
                                <TotalItem column="ID" summaryType="count" />
                                <TotalItem column="Total" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <TotalItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                <GroupItem column="ID" summaryType="count" displayFormat="{0} Transaksi" />
                                <GroupItem column="Total" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Invoice : {0}" showInGroupFooter={false} alignByColumn={true} />
                                <GroupItem column="Amount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Jumlah Tagihan : {0}" showInGroupFooter={false} alignByColumn={true} />
                            </Summary>
                            <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                        </DataGrid>
                    </div>

                    <div className="modal fade" id="modalApprove" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form id="formKirim" onSubmit={(e) => this.handleKirim(e)}>
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Approve Dokumen</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" name="DocNumber" value={this.state.DocNumber} />
                                        <h6>Apakah anda yakin akan approve Dokumen ini</h6>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnTutupKirim" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" id="btnKirimPusat" className="btn btn-primary">Approve</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            host: window.location.hostname == "localhost" ? "http://localhost:2005/file/" : "https://penagihanapi.pxa.co.id/file/",
            Detail: {},
            dataGiro: [],
            btnKirim: true,
            arrPayment: [],
            Notes: "",
            tableGiro: [],
            divImg: ""
        }
    }

    async componentDidMount() {
        let ctx = this;
        ReactDOM.render(<div className='btn-kembali' onClick={() => App()}><span className="material-icons">arrow_back</span> Kembali</div>, document.getElementById('divHeaderLabel'));
        let sql = await api("tagihan_detail_pusat", { DocNumber: ctx.props.DocNumber, Token: getCookie("Token") });
        console.log(sql);
        if (sql.status == "true") {
            this.setState({ data: sql.data, btnKirim: sql.data.Status == "0" ? true : false });
        }
    }

    async handleDetail(detail) {
        this.setState({ Detail: detail, Notes: detail.Notes, tableGiro: [], divImg: <img src={this.state.host + detail.Berkas} style={{ width: "100%" }} /> });
        if (detail.PaymentType == "Giro") {
            let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
            let tableGiro = <Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Nomor</th>
                            <th>Tanggal Jatuh Tempo</th>
                            <th>Nominal</th>
                            <th>Bank</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sql.data.map((val, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{val.GiroNumber}</td>
                                        <td>{tanggalIndo(val.GiroTermDate)}</td>
                                        <td>{numberFormat(val.Amount)}</td>
                                        <td>{val.CoaName}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Fragment>;
            let divImg = [];
            for (let img of sql.data) {
                divImg.push(<div className='col-md-6 mb-2'><img src={this.state.host + img.Berkas} style={{ width: "100%" }} /></div>)
            }
            let divRow = <div className='row'>{divImg}</div>;
            this.setState({ tableGiro: tableGiro, divImg: divRow });
        } else {
            let sql = await api("detail_giro", { ID: detail.ID, Token: getCookie("Token") });
            let tableGiro = <Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr>
                            <th>Nominal</th>
                            <th>Bank</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sql.data.map((val, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{numberFormat(val.Amount)}</td>
                                        <td>{val.CoaName}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </Fragment>;
            this.setState({ tableGiro: tableGiro });
        }
    }

    async handleReject(ID, DocNumber) {
        let sql = await api("reject_tagihan_pusat", { ID: ID, DocNumber: DocNumber, Token: getCookie("Token"), UserID: getCookie("UserID"), Notes: this.state.Notes });
        if (sql.status == "true") {
            pesan("Pemberitahuan", sql.message, "primary");
            App();
            document.getElementById('btnTutupDetail').click();
        } else {
            pesan("Pemberitahuan", sql.message, "danger");
        }
    }

    async handleApprove(ID, DocNumber) {
        let btn = document.getElementById('btnApprovePusat');
        btn.disabled = true;
        btn.innerText = "Memproses . . .";
        let sql = await api("approve_tagihan_pusat", { ID: ID, DocNumber: DocNumber, Notes: this.state.Notes, Token: getCookie("Token"), UserID: getCookie("UserID") });
        if (sql.status == "true") {
            console.log(sql);
            this.setState({ data: sql.data });
            btn.disabled = false;
            btn.innerText = "Approve";
            pesan("Pemberitahuan", sql.message, "primary");
            document.getElementById('btnTutupDetail').click();
        } else {
            btn.disabled = false;
            btn.innerText = "Approve";
            pesan("Pemberitahuan", sql.message, "danger");
        }
    }

    async handleKirimPusat() {
        let btn = document.getElementById('btnRejectPusat');
        btn.disabled = true;
        btn.innerText = "Memproses . . .";
        let sql = await api("kirim_tagihan_pusat", { ID: this.state.id, Token: getCookie("Token") });
        if (sql.status == "true") {
            btn.disabled = false;
            btn.innerText = "Reject";
            pesan("Pemberitahuan", sql.message, "primary");
            App();
        } else {
            btn.disabled = false;
            btn.innerText = "Reject";
            pesan("Pemberitahuan", sql.message, "danger");
        }
    }

    handleModalDelete(item) {
        this.setState({ id: item.ID, DocNumber: item.DocNumber });
    }

    async handleDelete() {
        let sql = await api("delete_trans_pusat", { ID: this.state.id, DocNumber: this.state.DocNumber, Token: getCookie("Token") });
        if (sql.status == "true") {
            pesan("Pemberitahuan", sql.message, "primary");
            App();
        } else {
            pesan("Pemberitahuan", sql.message, "danger");
        }
    }

    render() {
        let CTTunai = 0, SWTunai = 0, PMTunai = 0, SATunai = 0;
        return (
            <Fragment>
                <div className="card-form">
                    <h2 style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "24px" }}>Data Baru</h2>
                    <div className='row'>
                        <div className='col-md-9'></div>
                        <div className='col-md-3'>
                            <div className='input-icons'>
                                <span className='material-icons icon-search'>search</span>
                                <input className="form-control search-form" type="search" name="search" placeholder="Search" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} required />
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className="table-responsive" id="divView">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nomor</th>
                                    <th>No Invoice</th>
                                    <th>Pelanggan</th>
                                    <th>Sales</th>
                                    <th>Saldo Awal</th>
                                    <th>Pembayaran</th>
                                    <th>Saldo Akhir</th>
                                    <th>Tanggal Pelunasan</th>
                                    <th></th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.data.map((item, index) => {
                                        let State = <span className="badge bg-danger">Menunggu</span>;
                                        if (item.PusatState == 0) {
                                            State = <span className='badge bg-warning'>Menunggu</span>;
                                        } else if (item.PusatState == 1) {
                                            State = <span className='badge bg-primary'>Approve</span>;
                                        }
                                        let TaxType = <span className="badge bg-danger">NON PPN</span>;
                                        if (item.TaxType == "PPN") TaxType = <span className='badge bg-primary'>PPN</span>;
                                        CTTunai++;
                                        SWTunai += item.AmountOpen;
                                        PMTunai += item.Amount;
                                        SATunai += item.AmountBalance;
                                        let status = item.AmountBalance == 0 ? <span className='badge bg-primary'>Lunas</span> : <span className='badge bg-danger'>Cicilan</span>
                                        return (
                                            <tr>
                                                <td>
                                                    <button className='btn' onClick={() => this.handleDetail(item)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        <span className='material-icons'>visibility</span>
                                                    </button>
                                                    <button className='btn' onClick={() => this.handleModalDelete(item)} data-bs-toggle="modal" data-bs-target="#modalDelete">
                                                        <span className='material-icons'>delete</span>
                                                    </button>
                                                </td>
                                                <td>{item.DocNumber}</td>
                                                <td>{item.NoInvoice}</td>
                                                <td>{item.CustomerName}</td>
                                                <td>{item.SalesName}</td>
                                                <td>{numberFormat(item.AmountOpen)}</td>
                                                <td>{numberFormat(item.Amount)}</td>
                                                <td>{numberFormat(item.AmountBalance)}</td>
                                                <td>{tanggalIndo(item.TanggalPembayaran)}</td>
                                                <td>{status} {TaxType}</td>
                                                <td>{State}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td>{CTTunai}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{numberFormat(SWTunai)}</td>
                                    <td>{numberFormat(PMTunai)}</td>
                                    <td>{numberFormat(SATunai)}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Detail Invoice</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <table className='table table-bordered'>
                                            <tr>
                                                <td>Nama Pelanggan</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.CustomerName}</td>
                                            </tr>
                                            <tr>
                                                <td>No Invoice</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.NoInvoice}</td>
                                            </tr>
                                            <tr>
                                                <td>Saldo Awal</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.AmountOpen)}</td>
                                            </tr>
                                            <tr>
                                                <td>Diskon</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.Diskon)}</td>
                                            </tr>
                                            <tr>
                                                <td>Saldo Akhir</td>
                                                <td>:</td>
                                                <td>{numberFormat(this.state.Detail.AmountBalance)}</td>
                                            </tr>
                                            <tr>
                                                <td>Tanggal Pelunasan</td>
                                                <td>:</td>
                                                <td>{tanggalIndo(this.state.Detail.TanggalPembayaran)}</td>
                                            </tr>
                                            <tr>
                                                <td>Jenis Pembayaran</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <td>PPN</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.TaxType}</td>
                                            </tr>
                                            <tr>
                                                <td>Note</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Notes}</td>
                                            </tr>
                                        </table>
                                        <p></p>
                                        <div className='table-responsive'>{this.state.tableGiro}</div>
                                        <div className='form-group'>
                                            <label>Notes</label>
                                            <textarea className='form-control' name='Notes' value={this.state.Notes} onChange={(e) => this.setState({ Notes: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {this.state.divImg}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="btnTutupDetail" data-bs-dismiss="modal">Tutup</button>
                                <button type="button" id="btnRejectPusat" className="btn btn-danger" onClick={() => this.handleReject(this.state.Detail.ID, this.state.Detail.PusatID)}>Reject</button>
                                <button type="button" id="btnApprovePusat" className="btn btn-warning" onClick={() => this.handleApprove(this.state.Detail.ID, this.state.Detail.PusatID)}>Approve</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabindex="-1" aria-labelledby="modalDelete" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Hapus Data</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <h6>Apakah anda yakin akan menghapus data ini?</h6>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id="btnTutupModalDelete" data-bs-dismiss="modal">Batal</button>
                                <button type="button" className="btn btn-danger" onClick={() => this.handleDelete(this.state.Detail.ID, this.state.Detail.PusatID)}>Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default App;